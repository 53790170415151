/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-02-17 15:32:55
 * @ Description: A controller that handles professional service detail related operations.
 */

import BaseController from '../../../base.controller.js'

/**
* A class with helper methods to perform professional service detail-related functionality.
*/
class WorkProfessionalServiceDetailController extends BaseController {
    /**
    * Retrieve a professional service's detail.
    * @param {Number} id The ID of the professional service to retrieve.
    */
    async retrieve (id) {
        try {
            const result = await this.$http.get(`${this.apiPath}/work/service/${id}/detail`)
   
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
   
export default WorkProfessionalServiceDetailController
   