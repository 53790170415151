/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-30 13:06:08
 * @ Description: A controller that handles job application chat related operations.
 */

import BaseController from '../../../../base.controller.js'

/**
  * A class with helper methods to perform job application chat-related functionality.
  */
class WorkJobApplicationChatController extends BaseController {
    /**
      * Reset an unread message count of a job application.
      * @param {Object} application The job application object to update.
      */
    async resetMessageCount (application) {
        try {
            const result = await this.$http.put(`${this.apiPath}/work/job/application/chat/reset`, application)
       
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
       
export default WorkJobApplicationChatController
       