/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2020-12-01 15:16:46
 * @ Description: A controller that handles company signup related operations.
 */

import BaseController from '../../../base.controller.js'

/**
  * A class with helper methods to perform company signup-related functionality.
  */
class CompanySignupController extends BaseController {
    /**
     * Create a new company.
     * @param {Object} company The object with the company data.
     */
    async create (company) {
        try {
            const result = await this.$http.post(`${this.apiPath}/signup/company`, company)

            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
    
    /**
     * Upload a company avatar to the server on signup.
     * @param {Number} companyID The ID of the company uploading the file.
     * @param {FormData} form The form with the file to be uploaded to the server.
     */
    async uploadAvatar (companyID, form) {
        try {
            const response = await this.$http.post(`${this.apiPath}/signup/company/${companyID}/avatar`, form)
            const responseData = response.data.resultMessage

            return responseData
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
 
export default CompanySignupController
 