/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-29 09:31:47
 * @ Description: A controller that handles job application document related operations.
 */

import BaseController from '../../../../base.controller.js'

/**
 * A class with helper methods to perform job application document-related functionality.
 */
class WorkJobApplicationDocumentController extends BaseController {
    /**
      * Retrieve a job application's documents.
      * @param {Number} jobID The job ID of the job application.
      * @param {Number} applicantID The applicant ID of the job application.
      */
    async retrieve (jobID, applicantID) {
        try {
            const result = await this.$http.get(`${this.apiPath}/work/job/${jobID}/application/${applicantID}/document`)
     
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
    
    /**
      * Upload job application's document.
      * @param {FormData} document The document object to upload.
      * @param {Number} jobID The job ID of the job application.
      * @param {Number} applicantID The applicant ID of the job application.
      */
    async create (document, jobID, applicantID) {
        try {
            const result = await this.$http.put(`${this.apiPath}/work/job/${jobID}/application/${applicantID}/document`, document)
     
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
  
    /**
      * Delete job application's document.
      * @param {Object} document The document object to delete.
      * @param {Number} jobID The job ID of the job application.
      * @param {Number} applicantID The applicant ID of the job application.
      */
    async delete (document, jobID, applicantID) {
        try {
            const result = await this.$http.delete(`${this.apiPath}/work/job/${jobID}/application/${applicantID}/document`, document)
     
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
     
export default WorkJobApplicationDocumentController
     