/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2020-10-30 10:57:07
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-03-31 21:06:01
 * @ Description: The model used to parse an event type object from the API to an object that the Calendar component understands.
 */

class EventTypeModel {
    /**
     * Event Type constructor.
     * @param {Object} eventType The event type object retrieved from the API.
     */
    constructor (eventType) {
        this.id = eventType.id
        this.name = eventType.name
        this.color = '#fff'
        this.bgColor = eventType.color
        this.dragBgColor = eventType.color
        this.borderColor = eventType.color
        this.visible = true
    }
}

export default EventTypeModel
