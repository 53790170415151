/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-29 13:08:45
 * @ Description: A controller that handles individual profile education related operations.
 */

import BaseController from '../../../../base.controller.js'

/**
     * A class with helper methods to perform individual profile education-related functionality.
     */
class IndividualProfileEducationController extends BaseController {
    /**
     * Retrieve an individual profile's education.
     * @param {Number} userID The ID of the user to retrieve.
     */
    async retrieve (userID) {
        try {
            const result = await this.$http.get(`${this.apiPath}/individual/${userID}/profile/education`)
    
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
   
    /**
     * Upsert an individual profile's education.
     * @param {Object} education The education object to upsert.
     */
    async upsert (education) {
        try {
            const result = await this.$http.put(`${this.apiPath}/individual/profile/education`, education)
    
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
 
    /**
     * Delete an individual profile's education.
     * @param {Object} education The education object to delete.
     */
    async delete (education) {
        try {
            const result = await this.$http.delete(`${this.apiPath}/individual/profile/education`, education)
    
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
    
export default IndividualProfileEducationController
    