<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-29 12:56:22
 * @ Description: Component displaying the general profile social media section in the details tab.
 -->

<template>
    <!-- Desktop -->
    <v-form
        v-if="$vuetify.breakpoint.mdAndUp"
        v-model="validSocialMediaForm"
        ref="socialMediaForm"
    >
        <v-row>
            <v-col
                cols="12"
                class="os-22-sb"
            >
                Social Media
            </v-col>
            <v-col
                cols="12"
                class="py-0"
            >
                <v-divider />
            </v-col>

            <!-- Facebook -->
            <v-col
                cols="6"
                class="pb-0 os-15-sb"
            >
                <v-icon
                    color="#006FD6"
                    class="mr-1"
                >
                    {{ mdiFacebook }}
                </v-icon>
                Facebook
                <br />
                <v-text-field
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    v-model="social.facebook"
                    :rules="facebookRules"
                    placeholder="https://www.facebook.com/my-profile"
                />
            </v-col>
            <!-- Facebook -->

            <!-- Twitter -->
            <v-col
                cols="6"
                class="pb-0 os-15-sb"
            >
                <v-icon
                    color="#0095FF"
                    class="mr-1"
                >
                    {{ mdiTwitter }}
                </v-icon>
                Twitter
                <br />
                <v-text-field
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    v-model="social.twitter"
                    :rules="twitterRules"
                    placeholder="https://www.twitter.com/my-profile"
                />
            </v-col>
            <!-- Twitter -->

            <!-- LinkedIn -->
            <v-col
                cols="6"
                class="py-0 os-15-sb"
            >
                <v-icon
                    color="#0A579F"
                    class="mr-1"
                >
                    {{ mdiLinkedin }}
                </v-icon>
                LinkedIn
                <br />
                <v-text-field
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    v-model="social.linkedin"
                    :rules="linkedinRules"
                    placeholder="https://www.linkedin.com/in/my-profile"
                />
            </v-col>
            <!-- LinkedIn -->

            <!-- Webiste -->
            <v-col
                cols="6"
                class="py-0 os-15-sb"
            >
                <v-icon
                    color="#EA7101"
                    class="mr-1"
                >
                    {{ mdiWeb }}
                </v-icon>
                Website
                <br />
                <v-text-field
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    v-model="social.website"
                    :rules="websiteRules"
                    placeholder="https://www.my-website.com"
                />
            </v-col>
            <!-- Webiste -->

            <!-- Instagram -->
            <v-col
                cols="6"
                class="py-0 os-15-sb"
            >
                <v-icon
                    color="#E80620"
                    class="mr-1"
                >
                    {{ mdiInstagram }}
                </v-icon>
                Instagram
                <br />
                <v-text-field
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    v-model="social.instagram"
                    :rules="instagramRules"
                    placeholder="https://www.instagram.com/my-profile"
                />
            </v-col>
            <!-- Webiste -->

            <!-- Update Button -->
            <v-col
                cols="6"
                class="text-right pt-0 os-13-sb"
                align-self="center"
            >
                <v-btn
                    class="white--text no-uppercase rounded-lg os-13-sb"
                    color="#FFA858"
                    :disabled="!validSocialMediaForm"
                    @click="updateProfileSocial"
                >
                    Update
                </v-btn>
            </v-col>
            <!-- Update Button -->
        </v-row>
    </v-form>
    <!-- Desktop -->

    <!-- Mobile -->
    <v-form
        v-else-if="$vuetify.breakpoint.smAndDown"
        v-model="validSocialMediaForm"
        ref="socialMediaForm"
    >
        <v-row>
            <v-col
                cols="12"
                class="os-22-sb"
            >
                Social Media
            </v-col>
            <v-col
                cols="12"
                class="py-0"
            >
                <v-divider />
            </v-col>

            <!-- Facebook -->
            <v-col
                cols="12"
                sm="6"
                class="pb-0 os-15-sb"
            >
                <v-icon
                    color="#006FD6"
                    class="mr-1"
                >
                    {{ mdiFacebook }}
                </v-icon>
                Facebook
                <br />
                <v-text-field
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    v-model="social.facebook"
                    :rules="facebookRules"
                    placeholder="https://www.facebook.com/my-profile"
                />
            </v-col>
            <!-- Facebook -->

            <!-- Twitter -->
            <v-col
                cols="12"
                sm="6"
                class="os-15-sb"
                :class="$vuetify.breakpoint.sm ? 'pb-0' : 'py-0'"
            >
                <v-icon
                    color="#0095FF"
                    class="mr-1"
                >
                    {{ mdiTwitter }}
                </v-icon>
                Twitter
                <br />
                <v-text-field
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    v-model="social.twitter"
                    :rules="twitterRules"
                    placeholder="https://www.twitter.com/my-profile"
                />
            </v-col>
            <!-- Twitter -->

            <!-- LinkedIn -->
            <v-col
                cols="12"
                sm="6"
                class="py-0 os-15-sb"
            >
                <v-icon
                    color="#0A579F"
                    class="mr-1"
                >
                    {{ mdiLinkedin }}
                </v-icon>
                LinkedIn
                <br />
                <v-text-field
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    v-model="social.linkedin"
                    :rules="linkedinRules"
                    placeholder="https://www.linkedin.com/in/my-profile"
                />
            </v-col>
            <!-- LinkedIn -->

            <!-- Website -->
            <v-col
                cols="12"
                sm="6"
                class="py-0 os-15-sb"
            >
                <v-icon
                    color="#EA7101"
                    class="mr-1"
                >
                    {{ mdiWeb }}
                </v-icon>
                Website
                <br />
                <v-text-field
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    v-model="social.website"
                    :rules="websiteRules"
                    placeholder="https://www.my-website.com"
                />
            </v-col>
            <!-- Website -->

            <!-- Instagram -->
            <v-col
                cols="12"
                sm="6"
                class="py-0 os-15-sb"
            >
                <v-icon
                    color="#E80620"
                    class="mr-1"
                >
                    {{ mdiInstagram }}
                </v-icon>
                Instagram
                <br />
                <v-text-field
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    v-model="social.instagram"
                    :rules="instagramRules"
                    placeholder="https://www.instagram.com/my-profile"
                />
            </v-col>
            <!-- Instagram -->

            <!-- Update Button -->
            <v-col
                cols="12"
                sm="6"
                class="text-right pt-0 os-13-sb"
                align-self="center"
            >
                <v-btn
                    class="white--text no-uppercase rounded-lg os-13-sb"
                    color="#FFA858"
                    :disabled="!validSocialMediaForm"
                    @click="updateProfileSocial"
                >
                    Update
                </v-btn>
            </v-col>
            <!-- Update Button -->
        </v-row>
    </v-form>
    <!-- Mobile -->
</template>
<script>
    import { mdiFacebook, mdiTwitter, mdiLinkedin, mdiWeb, mdiInstagram } from '@mdi/js'
    import { CompanyProfileSocialController, IndividualProfileSocialController, GeneralProfileStatsController } from '@/logic/controller/index.controller.js'

    export default {
        name: 'ProfileDetailsSocialMediaComponent',

        computed: {
            facebookRules () {
                if (this.social.facebook === null || this.social.facebook === '') return []
                else if (this.social.facebook.replace(/\s/g, '') === '') return [ 'Invalid Facebook URL' ]
                else return [
                    v => /(?:https?:\/\/)?(?:www\.)?facebook\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*?(\/)?([\w\-\.]{5,})/ig.test(v) || 'Invalid Facebook URL'
                ]
            },

            twitterRules () {
                if (this.social.twitter === null || this.social.twitter === '') return []
                else if (this.social.twitter.replace(/\s/g, '') === '') return [ 'Invalid Twitter URL' ]
                else return [
                    v => /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/.test(v) || 'Invalid Twitter URL'
                ]
            },

            linkedinRules () {
                if (this.social.linkedin === null || this.social.linkedin === '') return []
                else if (this.social.linkedin.replace(/\s/g, '') === '') return [ 'Invalid LinkedIn URL' ]
                else return [
                    v => /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile|company)/gm.test(v) || 'Invalid LinkedIn URL'
                ]
            },

            websiteRules () {
                if (this.social.website === null || this.social.website === '') return []
                else if (this.social.website.replace(/\s/g, '') === '') return [ 'Invalid URL' ]
                else return [
                    v => /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(v) || 'Invalid URL'
                ]
            },

            instagramRules () {
                if (this.social.instagram === null || this.social.instagram === '') return []
                else if (this.social.instagram.replace(/\s/g, '') === '') return [ 'Invalid Instagram URL' ]
                else return [
                    v => /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/igm.test(v) || 'Invalid Instagram URL'
                ]
            }
        },

        data () {
            return {
                mdiFacebook,
                mdiTwitter,
                mdiLinkedin,
                mdiWeb,
                mdiInstagram,
                validSocialMediaForm: false,
                social: {
                    facebook: null,
                    twitter: null,
                    linkedin: null,
                    website: null,
                    instagram: null,
                },
                companyProfileSocialController: null,
                individualProfileSocialController: null,
                generalProfileStatsController: null
            }
        },

        beforeMount () {
            this.companyProfileSocialController = new CompanyProfileSocialController()
            this.individualProfileSocialController = new IndividualProfileSocialController()
            this.generalProfileStatsController = new GeneralProfileStatsController()
        },

        async mounted () {
            await this.getProfileSocial()
        },

        methods: {
            async getProfileSocial () {
                try {
                    if (this.$store.state.user.userContext === 'company') this.social = await this.companyProfileSocialController.retrieve(this.$store.state.user.userData.company_id)
                    else if (this.$store.state.user.userContext === 'individual') this.social = await this.individualProfileSocialController.retrieve(this.$store.state.user.userData.id)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getProfileCompletionStats () {
                try {
                    const stats = await this.generalProfileStatsController.getCompletionStats()
                    this.$store.commit('user/setUserProfileCompletionStats', stats)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async updateProfileSocial () {
                try {
                    if (this.$store.state.user.userContext === 'company') this.social = await this.companyProfileSocialController.update(this.social)
                    else if (this.$store.state.user.userContext === 'individual') this.social = await this.individualProfileSocialController.update(this.social)

                    this.$store.commit('setSnackbar', {
                        text: 'Updated successfully',
                        show: true,
                        color: '#069B34'
                    })
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }

                try {
                    await this.getProfileCompletionStats()
                } catch (error) {}
            }
        }
    }
</script>
