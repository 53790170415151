/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-12-09 20:39:06
 * @ Description: A controller that interacts with Facebook's Graph API.
 */

import BaseController from '../base.controller.js'

/**
 * A class with helper methods to interact with Facebook's Graph API.
 */
class FacebookController extends BaseController {
    /**
     * Retrieve a Facebook user's data.
     * @param {String} accessToken Facebook access token retrieved from the handshake.
     */
    async getFacebookUserData (accessToken) {
        try {
            const response = await this.$http.get(`https://graph.facebook.com/me?fields=email&access_token=${accessToken}`)
            const responseData = response.data
 
            return responseData
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
 
export default FacebookController
 