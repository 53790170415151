/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-03-25 15:55:51
 * @ Description: A controller that handles landing page subscription related operations.
 */

import BaseController from '../../base.controller.js'

/**
  * A class with helper methods to perform landing page subscription-related functionality.
  */
class LandingSubscriptionController extends BaseController {
    /**
      * Retrieve all landing page subscription data.
      */
    async get () {
        try {
            const response = await this.$http.get(`${this.apiPath}/landing/subscription`)
            const responseData = response.data.resultMessage
 
            return responseData
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
 
export default LandingSubscriptionController
 