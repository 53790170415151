/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:00
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-12-08 08:44:04
 * @ Description: The Axios Helper class enables you to communicate with API's. Consists of methods for GET, POST, PUT and DELETE and
 * @              allows you to setup Bearer Token (JWT) headers for authentication.
 */

import axios from 'axios'

class AxiosHelper {
    /**
     * The constructor of the Axios Helper.
     */
    constructor () {
        this.cancelTokenSource = axios.CancelToken.source()

        /**
         * An interceptor for each request sent to the API.
         * Interceptors can be used to perform an operation before every request is sent to an API.
         */
        axios.interceptors.request.use(
            (config) => {
                this.vuexStore.commit('setLoadingOverlay', true)
                return config
            }, (error) => {
                this.vuexStore.commit('setLoadingOverlay', false)
                return Promise.reject(error)
            }
        )

        /**
         * An interceptor for each response received from the API.
         * Interceptors can be used to perform an operation before every response reaches it destination.
         */
        axios.interceptors.response.use(
            (response) => {
                this.vuexStore.commit('setLoadingOverlay', false)
                return response
            },
            (error) => {
                this.vuexStore.commit('setLoadingOverlay', false)

                if (error.response.status === 401 && (error.response.data.errorCode === 401 || error.response.data.errorCode === 403)) {
                    if (this.vueRouter.currentRoute.path !== '/') this.vuexStore.commit('user/resetUserData')
                }

                return Promise.reject(error)
            }
        )
    }

    set token (val) {
        if (val) axios.defaults.headers.Authorization = `Bearer ${val}`
        else delete axios.defaults.headers.Authorization

        this.authToken = val
    }

    get token () {
        return this.authToken
    }

    set store (val) {
        this.vuexStore = val
    }

    get store () {
        return this.vuexStore
    }

    set router (val) {
        this.vueRouter = val
    }

    get router () {
        return this.vueRouter
    }

    /**
     * REST Cancel Request functionality.
     * Reset the cancel token after cancelling the Axios request.
     */
    cancelRequest () {
        this.cancelTokenSource.cancel('User cancelled the request')
        this.cancelTokenSource = axios.CancelToken.source()
    }

    /**
     * REST GET Request functionality.
     * @param {string} path The URL of the REST end-point.
     * @param {object} params The request body.
     */
    async get (path, params, responseType = null) {
        const config = {
            params,
            cancelToken: this.cancelTokenSource.token,
            responseType
        }
        return axios.get(path, config)
    }

    /**
     * REST POST Request functionality.
     * @param {string} path The URL of the REST end-point.
     * @param {object} params The request body.
     */
    post (path, params, responseType = null/* , config = null */) {
        const config = {
            responseType
        }
        return axios.post(path, params, config)
    }

    /**
     * REST PUT Request functionality.
     * @param {string} path The URL of the REST end-point.
     * @param {object} params The request body.
     */
    put (path, params) {
        return axios.put(path, params)
    }

    /**
     * REST DELETE Request functionality.
     * @param {string} path The URL of the REST end-point.
     * @param {object} params The request body.
     */
    delete (path, params) {
        return axios.delete(path, { data: params })
    }
}

export default AxiosHelper
