/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2020-12-08 14:42:54
 * @ Description: A controller that handles contact us related operations.
 */

import BaseController from '../base.controller.js'

/**
 * A class with helper methods to perform contact us-related functionality.
 */
class ContactUsController extends BaseController {
    /**
     * Send an e-mail to Fixer's admins from the web app's Contact Us page.
     * @param {String} user The user object containing the message data.
     */
    async createEmail (user) {
        try {
            const result = await this.$http.post(`${this.apiPath}/contactus`, user)

            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}

export default ContactUsController
