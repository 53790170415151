<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-05-12 12:57:35
 * @ Description: Component displaying the company profile details tab.
 -->

<template>
    <v-row>
        <v-col
            cols="12"
            md="6"
        >
            <!-- Details -->
            <profile-company-details-component />
            <!-- Details -->

            <!-- Location -->
            <profile-location-component />
            <!-- Location -->

            <!-- Social Media -->
            <profile-social-media-component />
            <!-- Social Media -->

            <!-- Documents -->
            <profile-documents-component />
            <!-- Documents -->
        </v-col>

        <!-- Profile Completion Stats -->
        <v-col
            cols="4"
            offset="2"
            class="os-18-sb"
            :style="$store.state.signup.newUser && !$store.state.user.userData.is_trial ? 'padding-top: 434px;' : $store.state.signup.newUser || !$store.state.user.userData.is_trial ? 'padding-top: 237px;' : 'padding-top: 125px;'"
            v-if="$vuetify.breakpoint.mdAndUp"
        >
            <div
                class="os-22-sb"
            >
                Profile Status
            </div>
            <v-divider />
            <div
                v-for="(item, index) in profileStatusItems"
                :key="index"
            >
                <v-list-item>
                    <v-list-item-avatar
                        size="20"
                        class="pa-0"
                    >
                        <v-img
                            :src="item.complete === 25 ? '/dashboard/profile/general/DashboardProfileGeneralCheckBlue.png' : '/dashboard/profile/general/DashboardProfileGeneralCheckGrey.png'"
                            contain
                            style="width: 20px;"
                        />
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <span>{{ item.text }}</span>
                    </v-list-item-content>
                </v-list-item>
            </div>
        </v-col>
        <!-- Profile Completion Stats -->
    </v-row>
</template>
<script>
    import ProfileCompanyDetailsComponent from '@/components/profile/company/details/ProfileCompanyDetailsComponent.vue'
    import ProfileDocumentsComponent from '@/components/profile/general/details/ProfileDetailsDocumentsComponent.vue'
    import ProfileLocationComponent from '@/components/profile/general/details/ProfileDetailsLocationComponent.vue'
    import ProfileSocialMediaComponent from '@/components/profile/general/details/ProfileDetailsSocialMediaComponent.vue'

    export default {
        name: 'ProfileCompanyDetailsTabComponent.vue',

        components: {
            ProfileCompanyDetailsComponent,
            ProfileDocumentsComponent,
            ProfileLocationComponent,
            ProfileSocialMediaComponent
        },

        computed: {
            profileStatusItems () {
                return [
                    {
                        text: 'Profile Picture',
                        complete: this.$store.state.user.userProfileCompletionStats.avatar
                    },
                    {
                        text: 'Company Details',
                        complete: this.$store.state.user.userProfileCompletionStats.detail
                    },
                    {
                        text: 'Location',
                        complete: this.$store.state.user.userProfileCompletionStats.address
                    },
                    // {
                    //     text: 'Social Media',
                    //     complete: this.$store.state.user.userProfileCompletionStats.social
                    // },
                    {
                        text: 'Documents',
                        complete: this.$store.state.user.userProfileCompletionStats.document
                    }
                ]
            }
        }
    }
</script>
<style scoped>
    >>> .v-list-item {
        min-height: 0;
        height: 40px;
    }
</style>
