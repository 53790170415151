/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-12-06 13:41:42
 * @ Description: A controller that handles general invoice related operations.
 */

import BaseController from '../../../base.controller.js'

/**
  * A class with helper methods to perform general invoice-related functionality.
  */
class GeneralInvoiceController extends BaseController {
    /**
      * Pay an invoice.
      * @param {Number} invoiceID The ID of the invoice to pay.
      * @param {Number} cardID The ID of the card to pay with.
      */
    async pay (invoiceID, cardID) {
        try {
            const result = await this.$http.put(`${this.apiPath}/user/invoice/pay`, { invoice_id: invoiceID, card_id: cardID })
     
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
     
export default GeneralInvoiceController
     