/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2020-12-01 15:16:46
 * @ Description: A controller that handles job location related operations.
 */

import BaseController from '../../../base.controller.js'

/**
    * A class with helper methods to perform job location-related functionality.
    */
class WorkJobLocationController extends BaseController {
    /**
    * Retrieve a job's location.
    * @param {Number} id The ID of the job's location to retrieve.
    */
    async retrieve (id) {
        try {
            const result = await this.$http.get(`${this.apiPath}/work/job/${id}/location`)
   
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
  
    /**
    * Upsert a job's location.
    * @param {Object} location The job location object to update.
    */
    async upsert (location) {
        try {
            const result = await this.$http.put(`${this.apiPath}/work/job/location`, location)
   
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
   
export default WorkJobLocationController
   