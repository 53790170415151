<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-30 16:46:56
 * @ Description: Component for displaying the user's selected Google places on a map.
 -->

<template>
    <!-- Map -->
    <GmapMap
        :center="mapCenter"
        :zoom="10"
        map-type-id="terrain"
        style="height: 300px;"
        :options="{
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false
        }"
    >
        <!-- Custom Map Marker -->
        <gmap-custom-marker
            v-for="(item, index) in mapMarkers"
            alignment="top"
            :key="index"
            :marker="item"
        >
            <map-marker-component
                :avatar="avatar"
            />
        </gmap-custom-marker>
        <!-- Custom Map Marker -->
    </GmapMap>
    <!-- Map -->
</template>
<script>
    import GmapCustomMarker from 'vue2-gmap-custom-marker'
    import MapMarkerComponent from './GeneralMapCustomMarkerComponent.vue'

    export default {
        name: 'GeneralMapComponent',

        components: {
            GmapCustomMarker,
            MapMarkerComponent
        },

        props: {
            mapMarkers: {
                type: Array,
                default: () => {
                    return []
                }
            },

            avatar: {
                type: String
            }
        },

        watch: {
            mapMarkers: {
                handler (value) {
                    if (value && value.length > 0) {
                        this.mapCenter = value[value.length - 1]
                    }
                }
            }
        },

        data () {
            return {
                mapCenter: {
                    lat: -25.683731047725967,
                    lng: 28.287206934269687
                }
            }
        }
    }
</script>