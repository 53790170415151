/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-23 11:44:36
 * @ Description: A controller that handles general notification related operations.
 */

import BaseController from '../../../base.controller.js'

/**
  * A class with helper methods to perform general notification-related functionality.
  */
class GeneralNotificationController extends BaseController {
    /**
      * Retrieve a user's notifications.
      */
    async retrieve () {
        try {
            const result = await this.$http.get(`${this.apiPath}/user/notification`)
     
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
      * Delete notifications.
      * @param {Array} notifications The array of notifications to delete.
      */
    async delete (notifications) {
        try {
            const result = await this.$http.delete(`${this.apiPath}/user/notification`, notifications)
 
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
      * Mark notifications as read.
      * @param {Array} notifications The array of notifications to mark as read.
      */
    async markAsRead (notifications) {
        try {
            const result = await this.$http.put(`${this.apiPath}/user/notification/read`, notifications)
 
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
     
export default GeneralNotificationController
     