/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2020-10-26 09:26:25
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-05-06 13:17:42
 * @ Description: The base controller contains properties that are used by all other controllers throughout the system.
 *                Ensure that all other controllers inherits from this class.
 */

import Vue from 'vue'

/**
 * Class used as the base for all other controllers.
 */
class BaseController {
    /**
     * Base constructor.
     */
    constructor () {
        this.$http = Vue.prototype.$http
        this.apiPath = process.env.VUE_APP_APIPATH // 'http://192.168.8.113:8082/api' // process.env.VUE_APP_APIPATH
    }
}

export default BaseController
