/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-12-07 16:41:27
 * @ Description: A controller that handles job status related operations.
 */

import BaseController from '../../../base.controller.js'

/**
     * A class with helper methods to perform job status-related functionality.
     */
class WorkJobStatusController extends BaseController {
    /**
     * Change a job's status to recruiting.
     * @param {Object} job The job object to update.
     */
    async recruit (job) {
        try {
            const result = await this.$http.put(`${this.apiPath}/work/job/recruit`, job)
    
            var data = result.data.resultMessage
            return data
        } catch (error) {
            if (error.response.data.errorCode === 402) {
                throw (
                    {
                        errorCode: 402,
                        invoiceData: error.response.data.resultMessage
                    }
                )
            } else throw Error(error.response.data.resultMessage)
        }
    }

    /**
     * Change a job's status to complete.
     * @param {Object} job The job object to update.
     */
    async complete (job) {
        try {
            const result = await this.$http.put(`${this.apiPath}/work/job/complete`, job)
    
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
     * Change a job's status to cancelled.
     * @param {Object} job The job object to update.
     */
    async cancel (job) {
        try {
            const result = await this.$http.put(`${this.apiPath}/work/job/cancel`, job)
    
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
     * Change a job's status to in progress.
     * @param {Object} job The job object to update.
     */
    async inProgress (job) {
        try {
            const result = await this.$http.put(`${this.apiPath}/work/job/inprogress`, job)
    
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
     * Change a job's status to paused.
     * @param {Object} job The job object to update.
     */
    async pause (job) {
        try {
            const result = await this.$http.put(`${this.apiPath}/work/job/pause`, job)
    
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
     * Review an applicant/company after job completion.
     * @param {Object} review The review object to create.
     */
    async review (review) {
        try {
            const result = await this.$http.put(`${this.apiPath}/work/job/review`, review)
    
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
    
export default WorkJobStatusController
    