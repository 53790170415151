/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-02-11 15:12:38
 * @ Description: A controller that handles company size lookup related operations.
 */

import BaseController from '../../base.controller.js'

/**
 * A class with helper methods to perform company size lookup-related functionality.
 */
class LookupCompanySizeController extends BaseController {
    /**
     * Retrieve all company sizes.
     */
    async get () {
        try {
            const response = await this.$http.get(`${this.apiPath}/lookup/company/size`)
            const responseData = response.data.resultMessage

            return responseData
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}

export default LookupCompanySizeController
