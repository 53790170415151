<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-07-20 11:08:47
 * @ Description: Component displaying the company profile details section in the details tab.
 -->

<template>
    <!-- Desktop -->
    <v-form
        v-if="$vuetify.breakpoint.mdAndUp"
        v-model="validCompanyProfileDetailForm"
        ref="companyProfileDetailForm"
    >
        <v-row
            align="center"
        >
            <!-- Heading -->
            <v-col
                class="flex-shrink-1 flex-grow-0 text-center os-13-r"
            >
                <input
                    type="file"
                    style="display: none"
                    ref="avatar"
                    accept="image/*"
                    @change="avatarSelected"
                >
                <v-avatar
                    size="100"
                    class="mb-2"
                >
                    <v-img
                        :src="avatar ? avatar : '/general/FixerIndividualIcon.png'"
                        contain
                    />
                </v-avatar>
                <span @click="selectAvatar" style="color: rgb(143, 155, 179); cursor: pointer;">Update Image</span>
            </v-col>
            <v-col
                class="flex-grow-1 flex-shrink-0"
            >
                <div
                    class="os-22-sb"
                >
                    {{ companyName }}
                </div>
                <div
                    class="os-13-r"
                    style="color: rgb(143, 155, 179);"
                >
                    Subscription: {{ $store.state.user.userData.subscription_name }}
                </div>
            </v-col>
            <!-- Heading -->

            <!-- Company Details -->
            <v-col
                cols="12"
                class="os-22-sb"
            >
                Company Details
            </v-col>
            <v-col
                cols="12"
                class="pa-0"
            >
                <v-divider />
            </v-col>
            <v-col
                cols="6"
                class="pb-0 os-15-sb"
            >
                Company Name<span style="color: red;">*</span>
                <br />
                <v-text-field
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    v-model="company.company_name"
                    :rules="nameRules"
                    counter="50"
                />
            </v-col>
            <v-col
                cols="6"
                class="pb-0 os-15-sb"
            >
                Number of Employees<span style="color: red;">*</span>
                <br />
                <v-select
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    :items="companySizes"
                    item-color="#2E567A"
                    item-text="name"
                    item-value="id"
                    return-object
                    v-model="company.company_size"
                    :rules="selectRules"
                />
            </v-col>
            <v-col
                cols="6"
                class="py-0 os-15-sb"
            >
                Company Registration
                <br />
                <v-text-field
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    placeholder="This field is highly suggested"
                    v-model="company.company_registration"
                    :rules="registrationRules"
                    counter="50"
                />
            </v-col>
            <!-- Company Details -->

            <!-- Primary Contact -->
            <v-col
                cols="12"
                class="os-22-sb"
            >
                Primary Contact
            </v-col>
            <v-col
                cols="12"
                class="pa-0"
            >
                <v-divider />
            </v-col>
            <v-col
                cols="6"
                class="pb-0 os-15-sb"
            >
                First Name<span style="color: red;">*</span>
                <br />
                <v-text-field
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    v-model="company.first_name"
                    :rules="nameRules"
                    counter="50"
                />
            </v-col>
            <v-col
                cols="6"
                class="pb-0 os-15-sb"
            >
                Last Name<span style="color: red;">*</span>
                <br />
                <v-text-field
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    v-model="company.last_name"
                    :rules="nameRules"
                    counter="50"
                />
            </v-col>
            <v-col
                cols="6"
                class="py-0 os-15-sb"
            >
                Email<span style="color: red;">*</span>
                <br />
                <v-text-field
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    readonly
                    v-model="company.email"
                    :rules="emailRules"
                />
            </v-col>
            <v-col
                cols="6"
                class="py-0 os-15-sb"
            >
                Contact Number
                <br />
                <vue-tel-input-vuetify
                    v-model="company.contact_number_1"
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    @input="contactNumberInput"
                    :rules="contactNumberRules"
                    defaultCountry="za"
                />
            </v-col>
            <!-- Primary Contact -->

            <!-- Reset Password/Update Buttons -->
            <v-col
                cols="12"
                class="text-right pt-0 os-13-sb"
            >
                <v-btn
                    class="white--text no-uppercase rounded-lg mr-4 os-13-sb"
                    color="#2E567A"
                    @click="resetPassword"
                >
                    Reset Password
                </v-btn>
                <v-btn
                    class="white--text no-uppercase rounded-lg os-13-sb"
                    color="#FFA858"
                    @click="updateCompanyProfileDetail"
                    :disabled="!validCompanyProfileDetailForm"
                >
                    Update
                </v-btn>
            </v-col>
            <!-- Reset Password/Update Buttons -->
        </v-row>
    </v-form>
    <!-- Desktop -->

    <!-- Mobile -->
    <v-form
        v-else-if="$vuetify.breakpoint.smAndDown"
        v-model="validCompanyProfileDetailForm"
        ref="companyProfileDetailForm"
    >
        <v-row
            align="center"
        >
            <!-- Heading -->
            <v-col
                :class="$vuetify.breakpoint.sm ? 'flex-shrink-1 flex-grow-0' : ''"
                class="text-center"
                :cols="$vuetify.breakpoint.sm ? '' : '12'"
            >
                <input
                    type="file"
                    style="display: none"
                    ref="avatar"
                    accept="image/*"
                    @change="avatarSelected"
                >
                <v-avatar
                    size="100"
                    class="mb-2"
                >
                    <v-img
                        :src="avatar ? avatar : '/general/FixerIndividualIcon.png'"
                        contain
                    />
                </v-avatar>
                <br />
                <span
                    style="color: rgb(143, 155, 179); cursor: pointer;"
                    class="os-13-r"
                    @click="selectAvatar"
                >
                    Update Image
                </span>
            </v-col>
            <v-col
                :class="$vuetify.breakpoint.sm ? 'flex-grow-1 flex-shrink-0' : 'text-center'"
                :cols="$vuetify.breakpoint.sm ? '' : '12'"
            >
                <div
                    class="os-22-sb"
                >
                    {{ companyName }}
                </div>
                <div
                    class="os-13-r"
                    style="color: rgb(143, 155, 179);"
                >
                    Subscription: {{ $store.state.user.userData.subscription_name }}
                </div>
            </v-col>
            <!-- Heading -->

            <!-- Company Details -->
            <v-col
                cols="12"
                class="os-22-sb"
            >
                Company Details
            </v-col>
            <v-col
                cols="12"
                class="pa-0"
            >
                <v-divider />
            </v-col>
            <v-col
                cols="12"
                sm="6"
                class="pb-0 os-15-sb"
            >
                Company Name<span style="color: red;">*</span>
                <br />
                <v-text-field
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    v-model="company.company_name"
                    :rules="nameRules"
                    counter="50"
                />
            </v-col>
            <v-col
                cols="12"
                sm="6"
                class="os-15-sb"
                :class="$vuetify.breakpoint.sm ? 'pb-0' : 'py-0'"
            >
                Number of Employees<span style="color: red;">*</span>
                <br />
                <v-select
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    :items="companySizes"
                    item-color="#2E567A"
                    item-text="name"
                    item-value="id"
                    return-object
                    v-model="company.company_size"
                    :rules="selectRules"
                />
            </v-col>
            <v-col
                cols="12"
                sm="6"
                class="py-0 os-15-sb"
            >
                Company Registration
                <br />
                <v-text-field
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    placeholder="This field is highly suggested"
                    v-model="company.company_registration"
                    :rules="registrationRules"
                    counter="50"
                />
            </v-col>
            <!-- Company Details -->

            <!-- Primary Contact -->
            <v-col
                cols="12"
                class="os-22-sb"
            >
                Primary Contact
            </v-col>
            <v-col
                cols="12"
                class="pa-0"
            >
                <v-divider />
            </v-col>
            <v-col
                cols="12"
                sm="6"
                class="pb-0 os-15-sb"
            >
                First Name<span style="color: red;">*</span>
                <br />
                <v-text-field
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    v-model="company.first_name"
                    :rules="nameRules"
                    counter="50"
                />
            </v-col>
            <v-col
                cols="12"
                sm="6"
                class="os-15-sb"
                :class="$vuetify.breakpoint.sm ? 'pb-0' : 'py-0'"
            >
                Last Name<span style="color: red;">*</span>
                <br />
                <v-text-field
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    v-model="company.last_name"
                    :rules="nameRules"
                    counter="50"
                />
            </v-col>
            <v-col
                cols="12"
                sm="6"
                class="py-0 os-15-sb"
            >
                Email<span style="color: red;">*</span>
                <br />
                <v-text-field
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    readonly
                    v-model="company.email"
                    :rules="emailRules"
                />
            </v-col>
            <v-col
                cols="12"
                sm="6"
                class="py-0 os-15-sb"
            >
                Contact Number
                <br />
                <vue-tel-input-vuetify
                    v-model="company.contact_number_1"
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    @input="contactNumberInput"
                    :rules="contactNumberRules"
                    defaultCountry="za"
                />
            </v-col>
            <!-- Primary Contact -->

            <!-- Reset Password/Update Buttons -->
            <v-col
                cols="12"
                class="text-right pt-0 os-13-sb"
            >
                <v-btn
                    class="white--text no-uppercase rounded-lg mr-4 os-13-sb"
                    color="#2E567A"
                    @click="resetPassword"
                >
                    Reset Password
                </v-btn>
                <v-btn
                    class="white--text no-uppercase rounded-lg os-13-sb"
                    color="#FFA858"
                    @click="updateCompanyProfileDetail"
                    :disabled="!validCompanyProfileDetailForm"
                >
                    Update
                </v-btn>
            </v-col>
            <!-- Reset Password/Update Buttons -->
        </v-row>
    </v-form>
    <!-- Mobile -->
</template>
<script>
    import { CompanyProfileDetailController, LookupCompanySizeController, GeneralProfileAvatarController, GeneralProfileController, GeneralProfileStatsController } from '@/logic/controller/index.controller.js'

    export default {
        name: 'ProfileCompanyDetailsComponent.vue',

        computed: {
            contactNumberRules () {
                // if (this.company.contact_number_1 && this.validContactNumber) return []
                if (!this.company.contact_number_1) return [ v => !!v || 'Number is required' ]
                else return [
                    v => /^\+[1-9]\d{8,15}$/.test(v.replace(/\s/g, '')) || 'Must be valid number'
                ]
            },

            selectRules () {
                return [
                    v => (!!v && !!v.id) || 'Required Field'
                ]
            },

            registrationRules () {
                if (this.company.company_registration === null || this.company.company_registration === '') return []
                else if (this.company.company_registration.replace(/\s/g, '') === '') return [ 'Invalid registration number' ]
                else return [ v => v.length <= 50 || 'Max 50 characters' ]
            },

            nameRules () { 
                return [
                    v => !!v || 'Required field',
                    v => v.replace(/\s/g, '') !== '' || 'Required field',
                    v => v.length <= 50 || 'Max 50 characters'
                ]
            },

            emailRules () {
                return [
                    v => !!v || 'Required field',
                    v => v.replace(/\s/g, '') !== '' || 'Required field',
                    v => /\S+@\S+\.\S+/.test(v) || 'Invalid e-mail address'
                ]
            }
        },

        data () {
            return {
                companyProfileDetailController: null,
                lookupCompanySizeController: null,
                generalProfileAvatarController: null,
                generalProfileController: null,
                generalProfileStatsController: null,
                company: {
                    id: null,
                    first_name: '',
                    last_name: '',
                    email: '',
                    contact_number_1: '',
                    avatar: null,
                    company_id: null,
                    company_name: '',
                    company_registration: '',
                    company_size: {
                        id: null,
                        name: ''
                    },
                    subscription_name: '',
                    subscription_id: null
                },
                companySizes: [],
                validContactNumber: false,
                companyName: null,
                avatar: null,
                validCompanyProfileDetailForm: false
            }
        },

        beforeMount () {
            this.companyProfileDetailController = new CompanyProfileDetailController()
            this.lookupCompanySizeController = new LookupCompanySizeController()
            this.generalProfileAvatarController = new GeneralProfileAvatarController()
            this.generalProfileController = new GeneralProfileController()
            this.generalProfileStatsController = new GeneralProfileStatsController()
        },

        async mounted () {
            await this.getCompanySizes()
            await this.getCompanyProfileDetail()
        },

        methods: {
            async getCompanySizes () {
                try {
                    this.companySizes = await this.lookupCompanySizeController.get()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getCompanyProfileDetail () {
                try {
                    this.company = await this.companyProfileDetailController.retrieve(this.$store.state.user.userData.company_id)
                    this.companyName = this.company.company_name
                    this.avatar = this.company.avatar
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getProfileCompletionStats () {
                try {
                    const stats = await this.generalProfileStatsController.getCompletionStats()
                    this.$store.commit('user/setUserProfileCompletionStats', stats)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async updateCompanyProfileDetail () {
                try {
                    this.company = await this.companyProfileDetailController.update(this.company)
                    this.companyName = this.company.company_name

                    this.$store.commit('setSnackbar', {
                        text: 'Updated successfully',
                        show: true,
                        color: '#069B34'
                    })

                    let userData = this.$store.state.user.userData
                    userData.first_name = this.company.first_name
                    userData.last_name = this.company.last_name
                    userData.company_name = this.company.company_name
                    this.$store.commit('user/setUserData', userData)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }

                try {
                    await this.getProfileCompletionStats()
                } catch (error) {}
            },

            contactNumberInput (number, isValid, country) {
                if (isValid.number.international) this.company.contact_number_1 = isValid.number.international
                else this.company.contact_number_1 = isValid.number.input
            },

            selectAvatar () {
                this.$refs.avatar.click()
            },

            async avatarSelected (e) {
                const files = e.target.files

                if (files && files.length > 0) {
                    var reader = new FileReader()
                    var f = files[0]

                    var self = this

                    reader.onloadend = async function () {
                        self.avatar = reader.result
                        let avatarFormData = new FormData()
                        avatarFormData.append('image', files[0])

                        const uploadResponse = await self.generalProfileAvatarController.update(avatarFormData)
                        self.company.avatar = uploadResponse.image_url
                        self.avatar = self.company.avatar

                        self.$store.commit('setSnackbar', {
                            text: 'Updated successfully',
                            show: true,
                            color: '#069B34'
                        })

                        let userData = self.$store.state.user.userData
                        userData.avatar = self.avatar
                        self.$store.commit('user/setUserData', userData)

                        try {
                            await self.getProfileCompletionStats()
                        } catch (error) {}
                    }

                    reader.readAsDataURL(f)
                }
            },

            async resetPassword () {
                try {
                    await this.generalProfileController.resetPassword()

                    this.$store.commit('setSnackbar', {
                        text: 'Reset e-mail sent succesfully',
                        show: true,
                        color: '#069B34'
                    })
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            }
        }
    }
</script>
<style>
    .country-code {
        margin-right: 12px;
    }
</style>
<style scoped>
    >>> .v-input__slot.white {
        font-size: 13px !important;
    }
</style>