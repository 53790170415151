/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2020-11-03 12:21:11
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-02-19 16:56:30
 * @ Description: The Twilio Helper class communicates with Twilio's Chat API to perfrom CRUD operations on messages and to manage
 *                the chat participants and channels.
 */

const Conversations = require('@twilio/conversations')
import store from '@/store/index.js'
 
class TwilioHelper {
    constructor (username) {
        this.identity = username
    }
 
    generateChatToken () {
        try {
            const AccessToken = require('twilio').jwt.AccessToken
            const ChatGrant = AccessToken.ChatGrant
 
            const accountSID = process.env.VUE_APP_TWILIO_ACCOUNT_SID
            const apiKey = process.env.VUE_APP_TWILIO_API_KEY
            const apiSecret = process.env.VUE_APP_TWILIO_API_SECRET
 
            const serviceSID = process.env.VUE_APP_TWILIO_CHAT_SERVICE_SID
 
            const chatGrant = new ChatGrant({
                serviceSid: serviceSID
            })
 
            const token = new AccessToken(
                accountSID,
                apiKey,
                apiSecret,
                {
                    identity: this.identity,
                    ttl: 28800
                }
            )
 
            token.addGrant(chatGrant)
 
            const chatToken = token.toJwt()
            return chatToken
        } catch (error) {
            throw Error(error)
        }
    }
 
    async createChatClient () {
        try {
            this.chatClient = await Conversations.Client.create(store.state.twilio.chatToken)
 
            this.chatClient.on('tokenAboutToExpire', function () {
                // // Implement fetchToken() to make a secure request to your backend to retrieve a refreshed access token.
                // // Use an authentication mechanism to prevent token exposure to 3rd parties.
                // fetchToken(function (updatedToken) {
                //     chatClient.updateToken(updatedToken)
                // })
            })
        } catch (error) {
            throw Error(error)
        }
    }
 
    async getSubscribedChannels () {
        try {
            this.subscribedChannels = await this.chatClient.getSubscribedConversations()
            return this.subscribedChannels.items
        } catch (error) {
            throw Error(error)
        }
    }
 
    async getChannel (channelID) {
        try {
            const channel = await this.chatClient.getConversationByUniqueName(channelID)
            return channel
        } catch (error) {
            throw Error(error)
        }
    }
 
    async getChannelMessages (channel) {
        try {
            return await channel.getMessages()
        } catch (error) {
            throw Error(error)
        }
    }
 
    async sendMessage (channel, message) {
        try {
            await channel.sendMessage(message)
        } catch (error) {
            throw Error(error)
        }
    }
 
    async createChannel (channelID, channelName) {
        try {
            var test = await this.chatClient.createConversation({
                uniqueName: channelID,
                friendlyName: channelID
            })
            return test
        } catch (error) {
            throw Error(error)
        }
    }
 
    async joinChannel (channel) {
        try {
            await channel.join()
        } catch (error) {
            throw Error(error)
        }
    }
}
 
export default TwilioHelper
 