/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-01-05 18:42:01
 * @ Description: A controller that handles payment lookup related operations.
 */

import BaseController from '../../base.controller.js'

/**
   * A class with helper methods to perform payment lookup-related functionality.
   */
class LookupPaymentController extends BaseController {
    /**
     * Retrieve all payment options.
     */
    async getPaymentOptions () {
        try {
            const response = await this.$http.get(`${this.apiPath}/lookup/payment/option`)
            const responseData = response.data.resultMessage
  
            return responseData
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
     * Retrieve all payment intervals.
     */
    async getPaymentIntervals () {
        try {
            const response = await this.$http.get(`${this.apiPath}/lookup/payment/interval`)
            const responseData = response.data.resultMessage
  
            return responseData
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
  
export default LookupPaymentController
  