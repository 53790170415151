<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-12-10 16:12:56
 * @ Description: Enter an e-mail address when one is not retrieved from Facebook's Graph API.
 -->

<template>
    <v-dialog
        v-model="showLandingEmailRequiredOverlayBoolean"
        overlay-color="rgb(34, 43, 69)"
        persistent
        scrollable
        max-width="350px"
    >
        <v-card
            color="rgb(249, 251, 253)"
        >
            <v-card-text
                class="pa-3 pb-0"
                style="color: black;"
            >
                <v-form
                    v-model="validEmailForm"
                    ref="emailForm"
                    style="height: 100%;"
                >
                    <v-row
                        style="height: 100%;"
                        class="py-6 px-3"
                    >
                        <v-col
                            cols="12"
                        >
                            <v-img
                                src="/branding/FixerLogoBlue.png"
                                width="100px"
                                class="mx-auto"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            class="os-18-sb text-center"
                        >
                            E-mail Required
                        </v-col>
                        <v-col
                            cols="12"
                            class="os-13-r text-center"
                        >
                            Your e-mail address could not be retrieved from Facebook. Please enter your e-mail address to continue.
                        </v-col>

                        <!-- E-mail Address -->
                        <v-col
                            cols="12"
                            class="py-0 os-15-sb"
                        >
                            Email<span style="color: red;">*</span>
                            <br />
                            <v-text-field
                                dense
                                outlined
                                color="#2E567A"
                                class="pt-2 os-13-r"
                                background-color="white"
                                v-model="email"
                                :rules="emailRules"
                            />
                        </v-col>
                        <!-- E-mail Address -->

                        <!-- Cancel/Continue Buttons -->
                        <v-col
                            cols="12"
                            class="text-right pt-0 os-13-sb"
                        >
                            <v-btn
                                class="white--text no-uppercase rounded-lg mr-4 os-13-sb"
                                color="#2E567A"
                                @click="cancelAuthentication"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                class="white--text no-uppercase rounded-lg os-13-sb"
                                color="#FFA858"
                                :disabled="!validEmailForm"
                                @click="toggleLandingEmailRequiredOverlayComponent(true)"
                            >
                                Continue
                            </v-btn>
                        </v-col>
                        <!-- Cancel/Continue Buttons -->
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'LandingEmailRequiredOverlayComponent',

        props: {
            showLandingEmailRequiredOverlayBoolean: {
                type: Boolean,
                required: true
            }
        },

        computed: {
            emailRules () {
                if (this.email === null || this.email === '') return [ 'Required field' ]
                else if (this.email.replace(/\s/g, '') === '') return [ 'Invalid e-mail address' ]
                else return [ v => /\S+@\S+\.\S+/.test(v) || 'Invalid e-mail address' ]
            }
        },

        data () {
            return {
                validEmailForm: false,
                email: ''
            }
        },

        methods: {
            toggleLandingEmailRequiredOverlayComponent (value) {
                this.$emit('toggleLandingEmailRequiredOverlayComponent', false, value, this.email)
            },

            cancelAuthentication () {
                this.$emit('cancelAuthentication')
            }
        }
    }
</script>
