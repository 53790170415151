/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2020-12-01 15:16:46
 * @ Description: A controller that handles general profile avatar related operations.
 */

import BaseController from '../../../../base.controller.js'

/**
   * A class with helper methods to perform general profile avatar-related functionality.
   */
class GeneralProfileAvatarController extends BaseController {
    /**
      * Upload a profile avatar to the server.
      * @param {FormData} form The form with the file to be uploaded to the server.
      */
    async update (form) {
        try {
            const response = await this.$http.put(`${this.apiPath}/user/profile/avatar`, form)
            const responseData = response.data.resultMessage
 
            return responseData
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
  
export default GeneralProfileAvatarController
  