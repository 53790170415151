/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-02-11 15:12:38
 * @ Description: A controller that handles location lookup related operations.
 */

import BaseController from '../../base.controller.js'

/**
  * A class with helper methods to perform location lookup-related functionality.
  */
class LookupLocationController extends BaseController {
    /**
      * Retrieve all countries.
      */
    async getCountries () {
        try {
            const response = await this.$http.get(`${this.apiPath}/lookup/location/country`)
            const responseData = response.data.resultMessage
 
            return responseData
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
      * Retrieve all currencies.
      */
    async getCurrencies () {
        try {
            const response = await this.$http.get(`${this.apiPath}/lookup/location/currency`)
            const responseData = response.data.resultMessage

            return responseData
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
 
export default LookupLocationController
 