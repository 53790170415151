/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2020-02-10 11:59:22
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-07-11 11:04:40
 * @ Description: The application's route manager.
 */

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'
import ProfileIndividualPage from '../pages/dashboard/profile/individual/ProfileIndividualPage.vue'
import ProfileCompanyPage from '../pages/dashboard/profile/company/ProfileCompanyPage.vue'
import ProfileIndividualDetailsTabComponent from '../components/profile/individual/ProfileIndividualDetailsTabComponent.vue'
import ProfileCompanyDetailsTabComponent from '../components/profile/company/ProfileCompanyDetailsTabComponent.vue'
import { GeneralAuthController } from '@/logic/controller/index.controller.js'
import { App } from '@capacitor/app'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: () => import('../pages/landing/LandingIndexPage.vue'),
        children: [
            {
                path: '/',
                component: () => import('../pages/landing/main/MainPage.vue'),
                name: 'landing',
                meta: {
                    title: '9to5Fixer | Your Business in Your Pocket'
                }
            },
            {
                path: '/privacy',
                component: () => import('../pages/landing/privacy/PrivacyPage.vue'),
                name: 'privacy',
                meta: {
                    title: '9to5Fixer | Privacy Policy'
                }
            },
            {
                path: '/terms',
                component: () => import('../pages/landing/terms/TermsPage.vue'),
                name: 'terms',
                meta: {
                    title: '9to5Fixer | Terms & Conditions'
                }
            },
            {
                path: '/contactus',
                component: () => import('../pages/landing/contactus/ContactUsPage.vue'),
                name: 'contactus',
                meta: {
                    title: '9to5Fixer | Contact Us'
                }
            },
            {
                path: '/browse/jobs',
                component: () => import('../pages/landing/search/job/LandingSearchJobPage.vue'),
                name: 'browseJobs',
                meta: {
                    title: '9to5Fixer | Browse Jobs'
                }
            },
            {
                path: '/browse/talent',
                component: () => import('../pages/landing/search/talent/LandingSearchTalentPage.vue'),
                name: 'browseTalent',
                meta: {
                    title: '9to5Fixer | Browse Talent'
                }
            }
        ]
    },
    {
        path: '/signup',
        component: () => import('../pages/landing/signup/SignupPage.vue'),
        name: 'signup',
        meta: {
            title: '9to5Fixer | Signup Options'
        }
    },
    {
        path: '/signup/company',
        component: () => import('../pages/landing/signup/company/SignupCompanyPage.vue'),
        name: 'signupCompany',
        meta: {
            title: '9to5Fixer | Company Signup'
        }
    },
    {
        path: '/signup/individual',
        component: () => import('../pages/landing/signup/individual/SignupIndividualPage.vue'),
        name: 'signupIndividual',
        meta: {
            title: '9to5Fixer | Individual Signup'
        }
    },
    {
        path: '/login/resetpassword/:token',
        component: () => import('../pages/landing/signup/general/SignupGeneralCreatePasswordPage.vue'),
        name: 'loginResetPassword',
        meta: {
            title: '9to5Fixer | Reset Password',
            requiresToken: true
        }
    },
    {
        path: '/dashboard',
        component: () => import('../pages/dashboard/DashboardIndexPage.vue'),
        meta: {
            requiresToken: true
        },
        children: [
            {
                path: '/dashboard',
                component: () => import('../pages/dashboard/home/HomePage.vue'),
                name: 'dashboard',
                meta: {
                    title: '9to5Fixer | Your Dashboard',
                    requiresToken: true
                }
            },
            {
                path: '/calendar',
                component: () => import('../pages/dashboard/calendar/CalendarPage.vue'),
                name: 'calendar',
                meta: {
                    title: '9to5Fixer | Calendar',
                    requiresToken: true
                }
            },
            {
                path: '/profile',
                component: {
                    render (component) {
                        if (store.state.user.userContext === 'individual') {
                            return component(ProfileIndividualPage)
                        } if (store.state.user.userContext === 'company') {
                            return component(ProfileCompanyPage)
                        }
                    }
                },
                name: 'profile',
                meta: {
                    title: '9to5Fixer | Profile'
                },
                children: [
                    {
                        path: '/profile',
                        component: {
                            render (component) {
                                if (store.state.user.userContext === 'individual') {
                                    return component(ProfileIndividualDetailsTabComponent)
                                } if (store.state.user.userContext === 'company') {
                                    return component(ProfileCompanyDetailsTabComponent)
                                }
                            }
                        },
                        name: 'profileDetails',
                        meta: {
                            title: '9to5Fixer | Profile Details',
                            requiresToken: true
                        }
                    },
                    {
                        path: '/profile/services',
                        component: () => import('../components/profile/general/ProfileServicesTabComponent.vue'),
                        name: 'profileServices',
                        meta: {
                            title: '9to5Fixer | Profile Services',
                            requiresToken: true
                        }
                    },
                    {
                        path: '/profile/job_titles',
                        component: () => import('../components/profile/general/ProfileIndustriesJobTitlesTabComponent.vue'),
                        name: 'profileJobTitles',
                        meta: {
                            title: '9to5Fixer | Profile Job Titles',
                            requiresToken: true,
                            context: 'individual'
                        }
                    },
                    {
                        path: '/profile/industries',
                        component: () => import('../components/profile/general/ProfileIndustriesJobTitlesTabComponent.vue'),
                        name: 'profileIndustries',
                        meta: {
                            title: '9to5Fixer | Profile Industries',
                            requiresToken: true,
                            context: 'company'
                        }
                    },
                    {
                        path: '/profile/education',
                        component: () => import('../components/profile/individual/ProfileIndividualEducationTabComponent.vue'),
                        name: 'profileEducation',
                        meta: {
                            title: '9to5Fixer | Profile Education',
                            requiresToken: true,
                            context: 'individual'
                        }
                    },
                    {
                        path: '/profile/experience',
                        component: () => import('../components/profile/individual/ProfileIndividualExperienceTabComponent.vue'),
                        name: 'profileExperience',
                        meta: {
                            title: '9to5Fixer | Profile Experience',
                            requiresToken: true,
                            context: 'individual'
                        }
                    },
                    {
                        path: '/profile/account',
                        component: () => import('../components/profile/general/ProfileAccountTabComponent.vue'),
                        name: 'profileAccount',
                        meta: {
                            title: '9to5Fixer | Profile Account',
                            requiresToken: true
                        }
                    },
                    {
                        path: '/profile/reviews',
                        component: () => import('../components/profile/general/ProfileReviewsTabComponent.vue'),
                        name: 'profileReviews',
                        meta: {
                            title: '9to5Fixer | Profile Reviews',
                            requiresToken: true
                        }
                    }
                ],
            },
            {
                path: '/profile/company/:id',
                component: () => import('../pages/dashboard/profile/public/company/ProfilePublicCompanyPage.vue'),
                name: 'publicCompanyProfile',
                meta: {
                    title: '9to5Fixer | Company Profile'
                },
                children: [
                    {
                        path: '/profile/company/:id',
                        component: () => import('../components/profile/public/company/ProfilePublicCompanyDetailsTabComponent.vue'),
                        name: 'publicCompanyProfileDetails',
                        meta: {
                            title: '9to5Fixer | Company Details',
                            requiresToken: true
                        }
                    },
                    {
                        path: '/profile/company/:id/services',
                        component: () => import('../components/profile/public/general/ProfilePublicServicesTabComponent.vue'),
                        name: 'publicCompanyProfileServices',
                        meta: {
                            title: '9to5Fixer | Company Services',
                            requiresToken: true
                        }
                    },
                    {
                        path: '/profile/company/:id/industries',
                        component: () => import('../components/profile/public/general/ProfilePublicIndustriesJobTitlesTabComponent.vue'),
                        name: 'publicCompanyProfileIndustries',
                        meta: {
                            title: '9to5Fixer | Company Industries',
                            requiresToken: true
                        }
                    },
                    {
                        path: '/profile/company/:id/reviews',
                        component: () => import('../components/profile/public/general/ProfilePublicReviewsTabComponent.vue'),
                        name: 'publicCompanyProfileReviews',
                        meta: {
                            title: '9to5Fixer | Company Reviews',
                            requiresToken: true
                        }
                    }
                ]
            },
            {
                path: '/profile/individual/:id',
                component: () => import('../pages/dashboard/profile/public/individual/ProfilePublicIndividualPage.vue'),
                name: 'publicIndividualProfile',
                meta: {
                    title: '9to5Fixer | Individual Profile'
                },
                children: [
                    {
                        path: '/profile/individual/:id',
                        component: () => import('../components/profile/public/individual/ProfilePublicIndividualDetailsTabComponent.vue'),
                        name: 'publicIndividualProfileDetails',
                        meta: {
                            title: '9to5Fixer | Individual Details',
                            requiresToken: true
                        }
                    },
                    {
                        path: '/profile/individual/:id/services',
                        component: () => import('../components/profile/public/general/ProfilePublicServicesTabComponent.vue'),
                        name: 'publicIndividualProfileServices',
                        meta: {
                            title: '9to5Fixer | Individual Services',
                            requiresToken: true
                        }
                    },
                    {
                        path: '/profile/individual/:id/job_titles',
                        component: () => import('../components/profile/public/general/ProfilePublicIndustriesJobTitlesTabComponent.vue'),
                        name: 'publicIndividualProfileJobTitles',
                        meta: {
                            title: '9to5Fixer | Individual Job Titles',
                            requiresToken: true
                        }
                    },
                    {
                        path: '/profile/individual/:id/education',
                        component: () => import('../components/profile/public/individual/ProfilePublicIndividualEducationTabComponent.vue'),
                        name: 'publicIndividualProfileEducation',
                        meta: {
                            title: '9to5Fixer | Individual Education',
                            requiresToken: true
                        }
                    },
                    {
                        path: '/profile/individual/:id/experience',
                        component: () => import('../components/profile/public/individual/ProfilePublicIndividualExperienceTabComponent.vue'),
                        name: 'publicIndividualProfileExperience',
                        meta: {
                            title: '9to5Fixer | Individual Experience',
                            requiresToken: true
                        }
                    },
                    {
                        path: '/profile/individual/:id/reviews',
                        component: () => import('../components/profile/public/general/ProfilePublicReviewsTabComponent.vue'),
                        name: 'publicIndividualProfileReviews',
                        meta: {
                            title: '9to5Fixer | Individual Reviews',
                            requiresToken: true
                        }
                    }
                ]
            },
            {
                path: '/job/all',
                component: () => import('../pages/dashboard/work/job/WorkJobPage.vue'),
                name: 'jobs',
                meta: {
                    title: '9to5Fixer | Jobs'
                },
                children: [
                    {
                        path: '/job/all',
                        component: () => import('../pages/dashboard/work/job/dashboard/general/WorkJobDashboardGeneralPage.vue'),
                        name: 'jobDashboard',
                        meta: {
                            title: '9to5Fixer | All Jobs',
                            requiresToken: true
                        }
                    },
                    {
                        path: '/job/active',
                        component: () => import('../pages/dashboard/work/job/dashboard/general/WorkJobDashboardGeneralPage.vue'),
                        name: 'jobActiveDashboard',
                        meta: {
                            title: '9to5Fixer | Active Jobs',
                            requiresToken: true
                        },
                    },
                    {
                        path: '/job/pending',
                        component: () => import('../pages/dashboard/work/job/dashboard/general/WorkJobDashboardGeneralPage.vue'),
                        name: 'jobPendingDashboard',
                        meta: {
                            title: '9to5Fixer | Pending Jobs',
                            requiresToken: true,
                            context: 'company'
                        },
                    },
                    {
                        path: '/job/drafts',
                        component: () => import('../pages/dashboard/work/job/dashboard/general/WorkJobDashboardGeneralPage.vue'),
                        name: 'jobDraftDashboard',
                        meta: {
                            title: '9to5Fixer | Draft Jobs',
                            requiresToken: true,
                            context: 'company'
                        }
                    },
                    {
                        path: '/job/history',
                        component: () => import('../pages/dashboard/work/job/dashboard/general/WorkJobDashboardGeneralPage.vue'),
                        name: 'jobHistoryDashboard',
                        meta: {
                            title: '9to5Fixer | Job History',
                            requiresToken: true
                        }
                    },
                    {
                        path: '/job/search',
                        component: () => import('../pages/dashboard/work/job/dashboard/individual/WorkJobDashboardIndividualSearchFavouritesPendingPage.vue'),
                        name: 'jobSearch',
                        meta: {
                            title: '9to5Fixer | Search Jobs',
                            requiresToken: true,
                            context: 'individual'
                        }
                    },
                    {
                        path: '/job/applications',
                        component: () => import('../pages/dashboard/work/job/dashboard/individual/WorkJobDashboardIndividualSearchFavouritesPendingPage.vue'),
                        name: 'jobApplications',
                        meta: {
                            title: '9to5Fixer | Job Applications',
                            requiresToken: true,
                            context: 'individual'
                        }
                    },
                    {
                        path: '/job/favourites',
                        component: () => import('../pages/dashboard/work/job/dashboard/individual/WorkJobDashboardIndividualSearchFavouritesPendingPage.vue'),
                        name: 'jobFavourites',
                        meta: {
                            title: '9to5Fixer | Favourite Jobs',
                            requiresToken: true,
                            context: 'individual'
                        }
                    },
                    {
                        path: '/job/applicants',
                        component: () => import('../pages/dashboard/work/job/dashboard/company/WorkJobDashboardCompanyApplicantsPage.vue'),
                        name: 'jobApplicants',
                        meta: {
                            title: '9to5Fixer | Job Applicants',
                            requiresToken: true,
                            context: 'company'
                        }
                    },
                    {
                        path: '/job/:job_id/applicant/:applicant_id',
                        component: () => import('../pages/dashboard/work/job/detail/general/WorkJobDetailGeneralApplicantPage.vue'),
                        name: 'jobApplicantDetail',
                        meta: {
                            title: '9to5Fixer | Job Applicant Detail',
                            requiresToken: true
                        }
                    }
                ]
            },
            {
                path: '/job/:id/application',
                component: () => import('../pages/dashboard/work/job/action/individual/WorkJobActionIndividualApplicationPage.vue'),
                name: 'jobApplication',
                meta: {
                    title: '9to5Fixer | Job Application',
                    requiresToken: true,
                    context: 'individual'
                }
            },
            {
                path: '/job/:id/view',
                component: () => import('../pages/dashboard/work/job/action/general/WorkJobActionGeneralViewPage.vue'),
                name: 'jobViewEdit',
                meta: {
                    title: '9to5Fixer | Job View',
                    requiresToken: true
                }
            },
            {
                path: '/job/:job_id/overview/:applicant_id',
                component: () => import('../pages/dashboard/work/job/detail/general/WorkJobDetailGeneralOverviewPage.vue'),
                name: 'jobOverview',
                meta: {
                    title: '9to5Fixer | Job Overview',
                    requiresToken: true
                }
            },
            {
                path: '/service/all',
                component: () => import('../pages/dashboard/work/professionalService/WorkProfessionalServicePage.vue'),
                name: 'services',
                meta: {
                    title: '9to5Fixer | Professional Services'
                },
                children: [
                    {
                        path: '/service/search',
                        component: () => import('../pages/dashboard/work/professionalService/dashboard/general/WorkProfessionalServiceDashboardGeneralSearchFavouritesPage.vue'),
                        name: 'serviceSearch',
                        meta: {
                            title: '9to5Fixer | Search Professional Services',
                            requiresToken: true
                        }
                    },
                    {
                        path: '/service/favourites',
                        component: () => import('../pages/dashboard/work/professionalService/dashboard/general/WorkProfessionalServiceDashboardGeneralSearchFavouritesPage.vue'),
                        name: 'serviceFavourites',
                        meta: {
                            title: '9to5Fixer | Favourite Professional Services',
                            requiresToken: true
                        }
                    },
                    {
                        path: '/service/requested/active',
                        component: () => import('../pages/dashboard/work/professionalService/dashboard/general/WorkProfessionalServiceDashboardGeneralRequestedPage.vue'),
                        name: 'serviceRequestedActiveDashboard',
                        meta: {
                            title: '9to5Fixer | Active Requested Professional Services',
                            requiresToken: true
                        }
                    },
                    {
                        path: '/service/requested/pending',
                        component: () => import('../pages/dashboard/work/professionalService/dashboard/general/WorkProfessionalServiceDashboardGeneralRequestedPage.vue'),
                        name: 'serviceRequestedPendingDashboard',
                        meta: {
                            title: '9to5Fixer | Pending Requested Professional Services',
                            requiresToken: true
                        }
                    },
                    {
                        path: '/service/requested/history',
                        component: () => import('../pages/dashboard/work/professionalService/dashboard/general/WorkProfessionalServiceDashboardGeneralRequestedPage.vue'),
                        name: 'serviceRequestedHistoryDashboard',
                        meta: {
                            title: '9to5Fixer | Requested Professional Service History',
                            requiresToken: true
                        }
                    },
                    {
                        path: '/service/provided/active',
                        component: () => import('../pages/dashboard/work/professionalService/dashboard/general/WorkProfessionalServiceDashboardGeneralProvidedPage.vue'),
                        name: 'serviceProvidedActiveDashboard',
                        meta: {
                            title: '9to5Fixer | Active Provided Professional Services',
                            requiresToken: true
                        }
                    },
                    {
                        path: '/service/provided/pending',
                        component: () => import('../pages/dashboard/work/professionalService/dashboard/general/WorkProfessionalServiceDashboardGeneralProvidedPage.vue'),
                        name: 'serviceProvidedPendingDashboard',
                        meta: {
                            title: '9to5Fixer | Pending Provided Professional Services',
                            requiresToken: true
                        }
                    },
                    {
                        path: '/service/provided/history',
                        component: () => import('../pages/dashboard/work/professionalService/dashboard/general/WorkProfessionalServiceDashboardGeneralProvidedPage.vue'),
                        name: 'serviceProvidedHistoryDashboard',
                        meta: {
                            title: '9to5Fixer | Provided Professional Service History',
                            requiresToken: true
                        }
                    }
                ]
            },
            {
                path: '/service/:id/request',
                component: () => import('../pages/dashboard/work/professionalService/action/general/WorkProfessionalServiceActionGeneralRequestPage.vue'),
                name: 'serviceRequest',
                meta: {
                    title: '9to5Fixer | Professional Service Request',
                    requiresToken: true
                }
            },
            {
                path: '/service/:service_id/requested/:request_id',
                component: () => import('../pages/dashboard/work/professionalService/action/general/WorkProfessionalServiceActionGeneralRequestedPage.vue'),
                name: 'serviceRequested',
                meta: {
                    title: '9to5Fixer | Professional Service Requested',
                    requiresToken: true
                }
            },
            {
                path: '/service/:id/view',
                component: () => import('../pages/dashboard/work/professionalService/action/general/WorkProfessionalServiceActionGeneralViewPage.vue'),
                name: 'professionalServiceView',
                meta: {
                    title: '9to5Fixer | Professional Service View',
                    requiresToken: true
                }
            },
            {
                path: '/admin/dashboard',
                component: () => import('../pages/admin/home/AdminHomePage.vue'),
                name: 'adminDashboard',
                meta: {
                    title: '9to5Fixer | Admin Dashboard',
                    requiresToken: true
                }
            },
            {
                path: '/admin/industries',
                component: () => import('../pages/admin/industry/AdminIndustryPage.vue'),
                name: 'adminIndustries',
                meta: {
                    title: '9to5Fixer | Admin Manage Industries',
                    requiresToken: true
                }
            },
            {
                path: '/admin/job_titles',
                component: () => import('../pages/admin/jobTitle/AdminJobTitlePage.vue'),
                name: 'adminJobTitles',
                meta: {
                    title: '9to5Fixer | Admin Manage Job Titles',
                    requiresToken: true
                }
            },
            {
                path: '/admin/news_feed',
                component: () => import('../pages/admin/newsFeed/AdminNewsFeedPage.vue'),
                name: 'adminNewsFeed',
                meta: {
                    title: '9to5Fixer | Admin Manage News Feed',
                    requiresToken: true
                }
            }
        ]
    },
    {
        path: '/payment/*',
        component: () => import('../pages/general/GeneralPaymentRedirectPage.vue'),
        name: 'paymentRedirect',
        meta: {
            title: '9to5Fixer | Payment Gateway Redirect'
        }
    },
    {
        path: '/:cb',
        component: () => import('../pages/general/GeneralFacebookRedirectPage.vue'),
        name: 'facebookRedirect',
        meta: {
            title: '9to5Fixer | Authenticate Facebook User'
        }
    },
    {
        path: '/googleAuthenticate/:code',
        component: () => import('../pages/general/GeneralGoogleAuthenticatePage.vue'),
        name: 'googleAuthenticate',
        meta: {
            title: '9to5Fixer | Authenticate Google User'
        }
    },
    {
        path: '*',
        name: '404', 
        component: () => import('../pages/general/GeneralNotFoundPage.vue'),
        meta: {
            title: '9to5Fixer | Page Not Found'
        }
    }
] 

const router = new VueRouter({
    // mode: 'history',
    routes,
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
})

App.addListener('appUrlOpen', function (event) {
    const slug = event.url.split(process.env.VUE_APP_DEEPLINK).pop();

    if (slug) {
        if (slug.includes('access_token=')) {
            var code = slug.split('access_token=')[1].split('&')[0]

            router.push(`googleAuthenticate/${code}`)
        } else router.push(slug)
    }
})

router.afterEach((to, from) => {
    store.commit('setSignupOverlay', false)
    store.commit('setLoginOverlay', false)
    store.commit('setEmailVerificationOverlay', false)

    Vue.nextTick(() => {
        document.title = to.meta.title || '9to5fixer | Your Business in Your Pocket'
    })

    if (!store.state.user.userData.token && !store.state.signup.signupData.token && !from.params.token) {
        if (from.meta.requiresToken && router.currentRoute.path !== '/') {
            store.commit('user/resetUserData')
        }
    }
})

router.beforeEach(async (to, from, next) => {
    store.commit('setCurrentRoute', from.fullPath)

    // if (store.state.user.userData === null) {
    //     store.commit('user/setUserData', {})

    //     store.commit('setSnackbar', {
    //         text: 'Please clear cache',
    //         show: true
    //     })
    // }

    // If the URL was clicked on externally e.g. from an e-mail
    if (to.query.external) store.commit('setExternalLink', to.path)

    // Set the global AxiosHelper token if it doesn't exist
    if (!Vue.prototype.$http.token) Vue.prototype.$http.token = store.state.user.userData.token

    // User has the incorrect context/account type for specified route
    if (to.meta.context && (store.state.user.userContext !== to.meta.context)) router.push('/404')

    /* if (to.path === '/' && store.state.user.userData.token) {
        try {
            const generalAuthController = new GeneralAuthController()
            await generalAuthController.authenticate()

            next()
        } catch {
            next()
        }
    } else */

    // If a user token exists and the specified route requires authentication
    if (to.meta.requiresToken && store.state.user.userData.token) {
        try {
            // Validate a user token
            const generalAuthController = new GeneralAuthController()
            await generalAuthController.authenticate()

            // Token is valid
            next()
        } catch {
            // Token has expired
            store.commit('setSnackbar', {
                text: 'Token expired - Please log in',
                show: true
            })

            // router.push('/').catch(() => true)
            store.commit('user/resetUserData')
        }
    // No token is found and the specified route requires authentication
    } else if (to.meta.requiresToken && !store.state.user.userData.token && !store.state.signup.signupData.token && !to.params.token) {
        store.commit('setSnackbar', {
            text: 'Please log in',
            show: true
        })

        // router.push('/').catch(() => true)
        store.commit('user/resetUserData')
    } else next()
})

export default router
