/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-01-07 11:30:00
 * @ Description: A controller that handles general profile service related operations.
 */

import BaseController from '../../../../base.controller.js'

/**
* A class with helper methods to perform general profile service-related functionality.
*/
class GeneralProfileServiceController extends BaseController {
    /**
     * Retrieve a profile's services.
     */
    async retrieve () {
        try {
            const result = await this.$http.get(`${this.apiPath}/user/profile/service`)
  
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
    * Upsert a profile service.
    * @param {FormData} service The service form to upsert.
    */
    async upsert (service) {
        try {
            const result = await this.$http.put(`${this.apiPath}/user/profile/service`, service)
   
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
    * Delete profile service's location.
    * @param {Object} serviceLocation The service location object to delete.
    */
    async deleteLocation (serviceLocation) {
        try {
            const result = await this.$http.delete(`${this.apiPath}/user/profile/service/location`, serviceLocation)
   
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
   
export default GeneralProfileServiceController
   