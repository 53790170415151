/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2020-12-01 15:16:46
 * @ Description: A controller that handles general profile document related operations.
 */

import BaseController from '../../../../base.controller.js'

/**
     * A class with helper methods to perform general profile document-related functionality.
     */
class GeneralProfileDocumentController extends BaseController {
    /**
     * Retrieve a profile's documents.
     */
    async retrieve () {
        try {
            const result = await this.$http.get(`${this.apiPath}/user/profile/document`)
    
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
   
    /**
     * Upload profile's document.
     * @param {FormData} document The document object to upload.
     */
    async create (document) {
        try {
            const result = await this.$http.put(`${this.apiPath}/user/profile/document`, document)
    
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
 
    /**
     * Delete profile's document.
     * @param {Object} document The document object to delete.
     */
    async delete (document) {
        try {
            const result = await this.$http.delete(`${this.apiPath}/user/profile/document`, document)
    
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
     * Download profile's document.
     * @param {Object} document The document object to download.
     */
    async download (document) {
        try {
            const result = await this.$http.post(`${this.apiPath}/user/profile/document/download`, document, 'blob')
    
            return result.data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
    
export default GeneralProfileDocumentController
    