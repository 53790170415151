/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-29 13:05:40
 * @ Description: A controller that handles individual profile social related operations.
 */

import BaseController from '../../../../base.controller.js'

/**
   * A class with helper methods to perform individual profile social-related functionality.
   */
class IndividualProfileSocialController extends BaseController {
    /**
       * Retrieve a individual profile's social.
       * @param {Number} userID The ID of the user to retrieve.
       */
    async retrieve (userID) {
        try {
            const result = await this.$http.get(`${this.apiPath}/individual/${userID}/profile/social`)
  
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
 
    /**
       * Update a individual profile's social.
       * @param {Object} social The individual profile social object to update.
       */
    async update (social) {
        try {
            const result = await this.$http.put(`${this.apiPath}/individual/profile/social`, social)
  
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
  
export default IndividualProfileSocialController
  