<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-24 13:34:31
 * @ Description: Component for providing error/success feedback to user interactions.
 -->

<template>
    <v-snackbar
        v-model="$store.state.general.showSnackbar.show"
        :timeout="timeout"
        :color="color"
        top
    >
        <v-row
            justify="center"
            align="center"
            class="pa-0"
        >
            <!-- Message -->
            <v-col
                cols="10"
                class="pa-0 pl-2 font-weight-medium"
            >
                {{ text }}
            </v-col>
            <!-- Message -->

            <v-col
                cols="2"
                class="text-right pa-0"
            >
                <!-- Close Button -->
                <v-btn
                    @click="closeSnackbar"
                    icon
                >
                    <v-icon
                        color="white"
                    >
                        {{ mdiClose }}
                    </v-icon>
                </v-btn>
                <!-- Close Button -->
            </v-col>
        </v-row>
    </v-snackbar>
</template>

<script>
    import { mdiClose } from '@mdi/js'

    export default {
        name: 'GeneralSnackbarComponent',

        data () {
            return {
                color: '#E2564C',
                timeout: 5000,
                text: 'This is an error message.',
                mdiClose
            }
        },

        watch: {
            '$store.state.general.showSnackbar': {
                handler (value) {
                    if (value.color) this.color = value.color
                    else this.color = '#E2564C'

                    if (value.timeout) this.timeout = value.timeout
                    if (value.text) this.text = value.text
                },
                immediate: true
            }
        },

        methods: {
            closeSnackbar () {
                this.$store.commit('setSnackbar', {
                    text: null,
                    show: false,
                    color: this.color
                })
            }
        }
    }
</script>
