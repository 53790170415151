<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-13 18:43:04
 * @ Description: Overlay to inform a user that a password reset e-mail has been sent.
 -->

<template>
    <!-- Desktop and Bigger Mobiles -->
    <v-dialog
        v-model="$store.state.overlay.overlays.landing.emailVerification"
        overlay-color="rgb(34, 43, 69)"
        persistent
        scrollable
        v-if="$vuetify.breakpoint.smAndUp"
        max-width="555px"
    >
        <v-card
            style="position: relative;"
            color="rgb(249, 251, 253)"
            max-height="705px"
        >
            <v-card-text
                style="max-height: 640px;  color: black;"
                class="py-8"
            >
                <!-- Close Button -->
                <v-btn
                    icon
                    style="position: absolute; right: 16px;"
                    @click="hideVerifyEmailOverlay"
                    aria-label="close"
                >
                    <v-icon
                        color="black"
                        style="z-index: 10;"
                    >
                        {{ mdiCloseThick }}
                    </v-icon>
                </v-btn>
                <!-- Close Button -->

                <v-row
                    justify="center"
                >
                    <v-col
                        cols="12"
                        style="max-width: 325px;"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-img
                                    src="/branding/FixerLogoBlue.png"
                                    width="135px"
                                    class="mx-auto"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                class="text-center os-29-sb"
                            >
                                Verify Email
                            </v-col>
                            <v-col
                                cols="12"
                                class="pt-8 pb-8"
                            >
                                <v-img
                                    src="/landing/verify/LandingVerifyCheck.png"
                                    width="135px"
                                    height="135px"
                                    contain
                                    class="mx-auto"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                class="text-center os-14-r"
                            >
                                Please check your inbox for an account <br />
                                verification email.
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>

            <!-- Footer -->
            <v-card-actions
                style="display: flex; justify-content: center; align-items: center; height: 55px; background-color: rgb(253, 227, 203);"
                class="os-14-sb"
            >
                Already a member? &nbsp; <span @click="showLoginOverlay" style="text-decoration: underline; cursor: pointer;">Sign In</span>
            </v-card-actions>
            <!-- Footer -->
        </v-card>
    </v-dialog>
    <!-- Desktop and Bigger Mobiles -->

    <!-- Mobile -->
    <v-dialog
        v-model="$store.state.overlay.overlays.landing.emailVerification"
        fullscreen
        scrollable
        v-else-if="$vuetify.breakpoint.xs"
        style="z-index: 10;"
    >
        <v-card
            style="position: relative;"
            color="rgb(249, 251, 253)"
        >
            <v-card-text
                style="height: 100vh; color: black;"
                class="py-8"
            >
                <!-- Close Button -->
                <v-btn
                    icon
                    style="position: absolute; right: 16px; top: 12px;"
                    @click="hideVerifyEmailOverlay"
                    aria-label="close"
                >
                    <v-icon
                        color="black"
                        style="z-index: 10;"
                    >
                        {{ mdiCloseThick }}
                    </v-icon>
                </v-btn>
                <!-- Close Button -->

                <v-row
                    justify="center"
                >
                    <v-col
                        cols="12"
                        style="max-width: 300px;"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-img
                                    src="/branding/FixerLogoBlue.png"
                                    width="135px"
                                    class="mx-auto"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                class="text-center os-29-sb"
                            >
                                Verify Email
                            </v-col>
                            <v-col
                                cols="12"
                                class="pt-8 pb-8"
                            >
                                <v-img
                                    src="/landing/verify/LandingVerifyCheck.png"
                                    width="135px"
                                    height="135px"
                                    contain
                                    class="mx-auto"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                class="text-center os-14-r"
                            >
                                Please check your inbox for an account <br />
                                verification email.
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>

            <!-- Footer -->
            <v-card-actions
                style="display: flex; justify-content: center; align-items: center; height: 55px; background-color: rgb(253, 227, 203);"
                class="os-14-sb"
            >
                Already a member? &nbsp; <span @click="showLoginOverlay" style="text-decoration: underline; cursor: pointer;">Sign In</span>
            </v-card-actions>
            <!-- Footer -->
        </v-card>
    </v-dialog>
    <!-- Mobile -->
</template>

<script>
    import { mdiCloseThick } from '@mdi/js'

    export default {
        name: 'VLandingerifyEmailAddressOverlayComponent',

        data () {
            return {
                mdiCloseThick
            }
        },

        methods: {
            hideVerifyEmailOverlay () {
                this.$store.commit('setEmailVerificationOverlay', false)
                if (this.$route.path !== '/') this.$router.push('/')
            },

            showLoginOverlay () {
                this.$store.commit('setLoginOverlay', true)
            }
        }
    }
</script>
<style scoped>
    >>> .v-text-field input {
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
        font-size: 0.813rem !important;
        font-weight: 600 !important;
    }
</style>
