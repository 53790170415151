/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-04-26 15:50:34
 * @ Description: A controller that handles news post related operations.
 */

import BaseController from '../base.controller.js'

/**
* A class with helper methods to perform news post-related functionality.
*/
class NewsPostController extends BaseController {
    /**
     * Retrive all news posts.
     * @param {Number} pageNumber The page number to retrieve.
     * @param {Number} pageSize The page size to retrieve.
     */
    async get (pageNumber, pageSize) {
        try {
            let searchString = ''
            let result

            if (pageNumber) searchString += `&pageNumber=${pageNumber}`
            if (pageSize) searchString += `&pageSize=${pageSize}`

            if (searchString !== '') result = await this.$http.get(`${this.apiPath}/newsPost?${searchString}`)
            else result = await this.$http.get(`${this.apiPath}/newsPost`)
   
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
 
    /**
     * Upsert a news post.
     * @param {Object} newsPost The news post to upsert.
     */
    async upsert (newsPost) {
        try {
            const response = await this.$http.put(`${this.apiPath}/newsPost`, newsPost)
            const responseData = response.data.resultMessage
 
            return responseData
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
     * Upsert a news post's banner image.
     * @param {Number} newsPostID The news post ID of the banner image being upserted.
     * @param {FormData} form The form with the file to be uploaded to the server.
     */
    async upsertBanner (newsPostID, form) {
        try {
            const result = await this.$http.put(`${this.apiPath}/newsPost/${newsPostID}/banner`, form)
  
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
      * Delete a news post.
      * @param {Object} newsPost The news post object to delete.
      */
    async delete (newsPost) {
        try {
            const result = await this.$http.delete(`${this.apiPath}/newsPost/${newsPost.id}`)
 
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
   
export default NewsPostController
   