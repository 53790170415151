/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2020-12-01 15:16:46
 * @ Description: A controller that handles general profile subscription related operations.
 */

import BaseController from '../../../../base.controller.js'

/**
  * A class with helper methods to perform general profile subscription-related functionality.
  */
class GeneralProfileSubscriptionController extends BaseController {
    /**
      * Retrieve a profile's current subscription and the upgrade options.
      */
    async retrieve () {
        try {
            const result = await this.$http.get(`${this.apiPath}/user/profile/subscription`)
       
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
 
    /**
      * Update a profile's current subscription.
      * @param {Object} subscription The selected subscription object to upgrade to.
      */
    async update (subscription) {
        try {
            const result = await this.$http.put(`${this.apiPath}/user/profile/subscription`, subscription)
       
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
       
export default GeneralProfileSubscriptionController
       