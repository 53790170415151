/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-12-11 13:12:15
 * @ Description: A controller that handles general home dashboard related operations.
 */

import BaseController from '../../../base.controller.js'

/**
 * A class with helper methods to perform general home dashboard-related functionality.
 */
class GeneralHomeController extends BaseController {
    /**
     * Retrieve a user's active tasks.
     */
    async retrieveActiveTasks () {
        try {
            const result = await this.$http.get(`${this.apiPath}/user/home/task`)
    
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
     * Retrieve a user's dashboard stats.
     */
    async retrieveStats () {
        try {
            const result = await this.$http.get(`${this.apiPath}/user/home/stats`)
    
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
    
export default GeneralHomeController
    