<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-30 08:25:30
 * @ Description: Component for retrieving Google Places location data.
 -->

<template>
    <v-form
        ref="autocompleteForm"
        v-model="validAutocompleteForm"
    >
        <!-- Autocomplete -->
        <gmap-autocomplete
            @place_changed="setPlace"
            id="gmapAutocomplete"
        >
            <template
                v-slot:input="slotProps"
            >
                <v-text-field
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    :placeholder="placeholder"
                    ref="input"
                    v-on:listeners="slotProps.listeners"
                    v-on:attrs="slotProps.attrs"
                    v-model="textAddress"
                    @input="addressChanged"
                    :rules="addressRules"
                    :clearable="clearable"
                    @click:clear="clearAddress"
                    :disabled="disabled"
                    :hide-details="hideDetails"
                    @keydown="preventFormSubmit($event)"
                />
            </template>
        </gmap-autocomplete>
        <!-- Autocomplete -->
    </v-form>
</template>
<script>
    import { PlaceModel } from '@/logic/model/index.model.js'

    export default {
        name: 'GeneralGoogleAutocompleteAddressComponent',

        props: {
            placeholder: {
                type: String
            },

            address: {
                type: Object,
                default: () => {
                    return {}
                }
            },

            editMode: {
                type: Boolean,
                default: true
            },

            hideDetails: {
                type: Boolean,
                default: false
            },

            clearable: {
                type: Boolean,
                default: false
            },

            disabled: {
                type: Boolean,
                default: false
            }
        },

        watch: {
            address: {
                handler (value) {
                    if (value.google_places_id) {
                        this.placeModel = {}
                        Object.assign(this.placeModel, value)
                        this.textAddress = value.address_line_1
                    } else {
                        this.placeModel = null
                        this.textAddress = null
                    }
                },
                deep: true
            },

            isEmpty: {
                handler (value) {
                    if (!value) this.placeModel = null
                }
            },

            textAddress: {
                handler (value) {
                    if (value === null) this.isEmpty = true
                    else if (value.replace(/\s/g, '') === '') this.isEmpty = true
                    else this.isEmpty = false
                }
            }
        },

        computed: {
            addressRules () {
                if (!this.clearable) {
                    if (this.textAddress === null) return [ 'Required field' ]
                    else if (this.textAddress.replace(/\s/g, '') === '') return [ 'Required field' ]
                    else if (this.placeModel === null) return [ 'Invalid Google address' ]
                    else if (this.googlePlaceAltered) return [ 'Invalid Google address' ]
                    else return []
                } else {
                    if (this.textAddress === null) return []
                    else if (this.googlePlaceAltered) return [ 'Invalid Google address' ]
                    else return []
                }
            }
        },

        data () {
            return {
                placeModel: null,
                isEmpty: false,
                textAddress: null,
                validAutocompleteForm: false,
                googlePlaceAltered: false
            }
        },

        methods: {
            setPlace (place) {
                if (place) this.placeModel = new PlaceModel(place)
                else this.placeModel = null

                this.googlePlaceAltered = false
                this.$emit('googlePlaceSelected', this.placeModel)

                this.$refs.autocompleteForm.resetValidation()
            },

            addressChanged () {
                this.$emit('addressChanged', this.textAddress, this.googlePlaceAltered)
            },

            clearAddress () {
                this.setPlace(null)
                this.textAddress = null
                this.googlePlaceAltered = false
            },

            preventFormSubmit (event) {
                if (event.keyCode === 13) {
                    event.preventDefault()
                } else this.googlePlaceAltered = true
            }
        }
    }
</script>
<style scoped>
    >>> div.v-input__append-inner {
        margin-top: 5px !important;
    }
</style>
