/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-29 12:59:09
 * @ Description: A controller that handles general profile category related operations.
 */

import BaseController from '../../../../base.controller.js'

/**
    * A class with helper methods to perform general profile category-related functionality.
    */
class GeneralProfileCategoryController extends BaseController {
    /**
    * Retrieve a profile's categories.
    * @param {Number} userID The ID of the user to retrieve.
    */
    async retrieve (userID) {
        try {
            const result = await this.$http.get(`${this.apiPath}/user/${userID}/profile/category`)
   
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
  
    /**
    * Upsert profile's category.
    * @param {Object} category The category object to upsert.
    */
    async upsert (category) {
        try {
            const result = await this.$http.put(`${this.apiPath}/user/profile/category`, category)
   
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
    * Delete profile's category.
    * @param {Object} category The category object to delete.
    */
    async delete (category) {
        try {
            const result = await this.$http.delete(`${this.apiPath}/user/profile/category`, category)
   
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
   
export default GeneralProfileCategoryController
   