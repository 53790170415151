/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-04-01 09:01:54
 * @ Description: A controller that handles professional service request related operations.
 */

import BaseController from '../../../base.controller.js'

/**
 * A class with helper methods to perform professional service request-related functionality.
 */
class WorkProfessionalServiceRequestController extends BaseController {
    /**
      * Retrieve a professional service request.
      * @param {Number} professionalServiceID The ID of the professional service to retrieve.
      * @param {Number} professionalServiceRequestID The ID of the professional service request to retrieve.
      */
    async retrieve (professionalServiceID, professionalServiceRequestID) {
        try {
            const result = await this.$http.get(`${this.apiPath}/work/service/${professionalServiceID}/request/${professionalServiceRequestID}`)
   
            var data = result.data.resultMessage
            return data
        } catch (error) {
            if (error.response.data.errorCode === 402) {
                throw (
                    {
                        errorCode: 402,
                        invoiceData: error.response.data.resultMessage
                    }
                )
            } else throw Error(error.response.data.resultMessage)
        }
    }
 
    /**
     * Upsert a professional service request.
     * @param {Number} professionalServiceID The ID of the professional service to request.
     * @param {Object} professionalServiceRequest The professional service request object to upsert.
     */
    async upsert (professionalServiceID, professionalServiceRequest) {
        try {
            const result = await this.$http.put(`${this.apiPath}/work/service/${professionalServiceID}/request`, professionalServiceRequest)
    
            var data = result.data.resultMessage
            return data
        } catch (error) {
            if (error.response.data.errorCode === 402) {
                throw (
                    {
                        errorCode: 402,
                        invoiceData: error.response.data.resultMessage
                    }
                )
            } else throw Error(error.response.data.resultMessage)
        }
    }

    /**
     * Reject a professional service request.
     * @param {Number} professionalServiceID The ID of the professional service.
     * @param {Number} professionalServiceRequestID The ID of the professional service request.
     */
    async reject (professionalServiceID, professionalServiceRequestID) {
        try {
            const result = await this.$http.put(`${this.apiPath}/work/service/${professionalServiceID}/request/${professionalServiceRequestID}/reject`)
    
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
     * Complete a professional service request.
     * @param {Number} professionalServiceID The ID of the professional service.
     * @param {Number} professionalServiceRequestID The ID of the professional service request.
     */
    async complete (professionalServiceID, professionalServiceRequestID) {
        try {
            const result = await this.$http.put(`${this.apiPath}/work/service/${professionalServiceID}/request/${professionalServiceRequestID}/complete`)
    
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
     * Review a professional service request.
     * @param {Number} professionalServiceID The ID of the professional service.
     * @param {Number} professionalServiceRequestID The ID of the professional service request.
     * @param {Object} ratingObject The ID containing the submitted rating and review.
     */
    async review (professionalServiceID, professionalServiceRequestID, ratingObject) {
        try {
            const result = await this.$http.put(`${this.apiPath}/work/service/${professionalServiceID}/request/${professionalServiceRequestID}/review`, ratingObject)
    
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
     * Upsert a professional service request.
     * @param {Number} professionalServiceID The ID of the professional service's request.
     * @param {Number} professionalServiceRequestID The ID of the professional service request's budget to upsert.
     * @param {FormData} professionalServiceRequestBudget The professional service request budget object to upsert.
     */
    async upsertBudget (professionalServiceID, professionalServiceRequestID, professionalServiceRequestBudget) {
        try {
            const result = await this.$http.put(`${this.apiPath}/work/service/${professionalServiceID}/request/${professionalServiceRequestID}/budget`, professionalServiceRequestBudget)
    
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
     * Reject a professional service request's proposed budget.
     * @param {Number} professionalServiceID The ID of the professional service's request.
     * @param {Number} professionalServiceRequestID The ID of the professional service request's budget to reject.
     */
    async rejectBudget (professionalServiceID, professionalServiceRequestID) {
        try {
            const result = await this.$http.put(`${this.apiPath}/work/service/${professionalServiceID}/request/${professionalServiceRequestID}/budget/reject`)
    
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
     * Accept a professional service request's proposed budget.
     * @param {Number} professionalServiceID The ID of the professional service's request.
     * @param {Number} professionalServiceRequestID The ID of the professional service request's budget to accept.
     */
    async acceptBudget (professionalServiceID, professionalServiceRequestID) {
        try {
            const result = await this.$http.put(`${this.apiPath}/work/service/${professionalServiceID}/request/${professionalServiceRequestID}/budget/accept`)
    
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
      * Reset an unread message count of a service request.
      * @param {Number} professionalServiceID The ID of the professional service's request.
      * @param {Number} professionalServiceRequestID The ID of the professional service request's message count to reset.
      */
    async resetMessageCount (professionalServiceID, professionalServiceRequestID) {
        try {
            const result = await this.$http.put(`${this.apiPath}/work/service/${professionalServiceID}/request/${professionalServiceRequestID}/chat/reset`)
       
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
    
export default WorkProfessionalServiceRequestController
    