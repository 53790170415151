/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2020-12-01 15:16:46
 * @ Description: A controller that handles job detail related operations.
 */

import BaseController from '../../../base.controller.js'

/**
   * A class with helper methods to perform job detail-related functionality.
   */
class WorkJobDetailController extends BaseController {
    /**
       * Retrieve a job's detail.
       * @param {Number} id The ID of the job to retrieve.
       */
    async retrieve (id) {
        try {
            const result = await this.$http.get(`${this.apiPath}/work/job/${id}/detail`)
  
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
 
    /**
       * Upsert a job's detail.
       * @param {Object} detail The job detail object to update.
       */
    async upsert (detail) {
        try {
            const result = await this.$http.put(`${this.apiPath}/work/job/detail`, detail)
  
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
  
export default WorkJobDetailController
  