<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-13 15:34:37
 * @ Description: Overlay to indicate we are waiting for an API response.
 -->

<template>
    <v-overlay
        v-model="$store.state.general.showLoadingOverlay"
        color="rgb(34, 43, 69)"
        style="z-index: 1000;"
    >
        <v-row>
            <v-col
                cols="12"
                class="text-center"
            >
                <v-progress-circular
                    indeterminate
                    size="64"
                >
                </v-progress-circular>
            </v-col>
        </v-row>
    </v-overlay>
</template>
<script>
    export default {
        name: 'OverlayHelperComponent'
    }
</script>
