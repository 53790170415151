/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2020-12-01 15:16:46
 * @ Description: A controller that handles job document related operations.
 */

import BaseController from '../../../base.controller.js'

/**
   * A class with helper methods to perform job document-related functionality.
   */
class WorkJobDocumentController extends BaseController {
    /**
       * Upsert a job's banner image.
       * @param {Number} jobID The job ID of the banner being upserted.
       * @param {FormData} form The form with the file to be uploaded to the server.
       */
    async upsertBanner (jobID, form) {
        try {
            const result = await this.$http.put(`${this.apiPath}/work/job/${jobID}/banner`, form)
  
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
     * Retrieve a job's documents.
     * @param {Number} jobID The job ID of the banner being upserted.
     */
    async retrieve (jobID) {
        try {
            const result = await this.$http.get(`${this.apiPath}/work/job/${jobID}/document`)
    
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
   
    /**
     * Upload job's document.
     * @param {Number} jobID The job ID of the banner being upserted.
     * @param {FormData} form The form with the file to be uploaded to the server.
     */
    async create (jobID, form) {
        try {
            const result = await this.$http.put(`${this.apiPath}/work/job/${jobID}/document`, form)
    
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
 
    /**
     * Delete job's document.
     * @param {Number} jobID The job ID of the banner being upserted.
     * @param {Object} document The document object to delete.
     */
    async delete (jobID, document) {
        try {
            const result = await this.$http.delete(`${this.apiPath}/work/job/${jobID}/document`, document)
    
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
     * Download job's document.
     * @param {Number} jobID The job ID of the banner being upserted.
     * @param {Object} documentURL The URL of the document to download.
     */
    async download (jobID, documentURL) {
        try {
            const result = await this.$http.get(`${this.apiPath}/work/job/${jobID}/document/${documentURL}`, null, 'blob')
    
            return result.data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
  
export default WorkJobDocumentController
  