/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-01-06 11:37:01
 * @ Description: A controller that handles job board related operations.
 */

import BaseController from '../../../base.controller.js'

/**
    * A class with helper methods to perform job board-related functionality.
    */
class WorkJobBoardController extends BaseController {
    /**
    * Retrieve job board.
    * @param {Number} pageNumber The page number to retrieve.
    * @param {Number} pageSize The page size to retrieve.
    * @param {Number} jobName The job name to retrieve.
    * @param {String} category The category of the jobs to retrieve eg. all, active, pending etc.
    * @param {Number} industryID The industry ID of the jobs to retrieve.
    * @param {Number} jobTitleID The job title ID of the jobs to retrieve.
    * @param {Number} typeID The type ID of the jobs to retrieve.
    * @param {Number} modeID The mode ID of the jobs to retrieve.
    * @param {Number} countryID The country ID of the jobs to retrieve.
    * @param {String} state The state of the jobs to retrieve.
    * @param {String} city The city of the jobs to retrieve.
    */
    async retrieve (pageNumber, pageSize, jobName, category, industryID, jobTitleID, typeID, modeID, countryID, state, city) {
        try {
            let searchString = ''
            let result

            if (pageNumber) searchString += `&pageNumber=${pageNumber}`
            if (pageSize) searchString += `&pageSize=${pageSize}`
            if (jobName && jobName !== '') searchString += `&jobName=${jobName}`
            if (industryID) searchString += `&industryID=${industryID}`
            if (jobTitleID) searchString += `&jobTitleID=${jobTitleID}`
            if (typeID) searchString += `&typeID=${typeID}`
            if (modeID) searchString += `&modeID=${modeID}`
            if (countryID) searchString += `&countryID=${countryID}`
            if (state) searchString += `&state=${state}`
            if (city) searchString += `&city=${city}`

            if (searchString !== '') result = await this.$http.get(`${this.apiPath}/work/job/board?category=${category}&${searchString}`)
            else result = await this.$http.get(`${this.apiPath}/work/job/board?category=${category}`)
   
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
   
export default WorkJobBoardController
   