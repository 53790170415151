/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2020-10-30 10:57:07
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-12-11 18:19:00
 * @ Description: The model used to parse an event object from the API to an object that the Calendar component understands.
 */

class EventModel {
    /**
     * Event constructor.
     * @param {Object} event The event object retrieved from the API.
     */
    constructor (event) {
        this.attendees = []
        this.bgColor = event.is_meeting ? '#FFA858' : '#2E567A' // this.getEventColour(event.type)
        this.body = event.description
        this.borderColor = event.is_meeting ? '#FFA858' : '#2E567A' // this.getEventColour(event.type)
        this.calendarId = event.is_meeting ? 1 : 2 // this.getEventType(event.type)
        this.category = event.is_meeting ? 'time' : 'allday' // this.getCategory(event.type)
        this.color = '#fff'
        this.comingDuration = 0
        this.customStyle = ''
        this.dragBgColor = event.is_meeting ? '#FFA858' : '#2E567A' // this.getEventColour(event.type)
        this.dueDateClass = ''
        this.end = event.is_meeting ? new Date(event.end_date/* .replace(/-/g, '/') */) : new Date(event.end_date.split('.')[0])
        this.goingDuration = 0
        this.id = event.id
        this.isAllday = event.is_meeting ? false : true
        this.isFocused = false
        this.isPending = false
        this.isPrivate = false
        this.isReadOnly = false
        this.isVisible = true
        this.location = event.location
        this.raw = {
            work_type_id: event.work_type_id,
            work_id: event.work_id
        } // this.getEventTypeName(event.type)
        this.recurrenceRule = ''
        this.start = event.is_meeting ? new Date(event.start_date/* .replace(/-/g, '/') */) : new Date(event.start_date.split('.')[0])
        this.state = 'Busy'
        this.title = event.summary
        this.user_id = event.user_id
        this.google_calendar_id = event.google_calendar_id
    }

    getEventType (eventTypeName) {
        if (!eventTypeName) eventTypeName = 'General'
        if (eventTypeName === 'General') return 1
        else if (eventTypeName === 'Job') return 2
        else if (eventTypeName === 'Project') return 3
        else if (eventTypeName === 'Tender') return 4
        else if (eventTypeName === 'Service') return 5
    }

    getEventColour (eventTypeName) {
        if (!eventTypeName) eventTypeName = 'General'
        if (eventTypeName === 'General') return '#FFA858'
        else if (eventTypeName === 'Job') return '#2E567A'
        else if (eventTypeName === 'Project') return '#0091FF'
        else if (eventTypeName === 'Tender') return '#44D7B6'
        else if (eventTypeName === 'Service') return '#57AA00'
    }

    getEventTypeName (eventTypeName) {
        if (!eventTypeName) return 'General'
        else return eventTypeName
    }

    getCategory (eventTypeName) {
        if (!eventTypeName) eventTypeName = 'General'
        if (eventTypeName === 'General') return 'time'
        else return 'allday'
    }

    getEventIsAllDay (eventTypeName) {
        if (!eventTypeName) eventTypeName = 'General'
        if (eventTypeName === 'General') return false
        else return true
    }
}

export default EventModel
