/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-12-08 13:26:24
 * @ Description: A controller that handles job milestone related operations.
 */

import BaseController from '../../../base.controller.js'

/**
    * A class with helper methods to perform job milestone-related functionality.
    */
class WorkJobMilestoneController extends BaseController {
    /**
    * Retrieve a job's milestones.
    * @param {Number} id The ID of the job's milestones to retrieve.
    */
    async retrieve (id) {
        try {
            const result = await this.$http.get(`${this.apiPath}/work/job/${id}/milestone`)
   
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
  
    /**
    * Upsert a job's milestone.
    * @param {Object} milestone The job milestone object to update.
    */
    async upsert (milestone) {
        try {
            const result = await this.$http.put(`${this.apiPath}/work/job/milestone`, milestone)
   
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
    * Delete a job's milestone.
    * @param {Object} milestone The job milestone object to delete.
    */
    async delete (milestone) {
        try {
            const result = await this.$http.delete(`${this.apiPath}/work/job/milestone`, milestone)
   
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
     * Pay a job's milestone.
     * @param {Object} milestone The job milestone object to pay.
     */
    async pay (milestone) {
        try {
            const result = await this.$http.put(`${this.apiPath}/work/job/milestone/pay`, milestone)
    
            var data = result.data.resultMessage
            return data
        } catch (error) {
            if (error.response.data.errorCode === 402) {
                throw (
                    {
                        errorCode: 402,
                        invoiceData: error.response.data.resultMessage
                    }
                )
            } else throw Error(error.response.data.resultMessage)
        }
    }
}
   
export default WorkJobMilestoneController
   