/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-12-12 08:38:16
 * @ Description: A controller that handles general signup related operations.
 */

import BaseController from '../../../base.controller.js'

/**
 * A class with helper methods to perform general signup-related functionality.
 */
class GeneralSignupController extends BaseController {
    /**
     * Verify a user's e-mail address before continuing with the signup process.
     * @param {String} email The user's e-mail address.
     * @param {String} facebookID The user's Facebook ID when attempting to sign up with Facebook.
     */
    async createSignupEmail (email, facebookID) {
        try {
            const result = await this.$http.post(`${this.apiPath}/signup/email/verification`, {
                email,
                metadata: {
                    facebook_id: facebookID
                }
            })

            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
     * Verify a token received in a user's e-mail address verification e-mail.
     * @param {String} token The token to verify.
     */
    async validateSignupEmail (token) {
        try {
            const result = await this.$http.get(`${this.apiPath}/signup/email/verification/${token}`)

            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}

export default GeneralSignupController
