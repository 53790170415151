/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-02-17 15:59:16
 * @ Description: A controller that handles professional service document related operations.
 */

import BaseController from '../../../base.controller.js'

/**
   * A class with helper methods to perform professional service document-related functionality.
   */
class WorkProfessionalServiceDocumentController extends BaseController {
    /**
     * Download professional service's document.
     * @param {Number} professionalServiceID The professional service ID of the banner being upserted.
     * @param {Object} documentURL The URL of the document to download.
     */
    async download (professionalServiceID, documentURL) {
        try {
            const result = await this.$http.get(`${this.apiPath}/work/service/${professionalServiceID}/document/${documentURL}`, null, 'blob')
    
            return result.data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
  
export default WorkProfessionalServiceDocumentController
  