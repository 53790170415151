/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-03-01 15:37:56
 * @ Description: A controller that handles professional service request document related operations.
 */

import BaseController from '../../../base.controller.js'

/**
* A class with helper methods to perform professional service request document-related functionality.
*/
class WorkProfessionalServiceRequestDocumentController extends BaseController {
    /**
      * Retrieve a professional service request's documents.
      * @param {Number} serviceID The professional service ID of the request.
      * @param {Number} requestID The request ID.
      */
    async retrieve (serviceID, requestID) {
        try {
            const result = await this.$http.get(`${this.apiPath}/work/service/${serviceID}/request/${requestID}/document`)
     
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
    
    /**
      * Upload professional service request's document/s.
      * @param {Number} serviceID The professional service ID of the request.
      * @param {Number} requestID The request ID.
      * @param {FormData} form The form with the files to be uploaded to the server.
      */
    async create (serviceID, requestID, form) {
        try {
            const result = await this.$http.put(`${this.apiPath}/work/service/${serviceID}/request/${requestID}/document`, form)
     
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
  
    /**
      * Delete professional service request's document.
      * @param {Number} serviceID The professional service ID of the request.
      * @param {Number} requestID The request ID.
      * @param {Object} document The document object to delete.
      */
    async delete (serviceID, requestID, document) {
        try {
            const result = await this.$http.delete(`${this.apiPath}/work/service/${serviceID}/request/${requestID}/document`, document)
     
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
     * Download professional service request's document.
     * @param {Number} serviceID The professional service ID of the request.
     * @param {Number} requestID The request ID.
     * @param {Object} documentURL The URL of the document to download.
     */
    async download (serviceID, requestID, documentURL) {
        try {
            const result = await this.$http.get(`${this.apiPath}/work/service/${serviceID}/request/${requestID}/document/${documentURL}`, null, 'blob')
    
            return result.data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
   
export default WorkProfessionalServiceRequestDocumentController
   