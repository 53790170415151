/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-15 10:07:35
 * @ Description: A controller that handles job application milestone related operations.
 */

import BaseController from '../../../../base.controller.js'

/**
 * A class with helper methods to perform job application milestone-related functionality.
 */
class WorkJobApplicationMilestoneController extends BaseController {
    /**
     * Retrieve a job application's milestones.
     * @param {Number} jobID The ID of the job's milestones to retrieve.
     * @param {Number} applicantID The ID of the applicant.
     */
    async retrieve (jobID, applicantID) {
        try {
            const result = await this.$http.get(`${this.apiPath}/work/job/${jobID}/application/${applicantID}/milestone`)
    
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
   
    /**
     * Upsert a job application's milestone.
     * @param {Object} milestone The job application milestone object to update.
     */
    async upsert (milestone) {
        try {
            const result = await this.$http.put(`${this.apiPath}/work/job/application/milestone`, milestone)
    
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
 
    /**
     * Delete a job application's milestone.
     * @param {Object} milestone The job application milestone object to delete.
     */
    async delete (milestone) {
        try {
            const result = await this.$http.delete(`${this.apiPath}/work/job/application/milestone`, milestone)
    
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
    
export default WorkJobApplicationMilestoneController
    