/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2020-12-01 15:16:46
 * @ Description: A controller that handles individual signup related operations.
 */

import BaseController from '../../../base.controller.js'

/**
  * A class with helper methods to perform individual signup-related functionality.
  */
class IndividualSignupController extends BaseController {
    /**
     * Create a new individual.
     * @param {Object} user The object with the user data.
     */
    async create (user) {
        try {
            const result = await this.$http.post(`${this.apiPath}/signup/individual`, user)

            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
    
    /**
     * Upload a user avatar to the server on signup.
     * @param {Number} userID The ID of the user uploading the file.
     * @param {FormData} form The form with the file to be uploaded to the server.
     */
    async uploadAvatar (userID, form) {
        try {
            const response = await this.$http.post(`${this.apiPath}/signup/individual/${userID}/avatar`, form)
            const responseData = response.data.resultMessage

            return responseData
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
 
export default IndividualSignupController
 