/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-29 13:01:27
 * @ Description: A controller that handles general review related operations.
 */

import BaseController from '../../../base.controller.js'

/**
  * A class with helper methods to perform general review-related functionality.
  */
class GeneralReviewController extends BaseController {
    /**
      * Retrieve a user's reviews.
      * @param {Number} userID The ID of the user to retrieve.
      */
    async retrieve (userID, sortingOption) {
        try {
            const result = await this.$http.get(`${this.apiPath}/user/${userID}/profile/review?sortBy=${sortingOption.id}`)
     
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
     
export default GeneralReviewController
     