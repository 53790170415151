/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2020-12-01 15:16:46
 * @ Description: A controller that handles general login related operations.
 */

import BaseController from '../../../base.controller.js'

/**
 * A class with helper methods to perform general login-related functionality.
 */
class GeneralLoginController extends BaseController {
    /**
     * Authenticate a user's login credentials.
     * @param {Object} credentials The user's login credentials.
     */
    async authenticate (credentials) {
        try {
            const result = await this.$http.post(`${this.apiPath}/login`, credentials)

            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
     * Generate a password reset token and send the link to the user via e-mail.
     * @param {String} email The user's e-mail address.
     */
    async createResetPasswordEmail (email) {
        try {
            const result = await this.$http.post(`${this.apiPath}/login/resetpassword`, {
                email
            })

            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
     * Reset the user's password.
     * @param {String} token The password reset token received via e-mail.
     * @param {String} password The user's new password.
     */
    async resetPassword (token, password) {
        try {
            const result = await this.$http.put(`${this.apiPath}/login/resetpassword/${token}`, {
                password
            })

            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}

export default GeneralLoginController
