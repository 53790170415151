var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"position":"relative"},attrs:{"id":"app"}},[(_vm.$route.path === '/' && _vm.platform === 'web')?_c('div',{staticStyle:{"position":"fixed","z-index":"500","left":"0","top":"calc(100vh - 60px)"}},[_vm._m(0)]):_vm._e(),_c('v-app',[_c('v-main',{staticStyle:{"overflow-y":"auto"},style:(_vm.$route.path.includes('dashboard') ||
                _vm.$route.path.includes('profile') ||
                _vm.$route.path.includes('job') ||
                _vm.$route.path.includes('service') ||
                _vm.$route.path.includes('company') ||
                _vm.$route.path.includes('individual') ||
                _vm.$route.path.includes('admin') ||
                _vm.$route.path.includes('calendar') ? 'background-color: #F9FBFD;' : '')},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('transition',{attrs:{"name":"fade"}},[_c('router-view',{staticClass:"pa-3"})],1),_c('landing-signup-overlay-component'),_c('landing-login-overlay-component'),_c('landing-verify-email-address-overlay-component'),_c('general-session-expired-overlay-component'),_c('general-trial-expired-overlay-component'),_c('general-snackbar-component'),_c('general-loading-overlay-component')],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"https://play.google.com/store/apps/details?id=coza.fixer.fixer"}},[_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":"/landing/GooglePlay.png","height":"60"}})])}]

export { render, staticRenderFns }