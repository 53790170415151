/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-17 17:56:32
 * @ Description: A controller that handles general auth related operations.
 */

import BaseController from '../../../base.controller.js'

/**
  * A class with helper methods to perform general login-related functionality.
  */
class GeneralAuthController extends BaseController {
    /**
      * Authenticate a user's token.
      */
    async authenticate () {
        try {
            const result = await this.$http.get(`${this.apiPath}/token/validate`)
 
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
 
export default GeneralAuthController
 