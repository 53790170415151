/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2020-12-01 15:16:46
 * @ Description: A controller that handles job delete related operations.
 */

import BaseController from '../../../base.controller.js'

/**
 * A class with helper methods to perform job delete-related functionality.
 */
class WorkJobDeleteController extends BaseController {
    /**
     * Delete a job.
     * @param {Object} job The job object to delete.
     */
    async delete (job) {
        try {
            const result = await this.$http.delete(`${this.apiPath}/work/job`, job)
     
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
     
export default WorkJobDeleteController
     