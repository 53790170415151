class PlaceModel {
    /**
     * Place constructor.
     * @param {Object} place The place object retrieved from the Google Places API.
     */
    constructor (place) {
        this.google_places_id = place.place_id
        this.address_line_1 = this.getAddressLine(place.name, place.address_components)
        this.city = this.getCity(place.address_components)
        this.state = this.getState(place.address_components)
        this.country = this.getCountry(place.address_components)
        this.lat = place.geometry.location.lat()
        this.lng = place.geometry.location.lng()
    }

    getAddressLine (name, place) {
        if (place && place.length > 0) {
            var suburb = ''

            for (let index = 0; index < place.length; index++) {
                const item = place[index]
                const types = item.types

                const typeSuburbIndex = types.findIndex(type => type === 'sublocality_level_1')

                if (typeSuburbIndex > -1) {
                    suburb = item.long_name
                }
            }

            if (suburb !== '') return `${name}, ${suburb}`
            else return name
        } else return null
    }

    getCity (place) {
        if (place && place.length > 0) {
            var city = null

            for (let index = 0; index < place.length; index++) {
                const item = place[index]
                const types = item.types
                const typeIndex = types.findIndex(type => type === 'locality')

                if (typeIndex > -1) {
                    city = item.long_name
                    break
                }
            }
            return city
        } else return null
    }

    getState (place) {
        if (place && place.length > 0) {
            var state = null

            // var geoCoder = new window.google.maps.Geocoder()
            // console.log(window.google.maps)
            // var geoCodedData = await geoCoder.geocode({'placeId': this.google_places_id})
            // console.log(geoCoder)

            for (let index = 0; index < place.length; index++) {
                const item = place[index]
                const types = item.types
                const typeIndex = types.findIndex(type => type === 'administrative_area_level_1')

                if (typeIndex > -1) {
                    state = item.long_name
                    break
                }
            }
            return state
        } else return null
    }

    getCountry (place) {
        if (place && place.length > 0) {
            var country = {
                id: null,
                name: null,
                country_code: null
            }

            for (let index = 0; index < place.length; index++) {
                const item = place[index]
                const types = item.types
                const typeIndex = types.findIndex(type => type === 'country')

                if (typeIndex > -1) {
                    country.name = item.long_name
                    country.country_code = item.short_name
                    break
                }
            }
            return country
        } else return null
    }
}

export default PlaceModel
