/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-05-17 13:26:58
 * @ Description: A controller that handles currency conversions based on a user's location.
 */

import BaseController from '../base.controller.js'

/**
  * A class with helper methods to perform currency conversions based on a user's location.
  */
class CurrencyController extends BaseController {
    /**
      * Convert currency.
      * @param {String} currencyToConvertFrom Currency to convert from.
      * @param {String} currencyToConvertTo Currency to convert to.
      * @param {Number} amount The amount to convert.
      */
    async convertCurrency (currencyToConvertFrom, currencyToConvertTo, amount) {
        try {
            const response = await this.$http.get(`${this.apiPath}/currency/conversion?from=${currencyToConvertFrom}&to=${currencyToConvertTo}&amount=${amount}`)
            const responseData = response.data.resultMessage

            return responseData
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
      * Get currency conversion rate.
      */
    async getCurrencyRate () {
        try {
            const response = await this.$http.get(`${this.apiPath}/currency/rate`)
            const responseData = response.data.resultMessage

            return responseData
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}

export default CurrencyController
