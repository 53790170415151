/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2020-11-03 12:21:11
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-12-09 14:14:16
 * @ Description: The Facebook Helper class communicates with Facebook's API to authenticate users logging in and signing up with
 *                Facebook credentials.
 */

class FacebookHelper {
    /**
     * Initialises the Facebook JS SDK.
     */
    init () {
        return new Promise(resolve => {
            window.fbAsyncInit = () => {
                FB.init({
                    appId: process.env.VUE_APP_FACEBOOK,
                    cookie: true,
                    version: 'v2.7'
                })
            };

            // Load facebook sdk script.
            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0]
                if (d.getElementById(id)) { return }
                js = d.createElement(s); js.id = id
                js.src = "https://connect.facebook.net/en_US/sdk.js"
                fjs.parentNode.insertBefore(js, fjs)
            } (document, 'script', 'facebook-jssdk'))

            resolve()
        })
    }

    /**
     * Checks if the user is already logged into Facebook.
     * Return true if logged in, else false.
     */
    retrieveLoginStatus() {
        return new Promise(resolve => {
            FB.getLoginStatus((response) => {
                // If the user is already logged in.
                if (response.status && response.status === 'connected') resolve(response.authResponse)
                // If the user is not logged in.
                else resolve(false)
            })
        })
    }

    /**
     * Retrieve the logged in Facebook user's details.
     */
    async retrieveUserData(userID, accessToken) {
        return new Promise(resolve => {
            FB.api('/me?fields=email', { fields: 'email' }, (response) => {
                resolve(response)
            })
        })

        // const axios = require('axios')
        // const response = await axios.get(`https://graph.facebook.com/${userID}?fields=email&access_token=${accessToken}`)
        // return response.data
    }
}
  
export default FacebookHelper
  