/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2020-02-10 11:59:22
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-05-31 16:42:31
 * @ Description: Vuex store that saves and updates certain states and that can be accessed throughout the entire application.
 */

import Vue from 'vue'
import Vuex from 'vuex'
import Vuetify from '../plugins/vuetify.plugin.js'
import createPersistedState from 'vuex-persistedstate'
import sharedMutations from 'vuex-shared-mutations'
import router from '@/router/index.js'

Vue.use(Vuex)

const general = {
    state: () => ({
        customBreakpoints: {
            xxs: false,
            xxl: false
        },
        showSnackbar: {
            show: false
        },
        showLoadingOverlay: null,
        currency: {
            code: 'USD',
            rate: 1,
            symbol: '$'
        },
        externalLink: null,
        androidNotificationTapped: false
    }),

    mutations: {
        setCustomBreakpoints (state) {
            var screenWidth = Vuetify.framework.breakpoint.width

            var customBreakpoints = {
                xxs: false,
                xxl: false
            }

            if (screenWidth <= 335) customBreakpoints.xxs = true
            else if (screenWidth > 2400) customBreakpoints.xxl = true

            state.customBreakpoints = customBreakpoints
        },

        setSnackbar (state, value) {
            state.showSnackbar = value
        },

        setLoadingOverlay (state, value) {
            state.showLoadingOverlay = value
        },

        setCurrency (state, value) {
            state.currency = value
        },

        setExternalLink (state, value) {
            state.externalLink = value
        },

        resetExternalLink (state) {
            state.externalLink = null
        },

        setAndroidNotificationTapped (state, value) {
            state.androidNotificationTapped = value
        }
    }
}

const overlay = {
    state: () => ({
        overlays: {
            landing: {
                login: false,
                signup: false,
                emailVerification: false
            },
            profile: {
                upgradeSubscription: false
            },
            job: {
                apply: false,
                scheduleInterview: false
            },
            general: {
                expiredSession: false,
                expiredTrial: false
            }
        }
    }),

    mutations: {
        setLoginOverlay (state, value) {
            if (value) {
                if (state.overlays.landing.signup) state.overlays.landing.signup = false
                if (state.overlays.landing.emailVerification) state.overlays.landing.emailVerification = false
            }

            state.overlays.landing.login = value
        },

        setSignupOverlay (state, value) {
            if (value) {
                if (state.overlays.landing.login) state.overlays.landing.login = false
                if (state.overlays.landing.emailVerification) state.overlays.landing.emailVerification = false
            }

            state.overlays.landing.signup = value
        },

        setEmailVerificationOverlay (state, value) {
            if (value) {
                if (state.overlays.landing.login) state.overlays.landing.login = false
                if (state.overlays.landing.signup) state.overlays.landing.signup = false
            }

            state.overlays.landing.emailVerification = value
        },

        setUpgradeSubscriptionOverlay (state, value) {
            state.overlays.profile.upgradeSubscription = value
        },

        setApplyOverlay (state, value) {
            state.overlays.job.apply = value
        },

        setScheduleInterviewOverlay (state, value) {
            state.overlays.job.scheduleInterview = value
        },

        setExpiredSessionOverlay (state, value) {
            state.overlays.general.expiredSession = value
        },

        setExpiredTrialOverlay (state, value) {
            state.overlays.general.expiredTrial = value
        }
    }
}

const signup = {
    state: () => ({
        signupData: {},
        newUser: false
    }),

    mutations: {
        setSignupData (state, value) {
            state.signupData = value
        },

        resetSignupData (state) {
            state.signupData = {}
        },

        setNewUser (state, value) {
            state.newUser = value
        }
    }
}

const user = {
    namespaced: true,
    state: () => ({
        userData: {},
        userProfileCompletionStats: {
            address: 0,
            detail: 0,
            document: 0,
            percentage: 0,
            social: 0
        },
        userContext: null,
        userExpiredSession: false,
        userExpiredTrial: false
    }),

    mutations: {
        setUserData (state, value) {
            state.userData = value
            if (value.company_id) state.userContext = 'company'
            else state.userContext = 'individual'

            state.userExpiredSession = false

            if (router.currentRoute.path === '/' || router.currentRoute.name === 'facebookRedirect' || router.currentRoute.name === 'signupPassword' || router.currentRoute.name === 'browseJobs' || router.currentRoute.name === 'browseTalent') {
                Vue.prototype.$http.token = value.token

                if (store.state.general.externalLink) router.push(store.state.general.externalLink).catch(() => true)
                else router.push('/dashboard').catch(() => true)

                store.commit('resetExternalLink')
            }
        },

        resetUserData (state) {
            state.userData = {}
            state.userContext = null
            state.expiredSession = false
            Vue.prototype.$http.token = null

            store.commit('resetTwilioChatToken')
            store.commit('resetTwilioInitialized')
            store.commit('resetSocketIOInitialized')

            router.replace('/')

            // if (router.currentRoute.path !== '/') router.push(`/`)

            // FB.logout()
        },

        setUserProfileCompletionStats (state, value) {
            const userState = this.state.user

            if (userState.userData.subscription_id && value.percentage === 100) {
                // User has been set in store and profile is completed
                if (!userState.userData.is_trial) {
                    // Trial has not been activated yet
                    if (userState.userData.user_role_id === 2 && userState.userData.subscription_id === 8) {
                        // Company
                        let company = userState.userData
                        // Object.assign(company, userState)
                        company.subscription_id = 5
                        company.subscription_name = 'Trial'
                        company.is_trial = 1
                        this.commit('user/setUserData', company)
                    } else if (userState.userData.user_role_id === 1 && userState.userData.subscription_id === 4) {
                        // Individual
                        let individual = userState.userData
                        // Object.assign(individual, userState)
                        individual.subscription_id = 1
                        individual.subscription_name = 'Trial'
                        individual.is_trial = 1
                        this.commit('user/setUserData', individual)
                    }
                }
            }
            state.userProfileCompletionStats = value
        },

        resetUserProfileCompletionStats (state) {
            state.userProfileCompletionStats = {
                address: 0,
                detail: 0,
                document: 0,
                percentage: 0,
                social: 0
            }
        },

        setUserExpiredSession (state, value) {
            state.userExpiredSession = value
        },

        setUserExpiredTrial (state, value) {
            state.userExpiredTrial = value
        }
    }
}

const twilio = {
    state: () => ({
        chatToken: null,
        initialized: false
    }),

    mutations: {
        setTwilioChatToken (state, value) {
            state.chatToken = value
        },

        resetTwilioChatToken (state) {
            state.chatToken = null
        },

        setTwilioInitialized (state, value) {
            state.initialized = value
        },

        resetTwilioInitialized (state) {
            state.initialized = false
        }
    }
}

const socketIO = {
    state: () => ({
        initialized: false
    }),

    mutations: {
        setSocketIOInitialized (state, value) {
            state.initialized = value
        },

        resetSocketIOInitialized (state) {
            state.initialized = false
        }
    }
}

const storeRouter = {
    state: () => ({
        currentRoute: null
    }),

    mutations: {
        setCurrentRoute (state, value) {
            state.currentRoute = value
        }
    }
}

const store = new Vuex.Store({
    modules: {
        general,
        overlay,
        signup,
        user,
        twilio,
        storeRouter,
        socketIO
    },

    plugins: [
        createPersistedState({
            key: 'vuex',
            paths: [ 'user.userData', 'user.userContext', 'user.userExpiredSession', 'user.userExpiredTrial', 'general.currency', 'signup.newUser', 'storeRouter.currentRoute' ]
        }),
        sharedMutations({
            predicate: [ 'user/resetUserData', 'user/setUserData' ]
        })
    ]
})

export default store
