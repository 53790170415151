/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-29 12:53:20
 * @ Description: A controller that handles company profile detail related operations.
 */

import BaseController from '../../../../base.controller.js'

/**
  * A class with helper methods to perform company profile detail-related functionality.
  */
class CompanyProfileDetailController extends BaseController {
    /**
      * Retrieve a company profile's detail.
      * @param {Number} companyID The ID of the company to retrieve.
      */
    async retrieve (companyID) {
        try {
            const result = await this.$http.get(`${this.apiPath}/company/${companyID}/profile/detail`)
 
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
      * Update a company profile's detail.
      * @param {Object} detail The company profile detail object to update.
      */
    async update (detail) {
        try {
            const result = await this.$http.put(`${this.apiPath}/company/profile/detail`, detail)
 
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
 
export default CompanyProfileDetailController
 