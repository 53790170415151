/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2020-12-01 15:16:46
 * @ Description: A controller that handles general profile stats related operations.
 */

import BaseController from '../../../../base.controller.js'

/**
      * A class with helper methods to perform general profile stats-related functionality.
      */
class GeneralProfileStatsController extends BaseController {
    /**
      * Retrieve a profile's completion stats.
      */
    async getCompletionStats () {
        try {
            const result = await this.$http.get(`${this.apiPath}/user/profile/stats/completion`)
     
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
     
export default GeneralProfileStatsController
     