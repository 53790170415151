/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-02-23 09:00:19
 * @ Description: A controller that handles professional service favourite related operations.
 */

import BaseController from '../../../base.controller.js'

/**
  * A class with helper methods to perform professional service favourite-related functionality.
  */
class WorkProfessionalServiceFavouriteController extends BaseController {
    /**
      * Change a professional service's favourite property.
      * @param {Object} professionalServiceID The ID of the professional service to update.
      */
    async toggleFavourite (professionalServiceID) {
        try {
            const result = await this.$http.put(`${this.apiPath}/work/service/${professionalServiceID}/favourite`)
      
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
      
export default WorkProfessionalServiceFavouriteController
      