/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-06-02 09:11:15
 * @ Description: A controller that handles general Firebase Cloud Messaging related operations.
 */

import BaseController from '../../../base.controller.js'

/**
 * A class with helper methods to perform general Firebase Cloud Messaging-related functionality.
 */
class GeneralFCMController extends BaseController {
    /**
     * Upsert a user's device token for push notifications.
     * @param {String} token The user's device token.
     * @param {String} provider The push notification provider, i.e. HMS or FCM.
     */
    async upsertDeviceToken (token, provider) {
        try {
            const result = await this.$http.post(`${this.apiPath}/user/fcm/device`, {
                token,
                provider
            })
  
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
  
export default GeneralFCMController
  