/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-29 18:04:41
 * @ Description: A controller that handles company profile social related operations.
 */

import BaseController from '../../../../base.controller.js'

/**
  * A class with helper methods to perform company profile social-related functionality.
  */
class CompanyProfileSocialController extends BaseController {
    /**
      * Retrieve a company profile's social.
      * @param companyID The ID of the company to retrieve.
      */
    async retrieve (companyID) {
        try {
            const result = await this.$http.get(`${this.apiPath}/company/${companyID}/profile/social`)
 
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
      * Update a company profile's social.
      * @param {Object} social The company profile social object to update.
      */
    async update (social) {
        try {
            const result = await this.$http.put(`${this.apiPath}/company/profile/social`, social)
 
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
 
export default CompanyProfileSocialController
 