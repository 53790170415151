/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-01-07 09:56:47
 * @ Description: A controller that handles preference lookup related operations.
 */

import BaseController from '../../base.controller.js'

/**
* A class with helper methods to perform preference lookup-related functionality.
*/
class LookupPreferenceController extends BaseController {
    /**
    * Retrieve all work locations.
    */
    async getWorkLocations () {
        try {
            const response = await this.$http.get(`${this.apiPath}/lookup/user/preference/worklocation`)
            const responseData = response.data.resultMessage
   
            return responseData
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
   
export default LookupPreferenceController
   