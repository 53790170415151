<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-12-01 09:04:27
 * @ Description: Component displaying the general profile location section in the details tab.
 -->

<template>
    <!-- Desktop -->
    <v-form
        v-if="$vuetify.breakpoint.mdAndUp"
        ref="locationForm"
        v-model="validLocationForm"
    >
        <v-row>
            <v-col
                cols="12"
                class="os-22-sb"
            >
                Location
            </v-col>
            <v-col
                cols="12"
                class="py-0"
            >
                <v-divider />
            </v-col>

            <!-- Google Autocomplete -->
            <v-col
                cols="6"
                class="pb-0 os-15-sb"
            >
                Address<span style="color: red;">*</span>
                <br />
                <general-google-autocomplete-address-component
                    @googlePlaceSelected="googlePlaceSelected"
                    @addressChanged="addressChanged"
                    :placeholder="'Type the address to start a Google Maps lookup'"
                    :address="address"
                />
            </v-col>
            <!-- Google Autocomplete -->

            <v-col
                cols="6"
                class="pb-0 os-15-sb"
            >
                Country
                <br />
                <v-text-field
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    readonly
                    v-model="address.country.name"
                />
            </v-col>
            <!-- <v-col
                cols="6"
                class="py-0 os-15-sb"
            >
                Timezone
                <br />
                <v-text-field
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    readonly
                />
            </v-col> -->
            <v-col
                cols="6"
                class="py-0 os-15-sb"
            >
                City
                <br />
                <v-text-field
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    readonly
                    v-model="address.city"
                />
            </v-col>

            <!-- Update Button -->
            <v-col
                cols="12"
                class="text-right pt-0 os-13-sb"
                align-self="center"
            >
                <v-btn
                    class="white--text no-uppercase rounded-lg os-13-sb"
                    color="#FFA858"
                    :disabled="!validLocationForm || address.google_places_id === null || googlePlaceAltered"
                    @click="updateProfileLocation"
                >
                    Update
                </v-btn>
            </v-col>
            <!-- Update Button -->

            <!-- Map -->
            <v-col
                cols="12"
                class="pt-0"
            >
                <general-map-component
                    :mapMarkers="mapMarkers"
                    :avatar="$store.state.user.userData.avatar"
                />
            </v-col>
            <!-- Map -->
        </v-row>
    </v-form>
    <!-- Desktop -->

    <!-- Mobile -->
    <v-form
        v-else-if="$vuetify.breakpoint.smAndDown"
        ref="locationForm"
        v-model="validLocationForm"
    >
        <v-row>
            <v-col
                cols="12"
                order="1"
                class="os-22-sb"
            >
                Location
            </v-col>
            <v-col
                cols="12"
                order="2"
                class="py-0"
            >
                <v-divider />
            </v-col>

            <!-- Google Autocomplete -->
            <v-col
                cols="12"
                sm="6"
                order="3"
                class="pb-0 os-15-sb"
            >
                Address<span style="color: red;">*</span>
                <br />
                <general-google-autocomplete-address-component
                    @googlePlaceSelected="googlePlaceSelected"
                    @addressChanged="addressChanged"
                    :placeholder="'Type the address to start a Google Maps lookup'"
                    :address="address"
                />
            </v-col>
            <!-- Google Autocomplete -->

            <v-col
                cols="12"
                sm="6"
                order="4"
                class="os-15-sb"
                :class="$vuetify.breakpoint.sm ? 'pb-0' : 'py-0'"
            >
                Country
                <br />
                <v-text-field
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    readonly
                    v-model="address.country.name"
                />
            </v-col>
            <v-col
                cols="12"
                sm="6"
                order="6"
                class="py-0 os-15-sb"
            >
                City
                <br />
                <v-text-field
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    readonly
                    v-model="address.city"
                />
            </v-col>

            <!-- Map -->
            <v-col
                cols="12"
                class="pt-0"
                order="8"
                order-sm="9"
            >
                <general-map-component
                    :mapMarkers="mapMarkers"
                    :avatar="$store.state.user.userData.avatar"
                />
            </v-col>
            <!-- Map -->

            <!-- Update Button -->
            <v-col
                cols="12"
                order="9"
                order-sm="8"
                class="text-right pt-0 os-13-sb"
                align-self="center"
            >
                <v-btn
                    class="white--text no-uppercase rounded-lg os-13-sb"
                    color="#FFA858"
                    :disabled="!validLocationForm || address.google_places_id === null || googlePlaceAltered"
                    @click="updateProfileLocation"
                >
                    Update
                </v-btn>
            </v-col>
            <!-- Update Button -->
        </v-row>
    </v-form>
    <!-- Mobile -->
</template>
<script>
    import GeneralMapComponent from '@/components/general/map/GeneralMapComponent'
    import GeneralGoogleAutocompleteAddressComponent from '@/components/general/google/GeneralGoogleAutocompleteAddressComponent.vue'
    import { LookupLocationController, GeneralProfileAddressController, GeneralProfileStatsController } from '@/logic/controller/index.controller.js'

    export default {
        name: 'ProfileDetailsLocationComponent',

        components: {
            GeneralMapComponent,
            GeneralGoogleAutocompleteAddressComponent
        },

        data () {
            return {
                mapMarkers: [],
                address: {
                    user_id: null,
                    address_line_1: null,
                    google_places_id: null,
                    lat: null,
                    lng: null,
                    state: null,
                    city: null,
                    country: {},
                    timezone: null
                },
                validLocationForm: false,
                lookupLocationController: null,
                generalProfileAddressController: null,
                generalProfileStatsController: null,
                countries: [],
                googlePlaceAltered: false
            }
        },

        beforeMount () {
            this.lookupLocationController = new LookupLocationController()
            this.generalProfileAddressController = new GeneralProfileAddressController()
            this.generalProfileStatsController = new GeneralProfileStatsController()
        },

        async mounted () {
            await this.getCountries()
            await this.getProfileLocation()

            this.$refs.locationForm.resetValidation()
        },

        methods: {
            async getCountries () {
                try {
                    this.countries = await this.lookupLocationController.getCountries()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getProfileLocation () {
                try {
                    this.address = await this.generalProfileAddressController.retrieve(this.$store.state.user.userData.id)

                    if (this.address.lat && this.address.lng) {
                        this.$nextTick(() => {
                            this.mapMarkers = [
                                {
                                    lat: Number(this.address.lat),
                                    lng: Number(this.address.lng)
                                }
                            ]
                        })
                    }
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getProfileCompletionStats () {
                try {
                    const stats = await this.generalProfileStatsController.getCompletionStats()
                    this.$store.commit('user/setUserProfileCompletionStats', stats)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async updateProfileLocation () {
                try {
                    this.address = await this.generalProfileAddressController.update(this.address)

                    this.$store.commit('setSnackbar', {
                        text: 'Updated successfully',
                        show: true,
                        color: '#069B34'
                    })
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }

                try {
                    await this.getProfileCompletionStats()
                } catch (error) {}
            },

            googlePlaceSelected (place) {
                const user_id = this.address.user_id

                this.googlePlaceAltered = false
                
                Object.assign(this.address, place)
                this.address.user_id = user_id

                const countryIndex = this.countries.findIndex(country => country.iso_2 === this.address.country.country_code)
                if (countryIndex > -1) this.address.country.id = this.countries[countryIndex].id

                this.mapMarkers = [
                    {
                        lat: place.lat,
                        lng: place.lng
                    }
                ]
            },

            addressChanged (address, googlePlaceAltered) {
                this.googlePlaceAltered = googlePlaceAltered
                this.address.address_line_1 = address
                this.autocompleteLocationValidate()
            },

            autocompleteLocationValidate () {
                if (this.address.address_line_1 === '' || (this.address.address_line_1.replace(/\s/g, '') === '')) {
                    this.address = {
                        user_id: this.address.user_id,
                        address_line_1: null,
                        google_places_id: null,
                        lat: null,
                        lng: null,
                        state: null,
                        city: null,
                        country: {},
                        timezone: null
                    }
                }
            }
        }
    }
</script>
