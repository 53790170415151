/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-01-07 09:43:50
 * @ Description: A controller that handles general profile address related operations.
 */

import BaseController from '../../../../base.controller.js'

/**
   * A class with helper methods to perform general profile address-related functionality.
   */
class GeneralProfileAddressController extends BaseController {
    /**
     * Retrieve a profile's address.
     * @param {Number} userID The ID of the user to retrieve.
     */
    async retrieve (userID) {
        try {
            const result = await this.$http.get(`${this.apiPath}/user/${userID}/profile/address`)
  
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
 
    /**
     * Update profile's address.
     * @param {Object} address The address object to update.
     */
    async update (address) {
        try {
            const result = await this.$http.put(`${this.apiPath}/user/profile/address`, address)
  
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
  
export default GeneralProfileAddressController
  