/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-05-17 11:00:30
 * @ Description: A controller that handles general profile card related operations.
 */

import BaseController from '../../../../base.controller.js'

/**
  * A class with helper methods to perform general profile card-related functionality.
  */
class GeneralProfileCardController extends BaseController {
    /**
      * Create an PayFast hosted session.
      */
    async createCardSession () {
        try {
            const result = await this.$http.get(`${this.apiPath}/user/profile/card/session`)
 
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
      * Create an PayFast payment URL.
      */
    async createPaymentURL () {
        try {
            const result = await this.$http.get(`${this.apiPath}/user/profile/card/url`)
 
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
 
    /**
      * Create a profile's card.
      * @param {Object} card The card object to create.
      */
    async create (card) {
        try {
            const result = await this.$http.put(`${this.apiPath}/user/profile/card`, card)
 
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
 
    /**
      * Get the card details associated with a token.
      */
    async retrieve () {
        try {
            const result = await this.$http.get(`${this.apiPath}/user/profile/card`)
 
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
      * Delete a card.
      * @param {Object} card The card object to delete.
      */
    async delete (card) {
        try {
            const result = await this.$http.delete(`${this.apiPath}/user/profile/card`, card)
 
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
      * Update a profile's card to primary.
      * @param {Object} card The card object to update.
      */
    async makePrimary (card) {
        try {
            const result = await this.$http.put(`${this.apiPath}/user/profile/card/primary`, card)
 
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
 
export default GeneralProfileCardController
 