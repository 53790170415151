<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-07-12 10:18:57
 * @ Description: Signup overlay.
 -->

<template>
    <!-- Desktop and Bigger Mobiles -->
    <v-dialog
        v-model="$store.state.overlay.overlays.landing.signup"
        overlay-color="rgb(34, 43, 69)"
        persistent
        scrollable
        v-if="$vuetify.breakpoint.smAndUp"
        style="overflow: hidden;"
        max-width="555px"
    >
        <v-card
            style="position: relative;"
            color="rgb(249, 251, 253)"
            max-height="705px"
        >
            <v-card-text
                style="max-height: 640px; color: black;"
                class="py-8"
            >
                <!-- Close Button -->
                <v-btn
                    icon
                    style="position: absolute; right: 16px;"
                    @click="hideSignupOverlay"
                    aria-label="close"
                >
                    <v-icon
                        color="black"
                        style="z-index: 10;"
                    >
                        {{ mdiCloseThick }}
                    </v-icon>
                </v-btn>
                <!-- Close Button -->

                <!-- Signup Form -->
                <v-row
                    justify="center"
                >
                    <v-col
                        cols="12"
                        style="max-width: 325px;"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-img
                                    src="/branding/FixerLogoBlue.png"
                                    width="135px"
                                    class="mx-auto"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                class="text-center os-29-sb"
                            >
                                Sign Up today!
                            </v-col>
                            <v-col
                                cols="12"
                                class="text-center os-14-r"
                            >
                                Continue with your <span v-if="!isWebView">Google or</span> Facebook account or
                                email address you use to sign in.
                            </v-col>

                            <!-- Google Signup -->
                            <v-col
                                cols="12"
                                style="cursor: pointer"
                                class="rounded-lg pb-5 os-15-sb"
                                @click="googleSignup"
                                v-if="!isWebView"
                            >
                                <v-row
                                    align="center"
                                    justify="center"
                                    style="background-color: white;"
                                >
                                    <v-col
                                        class="flex-grow-0 flex-shrink-1"
                                    >
                                        <v-img
                                            src="/landing/general/LandingGeneralGoogleLogo.png"
                                            width="28px"
                                            height="28px"
                                            contain
                                            class="mr-auto"
                                        />
                                    </v-col>
                                    <v-col
                                        class="flex-grow-1 flex-shrink-0 text-center"
                                    >
                                        Sign Up With Google
                                    </v-col>
                                </v-row>
                            </v-col>
                            <!-- Google Signup -->

                            <!-- Facebook Signup -->
                            <v-col
                                cols="12"
                                style="cursor: pointer"
                                class="rounded-lg pb-5 os-15-sb"
                                @click="logInWithFacebook"
                            >
                                <v-row
                                    align="center"
                                    justify="center"
                                    style="background-color: white;"
                                >
                                    <v-col
                                        class="flex-grow-0 flex-shrink-1"
                                    >
                                        <v-img
                                            src="/landing/general/LandingGeneralFacebookLogo.png"
                                            width="28px"
                                            height="28px"
                                            contain
                                            class="mr-auto"
                                        />
                                    </v-col>
                                    <v-col
                                        class="flex-grow-1 flex-shrink-0 text-center"
                                    >
                                        Sign Up With Facebook
                                    </v-col>
                                </v-row>
                            </v-col>
                            <!-- Facebook Signup -->

                            <!-- E-mail Signup -->
                            <v-col
                                cols="12"
                                style="cursor: pointer"
                                class="rounded-lg pb-5 os-15-sb"
                                @click="signup"
                            >
                                <v-row
                                    align="center"
                                    justify="center"
                                    style="background-color: white;"
                                >
                                    <v-col
                                        class="flex-grow-0 flex-shrink-1"
                                    >
                                        <v-img
                                            src="/branding/FixerIcon.ico"
                                            width="28px"
                                            height="28px"
                                            contain
                                            class="mr-auto"
                                        />
                                    </v-col>
                                    <v-col
                                        class="flex-grow-1 flex-shrink-0 text-center"
                                    >
                                        Sign Up With Email
                                    </v-col>
                                </v-row>
                            </v-col>
                            <!-- E-mail Signup -->

                            <!-- Terms Checkbox & Continue Button -->
                            <v-col
                                cols="12"
                                class="pt-0 pb-6 os-12-r"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                        class="text-center"
                                    >
                                        By joining I agree to receive 
                                        emails from Fixer.
                                    </v-col>
                                </v-row>
                            </v-col>
                            <!-- Terms Checkbox & Continue Button -->
                        </v-row>
                    </v-col>
                </v-row>
                <!-- Signup Form -->
            </v-card-text>

            <!-- Footer -->
            <v-card-actions
                style="display: flex; justify-content: center; align-items: center; height: 55px; background-color: rgb(253, 227, 203);"
                class="os-14-sb"
            >
                Already a member? &nbsp; <span @click="showLoginOverlay" style="text-decoration: underline; cursor: pointer;">Sign In</span>
            </v-card-actions>
            <!-- Footer -->

            <landing-email-required-overlay-component
                :showLandingEmailRequiredOverlayBoolean="showLandingEmailRequiredOverlayBoolean"
                @toggleLandingEmailRequiredOverlayComponent="toggleLandingEmailRequiredOverlayComponent"
                @cancelAuthentication="hideSignupOverlay"
            />
        </v-card>
    </v-dialog>
    <!-- Desktop and Bigger Mobiles -->

    <!-- Mobile -->
    <v-dialog
        v-model="$store.state.overlay.overlays.landing.signup"
        fullscreen
        scrollable
        v-else-if="$vuetify.breakpoint.xs"
        style="z-index: 10;"
    >
        <v-card
            style="position: relative;"
            color="rgb(249, 251, 253)"
        >
            <v-card-text
                style="height: 100vh; color: black;"
                class="py-8"
            >
                <!-- Close Button -->
                <v-btn
                    icon
                    style="position: absolute; right: 16px; top: 12px;"
                    @click="hideSignupOverlay"
                    aria-label="close"
                >
                    <v-icon
                        color="black"
                        style="z-index: 10;"
                    >
                        {{ mdiCloseThick }}
                    </v-icon>
                </v-btn>
                <!-- Close Button -->

                <!-- Signup Form -->
                <v-row
                    justify="center"
                >
                    <v-col
                        cols="12"
                        style="max-width: 300px;"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-img
                                    src="/branding/FixerLogoBlue.png"
                                    width="135px"
                                    class="mx-auto"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                class="text-center os-29-sb"
                            >
                                Sign Up today!
                            </v-col>
                            <v-col
                                cols="12"
                                class="text-center os-14-r"
                            >
                                Continue with your <span v-if="!isWebView">Google or</span> Facebook account or
                                email address you use to sign in.
                            </v-col>

                            <!-- Google Signup -->
                            <v-col
                                cols="12"
                                style="cursor: pointer"
                                class="rounded-lg pb-5 os-15-sb"
                                @click="googleSignup"
                                v-if="!isWebView"
                            >
                                <v-row
                                    align="center"
                                    justify="center"
                                    style="background-color: white;"
                                >
                                    <v-col
                                        class="flex-grow-0 flex-shrink-1"
                                    >
                                        <v-img
                                            src="/landing/general/LandingGeneralGoogleLogo.png"
                                            width="28px"
                                            height="28px"
                                            contain
                                            class="mr-auto"
                                        />
                                    </v-col>
                                    <v-col
                                        class="flex-grow-1 flex-shrink-0 text-center"
                                    >
                                        Sign Up With Google
                                    </v-col>
                                </v-row>
                            </v-col>
                            <!-- Google Signup -->

                            <!-- Facebook Signup -->
                            <v-col
                                cols="12"
                                style="cursor: pointer"
                                class="rounded-lg pb-5 os-15-sb"
                                @click="logInWithFacebook"
                            >
                                <v-row
                                    align="center"
                                    justify="center"
                                    style="background-color: white;"
                                >
                                    <v-col
                                        class="flex-grow-0 flex-shrink-1"
                                    >
                                        <v-img
                                            src="/landing/general/LandingGeneralFacebookLogo.png"
                                            width="28px"
                                            height="28px"
                                            contain
                                            class="mr-auto"
                                        />
                                    </v-col>
                                    <v-col
                                        class="flex-grow-1 flex-shrink-0 text-center"
                                    >
                                        Sign Up With Facebook
                                    </v-col>
                                </v-row>
                            </v-col>
                            <!-- Facebook Signup -->

                            <!-- E-mail Signup -->
                            <v-col
                                cols="12"
                                style="cursor: pointer"
                                class="rounded-lg pb-5 os-15-sb"
                                @click="signup"
                            >
                                <v-row
                                    align="center"
                                    justify="center"
                                    style="background-color: white;"
                                >
                                    <v-col
                                        class="flex-grow-0 flex-shrink-1"
                                    >
                                        <v-img
                                            src="/branding/FixerIcon.ico"
                                            width="28px"
                                            height="28px"
                                            contain
                                            class="mr-auto"
                                        />
                                    </v-col>
                                    <v-col
                                        class="flex-grow-1 flex-shrink-0 text-center"
                                    >
                                        Sign Up With Email
                                    </v-col>
                                </v-row>
                            </v-col>
                            <!-- E-mail Signup -->

                            <!-- Terms Checkbox & Continue Button -->
                            <v-col
                                cols="12"
                                class="pt-0 pb-6 os-12-r"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                        class="text-center"
                                    >
                                        By joining I agree to receive 
                                        emails from Fixer.
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col
                                cols="12"
                                class="text-center pt-0 os-13-sb"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#FFA858"
                                    @click="signup"
                                    :disabled="!validSignupForm || !email"
                                >
                                    Continue
                                </v-btn>
                            </v-col>
                            <!-- Terms Checkbox & Continue Button -->
                        </v-row>
                    </v-col>
                </v-row>
                <!-- Signup Form -->
            </v-card-text>

            <!-- Footer -->
            <v-card-actions
                style="display: flex; justify-content: center; align-items: center; height: 55px; background-color: rgb(253, 227, 203);"
                class="os-14-sb"
            >
                Already a member? &nbsp; <span @click="showLoginOverlay" style="text-decoration: underline; cursor: pointer;">Sign In</span>
            </v-card-actions>
            <!-- Footer -->

            <landing-email-required-overlay-component
                :showLandingEmailRequiredOverlayBoolean="showLandingEmailRequiredOverlayBoolean"
                @toggleLandingEmailRequiredOverlayComponent="toggleLandingEmailRequiredOverlayComponent"
                @cancelAuthentication="hideSignupOverlay"
            />
        </v-card>
    </v-dialog>
    <!-- Mobile -->
</template>

<script>
    import { mdiCloseThick } from '@mdi/js'
    import { GeneralSignupController, GeneralLoginController } from '@/logic/controller/index.controller.js'
    import FacebookHelper from '@/logic/helper/facebook/facebook.helper.js'
    import LandingEmailRequiredOverlayComponent from '@/overlays/landing/LandingEmailRequiredOverlayComponent.vue'
    import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'
    import { FacebookLogin } from '@capacitor-community/facebook-login'
    import { Capacitor } from '@capacitor/core'
    import { HMSAvailability } from '@hmscore/cordova-plugin-hms-availability/ionic-native/dist'

    export default {
        name: 'LandingSignupOverlayComponent',

        components: {
            LandingEmailRequiredOverlayComponent
        },

        computed: {
            emailRules () {
                return [
                    v => !!v || 'Required field',
                    v => v.replace(/\s/g, '') !== '' || 'Required field',
                    v => /\S+@\S+\.\S+/.test(v) || 'Invalid e-mail address'
                ]
            }
        },

        data () {
            return {
                mdiCloseThick,
                email: '',
                generalSignupController: null,
                validSignupForm: false,
                generalLoginController: null,
                facebookHelper: null,
                isWebView: false,
                showLandingEmailRequiredOverlayBoolean: false,
                facebookID: null
            }
        },

        async beforeMount () {
            this.generalSignupController = new GeneralSignupController()
            this.generalLoginController = new GeneralLoginController()
            this.facebookHelper = new FacebookHelper()
        },

        async mounted () {
            if (Capacitor.getPlatform() === 'web') await this.facebookHelper.init()
            else {
                await FacebookLogin.initialize({
                    appId: process.env.VUE_APP_FACEBOOK
                })
            }

            GoogleAuth.initialize({
                clientId: process.env.VUE_APP_GAUTH_KEY,
                scopes: [ 'profile' ]
            })

            const ua = window.navigator.userAgent || window.navigator.vendor || window.opera
            if ((ua.indexOf('FBAN') > -1) || (ua.indexOf('FBAV') > -1) || (ua.indexOf('Instagram') > -1)) this.isWebView = true
            else this.isWebView = false
        },

        methods: {
            hideSignupOverlay () {
                this.$store.commit('setSignupOverlay', false)
                if (this.$route.path !== '/' && !this.$route.path.includes('/browse')) this.$router.push('/')
            },

            showLoginOverlay () {
                this.$store.commit('setLoginOverlay', true)
            },

            async signup () {
                try {
                    const signupData = {
                        email: null,
                        password: null,
                        type: 'internal'
                    }

                    this.$store.commit('setSignupData', signupData)
                    this.$router.push('/signup')
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async googleSignup () {
                try {
                    if (Capacitor.getPlatform() === 'web') {
                        const response = await GoogleAuth.signIn()
                        await this.googleAuthenticate(response.authentication)
                    } else {
                        // const scopes = [
                        //     'email',
                        //     'profile'
                        // ]
                        // const scope = scopes.join(' ')
                        
                        // const googleURL = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?redirect_uri=https%3A%2F%2F${process.env.VUE_APP_GOOGLE_REDIRECT}&prompt=consent&response_type=token&client_id=459006018145-6f9tnas6eocd7rg7d9ch1i3a2i549jqt.apps.googleusercontent.com&scope=${scope}&flowName=GeneralOAuthFlow`
                        // window.open(googleURL)
                                    
                        HMSAvailability.isHuaweiMobileServicesAvailable()
                            .then(async (result) => {
                                if (result === 0) {
                                    const scopes = [
                                        'email',
                                        'profile'
                                    ]
                                    const scope = scopes.join(' ')
                        
                                    const googleURL = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?redirect_uri=https%3A%2F%2F${process.env.VUE_APP_GOOGLE_REDIRECT}&prompt=consent&response_type=token&client_id=459006018145-6f9tnas6eocd7rg7d9ch1i3a2i549jqt.apps.googleusercontent.com&scope=${scope}&flowName=GeneralOAuthFlow`
                                    window.open(googleURL)
                                } else {
                                    const response = await GoogleAuth.signIn()
                                    await this.googleAuthenticate(response.authentication)
                                }
                            })
                            .catch((error) => {
                                this.$store.commit('setSnackbar', {
                                    text: error,
                                    show: true
                                })
                            })
                    }
                } catch (error) {}
            },

            async googleAuthenticate (tokens) {
                try {
                    if (tokens) {
                        const loginCredentials = {
                            email: null,
                            password: null,
                            google_auth_code: tokens
                        }

                        const response = await this.generalLoginController.authenticate(loginCredentials)

                        if (response.google_id) {
                            const signupData = {
                                email: response.email,
                                password: response.google_id,
                                type: 'google'
                            }

                            this.$http.token = response.token
                            this.$store.commit('setSignupData', signupData)
                            this.$router.push('/signup')
                        } else this.setLoggedInUser(response)
                    }
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async setLoggedInUser (response) {
                this.$http.token = response.token

                // delete response.token
                await this.$store.commit('user/setUserData', response)
                await this.$store.commit('setNewUser', false)

                if (/* this.$route.path === '/' || */ this.$route.path.includes('/reset')) this.$router.push(`/dashboard`)
                else this.$store.commit('setLoginOverlay', false)
            },

            /**
             * Initiates a facebook login.
             * It first checks if the user is already logged in.
             */
            async logInWithFacebook() {
                try {
                    let userData
                    if (Capacitor.getPlatform() === 'web') {
                        const isLoggedIn = await this.facebookHelper.retrieveLoginStatus()

                        // If the user is not logged in, initiate the Facebook login process.
                        if (!isLoggedIn) window.location = encodeURI(`https://www.facebook.com/dialog/oauth?client_id=${process.env.VUE_APP_FACEBOOK}&auth_type=rerequest&scope=email&redirect_uri=${process.env.VUE_APP_FACEBOOK_REDIRECT_URI}&response_type=token`)
                        else {
                            userData = await this.facebookHelper.retrieveUserData(isLoggedIn.userID, isLoggedIn.accessToken)
                            await this.facebookAuthenticate(userData)
                        }
                    } else {
                        await FacebookLogin.login({ permissions: [ 'email' ] })
                        userData = await FacebookLogin.getProfile({
                            fields: [ 'email' ]
                        })
                        await this.facebookAuthenticate(userData)
                    }
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async facebookAuthenticate (userData) {
                try {
                    if (userData.email) {
                        const loginCredentials = {
                            email: userData.email,
                            password: null,
                            facebook_id: userData.id
                        }
                        const response = await this.generalLoginController.authenticate(loginCredentials)
                        if (response.facebook_id) {
                            const signupData = {
                                email: response.email,
                                facebook_id: response.facebook_id,
                                password: response.facebook_id,
                                type: 'facebook'
                            }

                            this.$http.token = response.token
                            this.$store.commit('setSignupData', signupData)
                            this.$router.push('/signup')
                        } else this.setLoggedInUser(response)
                    } else if (userData.id) {
                        const loginCredentials = {
                            email: null,
                            password: null,
                            facebook_id: userData.id
                        }
                        const response = await this.generalLoginController.authenticate(loginCredentials)
                        
                        if (response.facebook_id) {
                            const signupData = {
                                email: null,
                                facebook_id: response.facebook_id,
                                password: response.facebook_id,
                                type: 'facebook'
                            }

                            this.$http.token = response.token
                            this.$store.commit('setSignupData', signupData)
                            this.$router.push('/signup')
                        } else this.setLoggedInUser(response)
                    } else {
                        this.$store.commit('setSnackbar', {
                            text: 'Facebook authentication error',
                            show: true
                        })
                    }
                } catch (error) {
                    throw Error(error)
                }
            },

            async toggleLandingEmailRequiredOverlayComponent (value, isContinue, email) {
                this.showLandingEmailRequiredOverlayBoolean = value
                if (isContinue) {
                    try {
                        await this.generalSignupController.createSignupEmail(email, this.facebookID)

                        this.$store.commit('setEmailVerificationOverlay', true)

                        this.email = ''
                        this.facebookID = null
                    } catch (error) {
                        this.$store.commit('setSnackbar', {
                            text: error,
                            show: true
                        })
                    }
                }
            }
        }
    }
</script>
<style scoped>
    >>> .v-text-field input {
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
        font-size: 0.813rem !important;
        font-weight: 600 !important;
    }

    .button{
        color:white;
        min-width: 150px;
        background-color: #000000a1;
        height: 2.5rem;
        border-radius: 2rem;
        font-weight: 400;
        font-size: 0.8rem;
    }
</style>
