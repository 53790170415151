/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-29 13:04:39
 * @ Description: A controller that handles individual profile detail related operations.
 */

import BaseController from '../../../../base.controller.js'

/**
   * A class with helper methods to perform individual profile detail-related functionality.
   */
class IndividualProfileDetailController extends BaseController {
    /**
     * Retrieve a individual profile's detail.
     * @param {Number} userID The ID of the user to retrieve.
     */
    async retrieve (userID) {
        try {
            const result = await this.$http.get(`${this.apiPath}/individual/${userID}/profile/detail`)
  
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
 
    /**
     * Update a individual profile's detail.
     * @param {Object} detail The individual profile detail object to update.
     */
    async update (detail) {
        try {
            const result = await this.$http.put(`${this.apiPath}/individual/profile/detail`, detail)
  
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
  
export default IndividualProfileDetailController
  