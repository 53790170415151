<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-07-20 11:08:41
 * @ Description: Component displaying the individual profile details section in the details tab.
 -->

<template>
    <!-- Desktop -->
    <v-form
        v-if="$vuetify.breakpoint.mdAndUp"
        v-model="validIndividualProfileDetailForm"
        ref="individualProfileDetailForm"
    >
        <v-row
            align="center"
        >
            <!-- Heading -->
            <v-col
                class="flex-shrink-1 flex-grow-0 text-center os-13-r"
            >
                <input
                    type="file"
                    style="display: none"
                    ref="avatar"
                    accept="image/*"
                    @change="avatarSelected"
                >
                <v-avatar
                    size="100"
                    class="mb-2"
                >
                    <v-img
                        :src="avatar ? avatar : '/general/FixerIndividualIcon.png'"
                        contain
                    />
                </v-avatar>
                <span @click="selectAvatar" style="color: rgb(143, 155, 179); cursor: pointer;">Update Image</span>
            </v-col>
            <v-col
                class="flex-grow-1 flex-shrink-0"
            >
                <div
                    class="os-22-sb"
                >
                    {{ individualName }}
                </div>
                <div
                    class="os-13-r"
                    style="color: rgb(143, 155, 179);"
                >
                    Subscription: {{ $store.state.user.userData.subscription_name }}
                </div>
            </v-col>
            <!-- Heading -->

            <!-- Personal Details -->
            <v-col
                cols="12"
                class="os-22-sb"
            >
                Personal Details
            </v-col>
            <v-col
                cols="12"
                class="pa-0"
            >
                <v-divider />
            </v-col>
            <v-col
                cols="6"
                class="pb-0 os-15-sb"
            >
                First Name<span style="color: red;">*</span>
                <br />
                <v-text-field
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    v-model="individual.first_name"
                    :rules="nameRules"
                    counter="50"
                />
            </v-col>
            <v-col
                cols="6"
                class="pb-0 os-15-sb"
            >
                Last Name<span style="color: red;">*</span>
                <br />
                <v-text-field
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    v-model="individual.last_name"
                    :rules="nameRules"
                    counter="50"
                />
            </v-col>
            <v-col
                cols="6"
                class="py-0 os-15-sb"
            >
                Email<span style="color: red;">*</span>
                <br />
                <v-text-field
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    readonly
                    v-model="individual.email"
                    :rules="emailRules"
                />
            </v-col>
            <v-col
                cols="6"
                class="py-0 os-15-sb"
            >
                Contact Number
                <br />
                <vue-tel-input-vuetify
                    v-model="individual.contact_number_1"
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    @input="contactNumberInput"
                    :rules="contactNumberRules"
                    defaultCountry="za"
                />
            </v-col>
            <!-- Personal Details -->

            <!-- Reset Password/Update Buttons -->
            <v-col
                cols="12"
                class="text-right pt-0 os-13-sb"
            >
                <v-btn
                    class="white--text no-uppercase rounded-lg mr-4 os-13-sb"
                    color="#2E567A"
                    @click="resetPassword"
                >
                    Reset Password
                </v-btn>
                <v-btn
                    class="white--text no-uppercase rounded-lg os-13-sb"
                    color="#FFA858"
                    @click="updateIndividualProfileDetail"
                    :disabled="!validIndividualProfileDetailForm"
                >
                    Update
                </v-btn>
            </v-col>
            <!-- Reset Password/Update Buttons -->
        </v-row>
    </v-form>
    <!-- Desktop -->

    <!-- Mobile -->
    <v-form
        v-else-if="$vuetify.breakpoint.smAndDown"
        v-model="validIndividualProfileDetailForm"
        ref="individualProfileDetailForm"
    >
        <v-row
            align="center"
        >
            <!-- Heading -->
            <v-col
                :class="$vuetify.breakpoint.sm ? 'flex-shrink-1 flex-grow-0' : ''"
                class="text-center"
                :cols="$vuetify.breakpoint.sm ? '' : '12'"
            >
                <input
                    type="file"
                    style="display: none"
                    ref="avatar"
                    accept="image/*"
                    @change="avatarSelected"
                >
                <v-avatar
                    size="100"
                    class="mb-2"
                >
                    <v-img
                        :src="avatar ? avatar : '/general/FixerIndividualIcon.png'"
                        contain
                    />
                </v-avatar>
                <br />
                <span
                    style="color: rgb(143, 155, 179); cursor: pointer;"
                    class="os-13-r"
                    @click="selectAvatar"
                >
                    Update Image
                </span>
            </v-col>
            <v-col
                :class="$vuetify.breakpoint.sm ? 'flex-grow-1 flex-shrink-0' : 'text-center'"
                :cols="$vuetify.breakpoint.sm ? '' : '12'"
            >
                <div
                    class="os-22-sb"
                >
                    {{ individualName }}
                </div>
                <div
                    class="os-13-r"
                    style="color: rgb(143, 155, 179);"
                >
                    Subscription: {{ $store.state.user.userData.subscription_name }}
                </div>
            </v-col>
            <!-- Heading -->

            <!-- Personal Details -->
            <v-col
                cols="12"
                class="os-22-sb"
            >
                Personal Details
            </v-col>
            <v-col
                cols="12"
                class="pa-0"
            >
                <v-divider />
            </v-col>
            <v-col
                cols="12"
                sm="6"
                class="pb-0 os-15-sb"
            >
                First Name<span style="color: red;">*</span>
                <br />
                <v-text-field
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    v-model="individual.first_name"
                    :rules="nameRules"
                    counter="50"
                />
            </v-col>
            <v-col
                cols="12"
                sm="6"
                class="os-15-sb"
                :class="$vuetify.breakpoint.sm ? 'pb-0' : 'py-0'"
            >
                Last Name<span style="color: red;">*</span>
                <br />
                <v-text-field
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    v-model="individual.last_name"
                    :rules="nameRules"
                    counter="50"
                />
            </v-col>
            <v-col
                cols="12"
                sm="6"
                class="py-0 os-15-sb"
            >
                Email<span style="color: red;">*</span>
                <br />
                <v-text-field
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    readonly
                    v-model="individual.email"
                    :rules="emailRules"
                />
            </v-col>
            <v-col
                cols="12"
                sm="6"
                class="py-0 os-15-sb"
            >
                Contact Number
                <br />
                <vue-tel-input-vuetify
                    v-model="individual.contact_number_1"
                    dense
                    outlined
                    color="#2E567A"
                    class="pt-2 os-13-r"
                    background-color="white"
                    @input="contactNumberInput"
                    :rules="contactNumberRules"
                    defaultCountry="za"
                />
            </v-col>
            <!-- Personal Details -->

            <!-- Reset Password/Update Buttons -->
            <v-col
                cols="12"
                class="text-right pt-0 os-13-sb"
            >
                <v-btn
                    class="white--text no-uppercase rounded-lg mr-4 os-13-sb"
                    color="#2E567A"
                    @click="resetPassword"
                >
                    Reset Password
                </v-btn>
                <v-btn
                    class="white--text no-uppercase rounded-lg os-13-sb"
                    color="#FFA858"
                    @click="updateIndividualProfileDetail"
                    :disabled="!validIndividualProfileDetailForm"
                >
                    Update
                </v-btn>
            </v-col>
            <!-- Reset Password/Update Buttons -->
        </v-row>
    </v-form>
    <!-- Mobile -->
</template>
<script>
    import { IndividualProfileDetailController, GeneralProfileAvatarController, GeneralProfileController, GeneralProfileStatsController } from '@/logic/controller/index.controller.js'

    export default {
        name: 'ProfileIndividualDetailsComponent.vue',

        computed: {
            contactNumberRules () {
                // if (this.individual.contact_number_1 && this.validContactNumber) return []
                if (!this.individual.contact_number_1) return [ v => !!v || 'Number is required' ]
                else return [
                    v => /^\+[1-9]\d{8,15}$/.test(v.replace(/\s/g, '')) || 'Must be valid number'
                ]
            },

            nameRules () { 
                return [
                    v => !!v || 'Required field',
                    v => v.replace(/\s/g, '') !== '' || 'Required field',
                    v => v.length <= 50 || 'Max 50 characters'
                ]
            },

            emailRules () {
                return [
                    v => !!v || 'Required field',
                    v => v.replace(/\s/g, '') !== '' || 'Required field',
                    v => /\S+@\S+\.\S+/.test(v) || 'Invalid e-mail address'
                ]
            }
        },

        data () {
            return {
                individualProfileDetailController: null,
                generalProfileAvatarController: null,
                generalProfileController: null,
                generalProfileStatsController: null,
                individual: {
                    id: null,
                    first_name: '',
                    last_name: '',
                    email: '',
                    contact_number_1: '',
                    avatar: null,
                    subscription_name: '',
                    subscription_id: null
                },
                validContactNumber: false,
                individualName: null,
                avatar: null,
                validIndividualProfileDetailForm: false
            }
        },

        beforeMount () {
            this.individualProfileDetailController = new IndividualProfileDetailController()
            this.generalProfileAvatarController = new GeneralProfileAvatarController()
            this.generalProfileController = new GeneralProfileController()
            this.generalProfileStatsController = new GeneralProfileStatsController()
        },

        async mounted () {
            await this.getIndividualProfileDetail()
        },

        methods: {
            async getIndividualProfileDetail () {
                try {
                    this.individual = await this.individualProfileDetailController.retrieve(this.$store.state.user.userData.id)
                    this.individualName = this.individual.first_name + ' ' + this.individual.last_name
                    this.avatar = this.individual.avatar
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getProfileCompletionStats () {
                try {
                    const stats = await this.generalProfileStatsController.getCompletionStats()
                    this.$store.commit('user/setUserProfileCompletionStats', stats)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async updateIndividualProfileDetail () {
                try {
                    this.individual = await this.individualProfileDetailController.update(this.individual)
                    this.individualName = this.individual.first_name + ' ' + this.individual.last_name

                    this.$store.commit('setSnackbar', {
                        text: 'Updated successfully',
                        show: true,
                        color: '#069B34'
                    })

                    let userData = this.$store.state.user.userData
                    userData.first_name = this.individual.first_name
                    userData.last_name = this.individual.last_name
                    this.$store.commit('user/setUserData', userData)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }

                try {
                    await this.getProfileCompletionStats()
                } catch (error) {}
            },

            contactNumberInput (number, isValid, country) {
                if (isValid.number.international) this.individual.contact_number_1 = isValid.number.international
                else this.individual.contact_number_1 = isValid.number.input
            },

            selectAvatar () {
                this.$refs.avatar.click()
            },

            async avatarSelected (e) {
                const files = e.target.files

                if (files && files.length > 0) {
                    var reader = new FileReader()
                    var f = files[0]

                    var self = this

                    reader.onloadend = async function () {
                        self.avatar = reader.result
                        let avatarFormData = new FormData()
                        avatarFormData.append('image', files[0])

                        const uploadResponse = await self.generalProfileAvatarController.update(avatarFormData)
                        self.individual.avatar = uploadResponse.image_url
                        self.avatar = self.individual.avatar

                        self.$store.commit('setSnackbar', {
                            text: 'Updated successfully',
                            show: true,
                            color: '#069B34'
                        })

                        let userData = self.$store.state.user.userData
                        userData.avatar = self.avatar
                        self.$store.commit('user/setUserData', userData)

                        try {
                            await self.getProfileCompletionStats()
                        } catch (error) {}
                    }

                    reader.readAsDataURL(f)
                }
            },

            async resetPassword () {
                try {
                    await this.generalProfileController.resetPassword()

                    this.$store.commit('setSnackbar', {
                        text: 'Reset e-mail sent succesfully',
                        show: true,
                        color: '#069B34'
                    })
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            }
        }
    }
</script>
<style>
    .country-code {
        margin-right: 12px;
    }
</style>
<style scoped>
    >>> .v-input__slot.white {
        font-size: 13px !important;
    }
</style>