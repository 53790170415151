<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-30 16:27:28
 * @ Description: Component for displaying a custom marker on the GeneralMapCmponent.
 -->

<template>
    <div>
        <!-- Map Marker Image -->
        <v-img
            src="/dashboard/general/DashboardGeneralMapMarker.png"
            height="65"
            width="auto"
            contain
        />
        <!-- Map Marker Image -->

        <!-- Custom Image on Map Marker -->
        <v-avatar
            size="40"
            style="margin-top: -96px; margin-left: 19px;"
        >
            <v-img
                :src="avatar ? avatar : '/general/FixerIndividualIcon.png'"
                contain
            />
        </v-avatar>
        <!-- Custom Image on Map Marker -->
    </div>
</template>
<script>
    export default {
        name: 'GeneralMapCustomMarkerComponent',

        props: {
            avatar: {
                type: String
            }
        }
    }
</script>