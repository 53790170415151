/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2020-12-01 15:16:46
 * @ Description: A controller that handles job favourite related operations.
 */

import BaseController from '../../../base.controller.js'

/**
 * A class with helper methods to perform job favourite-related functionality.
 */
class WorkJobFavouriteController extends BaseController {
    /**
     * Change a job's favourite property.
     * @param {Object} job The job object to update.
     */
    async toggleFavourite (job) {
        try {
            const result = await this.$http.put(`${this.apiPath}/work/job/favourite`, job)
     
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
     
export default WorkJobFavouriteController
     