/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-16 09:32:40
 * @ Description: A controller that handles job application interview related operations.
 */

import BaseController from '../../../../base.controller.js'

/**
 * A class with helper methods to perform job application interview-related functionality.
 */
class WorkJobApplicationInterviewController extends BaseController {
    /**
     * Create a job application interview.
     * @param {Object} interview The job application interview object to create.
     */
    async create (interview) {
        try {
            const result = await this.$http.post(`${this.apiPath}/work/job/application/interview`, interview)
    
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
    
export default WorkJobApplicationInterviewController
    