/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-01-11 14:39:36
 * @ Description: A controller that handles job title lookup related operations.
 */

import BaseController from '../../base.controller.js'

/**
   * A class with helper methods to perform job title lookup-related functionality.
   */
class LookupJobTitleController extends BaseController {
    /**
     * Retrive all job titles.
     * @param {Number} pageNumber The page number to retrieve.
     * @param {Number} pageSize The page size to retrieve.
     * @param {Boolean} isProfService Retrieve job titles related to professional services.
     * @param {Boolean} isProject Retrieve job titles related to projects.
     * @param {Boolean} isJob Retrieve job titles related to jobs.
     * @param {Boolean} isTender Retrieve job titles related to tenders.
     * @param {Boolean} isProfileInd Retrieve job titles related to individual profiles.
     * @param {Boolean} isProfileComp Retrieve job titles related to company profiles.
     */
    async get (isProfService, isProject, isJob, isTender, isProfileInd, isProfileComp, pageNumber, pageSize) {
        try {
            let queryString = 'lookup/category/item'

            if (isProfService) queryString += '?isProfService=1'
            if (isProject) queryString += '?isProject=1'
            if (isJob) queryString += '?isJob=1'
            if (isTender) queryString += '?isTender=1'
            if (isProfileInd) queryString += '?isProfileInd=1'
            if (isProfileComp) queryString += '?isProfileComp=1'

            if (pageNumber && queryString === 'lookup/category/item') queryString += `?pageNumber=${pageNumber}`
            else if (pageNumber) queryString += `&pageNumber=${pageNumber}`
            if (pageSize) queryString += `&pageSize=${pageSize}`

            const response = await this.$http.get(`${this.apiPath}/${queryString}`)
            const responseData = response.data.resultMessage

            return responseData
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
     * Upsert a job title.
     * @param {Object} jobTitle The job title to upsert.
     */
    async upsert (jobTitle) {
        try {
            const response = await this.$http.put(`${this.apiPath}/lookup/category/item`, jobTitle)
            const responseData = response.data.resultMessage

            return responseData
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
  
export default LookupJobTitleController
  