<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-25 10:17:38
 * @ Description: Overlay to indicate a user's session has expired.
 -->

<template>
    <v-dialog
        v-model="$store.state.overlay.overlays.general.expiredSession"
        overlay-color="rgb(34, 43, 69)"
        persistent
        scrollable
        style="overflow: hidden;"
        max-width="400px"
    >
        <v-card
            style="position: relative;"
            color="background-color: rgba(255, 168, 88, 0.25);"
            max-height="705px"
        >
            <v-card-text
                style="max-height: 640px; color: black; background-color: rgba(255, 168, 88, 0.25);"
                class="py-8"
            >
                <v-row
                    justify="center"
                >
                    <v-col
                        cols="12"
                        style="max-width: 325px;"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-img
                                    src="/branding/FixerLogoBlue.png"
                                    width="135px"
                                    class="mx-auto"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                class="text-center os-22-sb"
                            >
                                Expired Session
                            </v-col>
                            <v-col
                                cols="12"
                                class="text-center os-14-r"
                            >
                                Your session has expired,
                                please log in again.
                            </v-col>
                            <v-col
                                cols="12"
                                class="text-center os-13-sb"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#FFA858"
                                    @click="hideExpiredSessionOverlay"
                                >
                                    Continue
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        name: 'GeneralSessionExpiredOverlayComponent',

        methods: {
            hideExpiredSessionOverlay () {
                this.$store.commit('setExpiredSessionOverlay', false)
                this.$store.commit('user/resetUserData')
            }
        }
    }
</script>