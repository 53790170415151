/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2020-12-08 15:00:37
 * @ Description: A controller that handles newsletter related operations.
 */

import BaseController from '../base.controller.js'

/**
 * A class with helper methods to perform newsletter-related functionality.
 */
class NewsletterController extends BaseController {
    /**
     * Subscribe a user to the Fixer newsletter.
     * @param {String} email The user's e-mail address.
     */
    async subscribe (email) {
        try {
            const result = await this.$http.post(`${this.apiPath}/newsletter`, {
                email
            })

            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}

export default NewsletterController
