<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-29 11:25:13
 * @ Description: Component displaying the general profile documents section in the details tab.
 -->

<template>
    <div>
        <!-- Desktop -->
        <v-row
            v-if="$vuetify.breakpoint.mdAndUp"
        >
            <v-col
                cols="12"
                class="os-22-sb"
            >
                Documents
            </v-col>
            <v-col
                cols="12"
                class="py-0"
            >
                <v-divider />
            </v-col>
            <v-col
                cols="12"
            >
                <general-document-component
                    :documents="profileDocuments"
                    @deleteDocument="confirmDeleteDocument"
                    @downloadDocument="downloadDocument"
                    @createDocument="createDocument"
                />
            </v-col>
        </v-row>
        <!-- Desktop -->

        <!-- Mobile -->
        <v-row
            v-else-if="$vuetify.breakpoint.smAndDown"
        >
            <v-col
                cols="12"
                class="os-22-sb"
            >
                Documents
            </v-col>
            <v-col
                cols="12"
                class="py-0"
            >
                <v-divider />
            </v-col>
            <v-col
                cols="12"
            >
                <general-document-component
                    :documents="profileDocuments"
                    @deleteDocument="confirmDeleteDocument"
                    @downloadDocument="downloadDocument"
                    @createDocument="createDocument"
                />
            </v-col>
        </v-row>
        <!-- Mobile -->

        <!-- Confirm Delete Overlay -->
        <general-confirm-delete-overlay-component
            :showDeleteConfirmationOverlayBoolean="showDeleteConfirmationOverlayBoolean"
            :itemToDeleteType="'Document'"
            @showDeleteConfirmationOverlay="showDeleteConfirmationOverlay"
        />
        <!-- Confirm Delete Overlay -->
    </div>
</template>
<script>
    import GeneralDocumentComponent from '@/components/general/document/GeneralDocumentComponent.vue'
    import { GeneralProfileDocumentController, GeneralProfileStatsController } from '@/logic/controller/index.controller.js'
    import GeneralConfirmDeleteOverlayComponent from '@/overlays/general/GeneralConfirmDeleteOverlayComponent.vue'

    export default {
        name: 'ProfileDetailsDocumentsComponent',

        components: {
            GeneralDocumentComponent,
            GeneralConfirmDeleteOverlayComponent
        },

        data () {
            return {
                generalProfileDocumentController: null,
                generalProfileStatsController: null,
                profileDocuments: [],
                showDeleteConfirmationOverlayBoolean: false,
                documentToDelete: null
            }
        },

        beforeMount () {
            this.generalProfileDocumentController = new GeneralProfileDocumentController()
            this.generalProfileStatsController = new GeneralProfileStatsController()
        },

        async mounted () {
            await this.getProfileDocuments()
        },

        methods: {
            async getProfileDocuments() {
                try {
                    this.profileDocuments = await this.generalProfileDocumentController.retrieve()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getProfileCompletionStats () {
                try {
                    const stats = await this.generalProfileStatsController.getCompletionStats()
                    this.$store.commit('user/setUserProfileCompletionStats', stats)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async createDocument(document) {
                try {
                    this.profileDocuments = await this.generalProfileDocumentController.create(document)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }

                try {
                    await this.getProfileCompletionStats()
                } catch (error) {}
            },

            async confirmDeleteDocument(document) {
                this.documentToDelete = document
                this.showDeleteConfirmationOverlay(true)
            },

            async deleteDocument(document) {
                try {
                    this.profileDocuments = await this.generalProfileDocumentController.delete([document])

                    this.$store.commit('setSnackbar', {
                        text: 'Deleted successfully',
                        show: true,
                        color: '#069B34'
                    })

                    this.documentToDelete = null
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }

                try {
                    await this.getProfileCompletionStats()
                } catch (error) {}
            },

            async downloadDocument(documentToDownload) {
                try {
                    const downloadResponse = await this.generalProfileDocumentController.download(documentToDownload)

                    var data = new Blob([downloadResponse])
                    if (typeof window.navigator.msSaveBlob === 'function') {
                        window.navigator.msSaveBlob(data, documentToDownload.filename)
                    } else {
                        var blob = data
                        var link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = documentToDownload.filename

                        document.body.appendChild(link)

                        link.click()
                    }

                    this.$store.commit('setSnackbar', {
                        text: 'Downloaded successfully',
                        show: true,
                        color: '#069B34'
                    })
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            showDeleteConfirmationOverlay (value, confirmDelete) {
                this.showDeleteConfirmationOverlayBoolean = value

                if (confirmDelete) this.deleteDocument(this.documentToDelete)
            }
        }
    }
</script>
