/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-02-28 09:57:25
 * @ Description: A controller that handles professional service board related operations.
 */

import BaseController from '../../../base.controller.js'

/**
     * A class with helper methods to perform professional service board-related functionality.
     */
class WorkProfessionalServiceBoardController extends BaseController {
    /**
     * Retrieve professional service board.
     * @param {Number} pageNumber The page number to retrieve.
     * @param {Number} pageSize The page size to retrieve.
     * @param {Number} professionalServiceName The professional service name to retrieve.
     * @param {String} category The category of the professional services to retrieve eg. all, active, pending etc.
     * @param {Number} industryID The industry ID of the professional services to retrieve.
     * @param {Number} jobTitleID The job title ID of the professional services to retrieve.
     * @param {Number} typeID The type ID of the professional services to retrieve.
     * @param {Number} serviceLocationID The location preference ID of the professional services to retrieve.
     * @param {Number} countryID The country ID of the professional services to retrieve.
     * @param {String} state The state of the professional services to retrieve.
     * @param {String} city The city of the professional services to retrieve.
     */
    async retrieve (ownership, pageNumber, pageSize, professionalServiceName, category, industryID, jobTitleID, typeID, serviceLocationID, countryID, state, city) {
        try {
            let searchString = ''
            let result
 
            if (ownership) searchString += `&ownership=${ownership}`
            if (pageNumber) searchString += `&pageNumber=${pageNumber}`
            if (pageSize) searchString += `&pageSize=${pageSize}`
            if (professionalServiceName && professionalServiceName !== '') searchString += `&jobName=${professionalServiceName}`
            if (industryID) searchString += `&industryID=${industryID}`
            if (jobTitleID) searchString += `&jobTitleID=${jobTitleID}`
            if (typeID) searchString += `&typeID=${typeID}`
            if (serviceLocationID) searchString += `&workLocationID=${serviceLocationID}`
            if (countryID) searchString += `&countryID=${countryID}`
            if (state) searchString += `&state=${state}`
            if (city) searchString += `&city=${city}`
 
            if (searchString !== '') result = await this.$http.get(`${this.apiPath}/work/service/board?category=${category}&${searchString}`)
            else result = await this.$http.get(`${this.apiPath}/work/service/board?category=${category}`)
    
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
    
export default WorkProfessionalServiceBoardController
    