<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2020-02-10 11:59:22
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-10-12 08:17:47
 * @ Description: The main entry point component of the application.
 -->

<template>
    <div
        id="app"
        style="position: relative;"
    >
        <div
            style="position: fixed; z-index: 500; left: 0; top: calc(100vh - 60px);"
            v-if="$route.path === '/' && platform === 'web'"
        >
            <a href="https://play.google.com/store/apps/details?id=coza.fixer.fixer">
                <img
                    src="/landing/GooglePlay.png"
                    height="60"
                    style="cursor: pointer;"
                />
            </a>
        </div>
                    
        <v-app>
            <!-- Primary Application -->
            <v-main 
                :style="$route.path.includes('dashboard') ||
                    $route.path.includes('profile') ||
                    $route.path.includes('job') ||
                    $route.path.includes('service') ||
                    $route.path.includes('company') ||
                    $route.path.includes('individual') ||
                    $route.path.includes('admin') ||
                    $route.path.includes('calendar') ? 'background-color: #F9FBFD;' : ''"
                style="overflow-y: auto;"
            >
                <v-container
                    class="pa-0"
                    fluid
                >
                    <!-- Main Router -->
                    <transition name="fade">
                        <router-view
                            class="pa-3"
                        />
                    </transition>
                    <!-- Main Router -->

                    <!-- Signup Overlay -->
                    <landing-signup-overlay-component />
                    <!-- Signup Overlay -->

                    <!-- Login Overlay -->
                    <landing-login-overlay-component />
                    <!-- Login Overlay -->

                    <!-- Verify E-mail Address Overlay -->
                    <landing-verify-email-address-overlay-component />
                    <!-- Verify E-mail Address Overlay -->

                    <!-- Expired Client Session Overlay -->
                    <general-session-expired-overlay-component />
                    <!-- Expired Client Session Overlay -->

                    <!-- Expired Trial Overlay -->
                    <general-trial-expired-overlay-component />
                    <!-- Expired Trial Overlay -->

                    <!-- Feedback Snackbar -->
                    <general-snackbar-component />
                    <!-- Feedback Snackbar -->

                    <!-- Loading Indicator Overlay -->
                    <general-loading-overlay-component />
                    <!-- Loading Indicator Overlay -->
                </v-container>
            </v-main>
            <!-- Primary Application -->
        </v-app>
    </div>
</template>

<script>
    import LandingSignupOverlayComponent from '@/overlays/landing/LandingSignupOverlayComponent.vue'
    import LandingLoginOverlayComponent from '@/overlays/landing/LandingLoginOverlayComponent.vue'
    import LandingVerifyEmailAddressOverlayComponent from '@/overlays/landing/LandingVerifyEmailAddressOverlayComponent.vue'
    import GeneralSnackbarComponent from '@/components/general/snackbar/GeneralSnackbarComponent.vue'
    import GeneralLoadingOverlayComponent from '@/overlays/general/GeneralLoadingOverlayComponent.vue'
    import GeneralSessionExpiredOverlayComponent from '@/overlays/general/GeneralSessionExpiredOverlayComponent.vue'
    import GeneralTrialExpiredOverlayComponent from '@/overlays/general/GeneralTrialExpiredOverlayComponent.vue'
    import { Capacitor } from '@capacitor/core'
    import { SplashScreen } from '@capacitor/splash-screen'
    import { App } from '@capacitor/app'

    export default {
        name: 'App',

        components: {
            LandingSignupOverlayComponent,
            LandingLoginOverlayComponent,
            LandingVerifyEmailAddressOverlayComponent,
            GeneralSnackbarComponent,
            GeneralLoadingOverlayComponent,
            GeneralSessionExpiredOverlayComponent,
            GeneralTrialExpiredOverlayComponent
        },

        computed: {
            platform () {
                return Capacitor.getPlatform()
            }
        },

        created () {
            window.addEventListener('resize', this.onResize)
            this.onResize()

            const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
        },

        updated () {
            if (this.$route.path === '/' || this.$route.path.includes('/dashboard') || this.$route.path.includes('/contactus')) window.$zoho.salesiq.floatbutton.visible("show")
            else window.$zoho.salesiq.floatbutton.visible("hide")
        },

        async mounted () {
            if (Capacitor.getPlatform() !== 'web') {
                document.addEventListener('deviceready', this.onDeviceReady, false)

                App.addListener('backButton', ({ canGoBack }) => {
                    if (!canGoBack) {
                        App.exitApp()
                    } else {
                        this.$router.back()
                    }
                })
            }
        },

        methods: {
            onDeviceReady () {
                setTimeout(()=>{
                    SplashScreen.hide({
                        fadeOutDuration: 500
                    })
                }, 1000)
            },

            onResize () {
                setTimeout(() => {
                    this.$store.commit('setCustomBreakpoints')

                    var marginSize

                    if (this.$vuetify.breakpoint.lgAndUp) {
                        marginSize = window.innerWidth - 1110;
                    } else if (this.$vuetify.breakpoint.md) marginSize = window.innerWidth - 920;
                    else marginSize = window.innerWidth - 1110;

                    marginSize = marginSize / 2

                    if (marginSize < 0) marginSize = 0

                    document.documentElement.style.setProperty('--marginSize', `${marginSize + 16}px`)
                }, 200)
            }
        }
    }
</script>
<style>
    .fade-enter {
        opacity: 0;
    }

    .fade-enter-active {
        transition: opacity 1s;
    }

    .fade-leave-active {
        transition: opacity 1s;
        opacity: 0;
    }

    div.v-input__slot.white {
        min-height: 0 !important;
        height: 34px !important;
    }

    @font-face {
        font-family: 'MontserratBlack';
        font-style: normal;
        font-weight: normal;
        src: url('./assets/fonts/montserrat/Montserrat-Black.ttf');
        font-display: swap;
    }

    @font-face {
        font-family: 'MontserratBold';
        font-style: normal;
        font-weight: normal;
        src: url('./assets/fonts/montserrat/Montserrat-Bold.ttf');
        font-display: swap;
    }

    @font-face {
        font-family: 'MontserratExtraBold';
        font-style: normal;
        font-weight: normal;
        src: url('./assets/fonts/montserrat/Montserrat-ExtraBold.ttf');
        font-display: swap;
    }

    @font-face {
        font-family: 'MontserratMedium';
        font-style: normal;
        font-weight: normal;
        src: url('./assets/fonts/montserrat/Montserrat-Medium.ttf');
        font-display: swap;
    }

    @font-face {
        font-family: 'MontserratRegular';
        font-style: normal;
        font-weight: normal;
        src: url('./assets/fonts/montserrat/Montserrat-Regular.ttf');
        font-display: swap;
    }

    @font-face {
        font-family: 'MontserratSemiBold';
        font-style: normal;
        font-weight: normal;
        src: url('./assets/fonts/montserrat/Montserrat-SemiBold.ttf');
        font-display: swap;
    }
</style>
