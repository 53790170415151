import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify.plugin.js'
import router from './router'
import store from './store'
import * as VueGoogleMaps from 'gmap-vue'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import 'vuetify/dist/vuetify.min.css'
import AxiosHelper from '@/logic//helper/axios/axios.helper.js'
import IdleVue from 'idle-vue'
import TwilioHelper from '@/logic/helper/twilio/twilio.helper.js'
import io from 'socket.io-client'
import VueHtmlToPaper from 'vue-html-to-paper'
import { PushNotifications } from '@capacitor/push-notifications'
import { Capacitor } from '@capacitor/core'
import { GeneralNotificationController, GeneralFCMController } from '@/logic/controller/index.controller.js'
import { HmsPush, HmsPushEvent } from '@hmscore/cordova-plugin-hms-push/ionic-native/dist'
import { HMSAvailability } from '@hmscore/cordova-plugin-hms-availability/ionic-native/dist'

if (Capacitor.getPlatform() === 'web') {
    // var firebaseMessaging = require('./firebase.js').default
}

require('typeface-roboto')

Vue.config.productionTip = false

Vue.use(TiptapVuetifyPlugin, {
    vuetify,
    iconsGroup: 'mdiSvg'
})

Vue.use(VueTelInputVuetify, {
    vuetify,
    placeholder: '',
    label: ''
})

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
        libraries: 'places'
    }
})

const eventsHub = new Vue()

Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    idleTime: 60 * 60 * 2 * 1000 // 2 hours
})

Vue.prototype.$http = new AxiosHelper()

// if (Capacitor.getPlatform() === 'web') Vue.prototype.$messaging = firebaseMessaging

const printOptions = {
    name: '_blank',
    specs: [
        'fullscreen=yes',
        'titlebar=yes',
        'scrollbars=yes'
    ],
    styles: [
        '../node_modules/vuetify/dist/vuetify.css',
        './assets/style/style.fonts.css'
    ]
}

Vue.use(VueHtmlToPaper, printOptions)

new Vue({
    vuetify,
    router,
    store,
    watch: {
        '$store.state.user.userData.email': {
            async handler (value) {
                if (value) {
                    this.deviceToken = null

                    this.initSocketIO()
                    await this.initPushNotifications()
                    await this.initTwilio()
                }
            },
            immediate: true,
            deep: true
        }
    },

    data () {
        return {
            deviceToken: null,
            generalNotificationController: null,
            generalFCMController: null,
            hmsInitDone: false
        }
    },

    onIdle () {
        if (this.$route.meta.requiresToken && !store.state.overlay.overlays.landing.login) {
            store.commit('setExpiredSessionOverlay', true)
            store.commit('user/setUserExpiredSession', true)
        }
    },

    async created () {
        this.$http.store = store
        this.$http.router = router

        // Check if a user token exists in the store
        if (this.$store.state.user.userData.token) {
            // Set the global AxiosHelper's token to the user token in the store
            this.$http.token = this.$store.state.user.userData.token
            // Check if a user session has expired and display the message if so
            if (this.$store.state.user.userExpiredSession /* && this.$router.currentRoute.path !== '/' */) store.commit('setExpiredSessionOverlay', true)
            // Reset the user's store data and navigate to the landing page if a token doesn't exist
        } else if (this.$route.fullPath !== '/') store.commit('user/resetUserData')
    },

    beforeMount () {
        this.generalNotificationController = new GeneralNotificationController()
        this.generalFCMController = new GeneralFCMController()
    },

    methods: {
        async initTwilio () {
            try {
                this.$store.commit('setTwilioInitialized', false)
                Vue.prototype.$twilio = new TwilioHelper(this.$store.state.user.userData.email)
                await this.twilioGenerateChatClient()
                this.$store.commit('setTwilioInitialized', true)
            } catch (error) {
                this.$store.commit('setSnackbar', {
                    text: error,
                    show: true
                })
            }
        },

        async twilioGenerateChatClient () {
            try {
                if (!this.$store.state.twilio.chatToken) {
                    const chatToken = await Vue.prototype.$twilio.generateChatToken()
                    this.$store.commit('setTwilioChatToken', chatToken)
                }
                await Vue.prototype.$twilio.createChatClient()
            } catch (error) {
                this.$store.commit('setSnackbar', {
                    text: error,
                    show: true
                })
            }
        },

        initSocketIO () {
            this.$store.commit('setSocketIOInitialized', false)

            let socketIO = null

            if (process.env.NODE_ENV === 'development') {
                // We try to connect to the Socket IO server
                socketIO = io.connect(process.env.VUE_APP_SOCKETIO, {
                    // We send user details to the socket server
                    auth: {
                        id: this.$store.state.user.userData.id
                    }
                })
            } else {
                // socketIO = io()
                // We try to connect to the Socket IO server
                socketIO = io.connect(process.env.VUE_APP_SOCKETIO, {
                    // We send user details to the socket server
                    auth: {
                        id: this.$store.state.user.userData.id
                    }
                })
            }

            Vue.prototype.$socketIO = socketIO

            this.$store.commit('setSocketIOInitialized', true)
        },

        async initPushNotifications () {
            try {
                if (Capacitor.getPlatform() === 'web') {
                    // if (Notification.permission !== 'granted') await this.$messaging.requestPermission()
                    // this.deviceToken = await this.$messaging.getToken({ vapidKey: 'BJQjUsjG-bMrBMDwEm9bED1l1AO7DrBcTM5mI0AqbMXh_6ynEACd7UMuoZI754g2n6pt6UE9dZG7m83IatrgRrI' })

                    // try {
                    //     this.generalFCMController.upsertDeviceToken(this.deviceToken, 'FCM')
                    // } catch (error) {
                    //     this.$store.commit('setSnackbar', {
                    //         text: 'Firebase Token Registration Error',
                    //         show: true
                    //     })
                    // }

                    // this.$messaging.onMessage((payload) => {
                    //     // console.log('Message received. ', payload)
                    // })
                } else {
                    try {
                        const self = this

                        HMSAvailability.isHuaweiMobileServicesAvailable()
                            .then(async (result) => {
                                if (result === 0) {
                                    if (self.hmsInitDone) {
                                        HmsPush.getToken()
                                            .then(async (result) => {
                                                await this.generalFCMController.upsertDeviceToken(result, 'HMS')
                                            })
                                            .catch((error) => {
                                                this.$store.commit('setSnackbar', {
                                                    text: 'HMS Core Token Error',
                                                    show: true
                                                })
                                            })
                                    } else {
                                        HmsPush.init()
                                            .catch((error) => {
                                                this.$store.commit('setSnackbar', {
                                                    text: 'HMS Core Init Error',
                                                    show: true
                                                })

                                                self.hmsInitDone = true

                                                return
                                            })

                                        // const pushNotificationsOn = await HmsPush.turnOnPush()
                                        // if (!pushNotificationsOn) return

                                        HmsPushEvent.onTokenReceived(async (token) => {
                                            // await self.generalFCMController.upsertDeviceToken(token, 'HMS')
                                            this.$store.commit('setSnackbar', {
                                                text: 'HMS Core Token Received',
                                                show: true,
                                                timeout: 50000,
                                            })
                                        })

                                        HmsPushEvent.onTokenError((error) => {
                                            this.$store.commit('setSnackbar', {
                                                text: 'HMS Core Token Error',
                                                show: true,
                                                timeout: 50000
                                            })
                                        })

                                        HmsPushEvent.onNotificationOpenedApp((result) => {
                                            const notification = JSON.parse(result.extras.notification)
                                            self.notificationAction(notification)
                                        })

                                        HmsPush.getToken()
                                            .then(async (result) => {
                                                await this.generalFCMController.upsertDeviceToken(result, 'HMS')
                                            })
                                            .catch((error) => {
                                                this.$store.commit('setSnackbar', {
                                                    text: 'HMS Core Token Error',
                                                    show: true
                                                })
                                            })
                                    }
                                } else {
                                    const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications')
                                    if (!isPushNotificationsAvailable) {
                                        this.$store.commit('setSnackbar', {
                                            text: 'Push Notifications Unavailable',
                                            show: true
                                        })

                                        return
                                    }

                                    PushNotifications.addListener('registration', async (token) => {
                                        try {
                                            if (!this.deviceToken) {
                                                this.deviceToken = token.value
                                                await this.generalFCMController.upsertDeviceToken(this.deviceToken, 'FCM')
                                            }
                                        } catch (error) {}
                                    })

                                    PushNotifications.addListener('registrationError', (error) => {
                                        this.$store.commit('setSnackbar', {
                                            text: 'Firebase Token Registration Error',
                                            show: true
                                        })
                                    })

                                    PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
                                        notification = notification.notification.data
                                        self.notificationAction(notification)
                                    })

                                    await PushNotifications.requestPermissions()
                                    await PushNotifications.register()
                                }
                            })
                            .catch((error) => {
                                this.$store.commit('setSnackbar', {
                                    text: error,
                                    show: true
                                })
                            })
                    } catch (error) {
                        this.$store.commit('setSnackbar', {
                            text: error,
                            show: true
                        })
                    }
                }
            } catch (error) {
                this.$store.commit('setSnackbar', {
                    text: error,
                    show: true
                })
            }
        },

        async notificationAction (notification) {
            this.$store.commit('setAndroidNotificationTapped', true)
            // notification = notification.notification.data

            await this.markNotificationsAsRead([notification])

            if (notification.notification_context_id) {
                if (Number(notification.notification_context_id) === 1) {
                // Activity
                    if (Number(notification.notification_type_id) === 3) {
                    // Job
                        switch (Number(notification.notification_event_id)) {
                        case 1:
                        // New post
                            if (this.$store.state.user.userContext === 'company') {/* console.log('New post company') */}
                            else if (this.$route.path !== `job/${notification.work_id}/view`) this.$router.push(`/job/${notification.work_id}/view`)
                            break
                        case 2:
                        // Applicant applied
                            if (Number(notification.work_status_id) === 2) {
                                if (this.$store.state.user.userContext === 'company') if (this.$route.path !== `/job/${notification.work_id}/applicant/${notification.sender_id}`) this.$router.push(`/job/${notification.work_id}/applicant/${notification.sender_id}`)
                                else {/* console.log('Applicant applied individual') */}
                            } else {
                                this.$store.commit('setSnackbar', {
                                    text: 'This job is not in a recruiting status',
                                    show: true,
                                    color: '#069B34'
                                })
                            }
                            break
                        case 3:
                        // Applicant declined
                            if (this.$store.state.user.userContext === 'company') {/* console.log('Applicant declined company') */}
                            else if (this.$route.path !== '/job/search') this.$router.push('/job/search')
                            break
                        case 4:
                        // Applicant accepted
                            if (this.$store.state.user.userContext === 'company') {/* console.log('Applicant accepted company') */}
                            else if (this.$route.path !== `/job/${notification.work_id}/overview/${notification.work_applicant_id}`) this.$router.push(`/job/${notification.work_id}/overview/${notification.work_applicant_id}`)
                            break
                        case 5:
                        // Updated
                            if (Number(notification.work_status_id) === 20 || Number(notification.work_status_id) === 21 || Number(notification.work_status_id) === 7) {
                            // Reviewed or Completed
                                if (this.$route.path !== '/profile/reviews') this.$router.push('/profile/reviews')
                            } else if (Number(notification.work_stage_id) === 2 || Number(notification.work_status_id) === 19) {
                            // Pending or Cancelled During Recruitment
                                if (this.$route.path !== `/job/${notification.work_id}/view`) this.$router.push(`/job/${notification.work_id}/view`)
                            } else if (Number(notification.work_stage_id) === 3 || Number(notification.work_status_id) === 6) {
                            // Active or Cancelled
                                if (this.$route.path !== `/job/${notification.work_id}/overview/${notification.work_applicant_id}`) this.$router.push(`/job/${notification.work_id}/overview/${notification.work_applicant_id}`)
                            }
                            break
                        case 6:
                        // Interview
                            if (this.$store.state.user.userContext === 'company') {/* console.log('Interview company') */}
                            else {
                                if (this.$route.path !== 'calendar') this.$router.push({ path: '/calendar', query: { event_id: notification.notification_calendar_event_id }}).catch(() => true)
                                else this.$router.replace({ query: { event_id: notification.notification_calendar_event_id }}).catch(() => true)
                            }
                            break
                        default:
                            break
                        }
                    } else if (Number(notification.notification_type_id) === 2) {
                    // Professional Service
                        switch (Number(notification.notification_event_id)) {
                        case 1:
                        // // New post
                        // if (this.$store.state.user.userContext === 'company') {/* console.log('New post company') */}
                        // else if (this.$route.path !== `job/${notification.work_id}/view`) this.$router.push(`/job/${notification.work_id}/view`)
                            break
                        case 2:
                        // Service requested
                            if (Number(notification.work_stage_id) === 2) {
                                if (this.$route.path !== `/service/${notification.service_id}/requested/${notification.work_id}`) this.$router.push(`/service/${notification.service_id}/requested/${notification.work_id}`)
                            } else {
                                this.$store.commit('setSnackbar', {
                                    text: 'This professional service is not in a pending status',
                                    show: true,
                                    color: '#069B34'
                                })
                            }
                            break
                        case 3:
                        // // Applicant declined
                            if (this.$route.path !== `/service/${notification.work_id}/requested/${notification.service_id}`) this.$router.push(`/service/${notification.service_id}/requested/${notification.work_id}`)
                            // if (this.$store.state.user.userContext === 'company') {/* console.log('Applicant declined company') */}
                            // else if (this.$route.path !== '/job/search') this.$router.push('/job/search')
                            break
                        case 4:
                        // // Applicant accepted
                        // if (this.$store.state.user.userContext === 'company') {/* console.log('Applicant accepted company') */}
                        // else if (this.$route.path !== `/job/${notification.work_id}/overview/${notification.service}`) this.$router.push(`/job/${notification.work_id}/overview/${notification.work_id}`)
                            break
                        case 5:
                        // Updated
                        // if (Number(notification.work_stage_id) === 2) {
                            if (this.$route.path !== `/service/${notification.work_id}/requested/${notification.service_id}`) this.$router.push(`/service/${notification.service_id}/requested/${notification.work_id}`)
                            // } else {
                            //     this.$store.commit('setSnackbar', {
                            //         text: 'This professional service is not in a pending status',
                            //         show: true,
                            //         color: '#069B34'
                            //     })
                            // }
                            break
                        default:
                            break
                        }
                    }
                } else if (Number(notification.notification_context_id) === 2) {
                // Message
                    if (Number(notification.notification_type_id) === 3) {
                    // Job
                        switch (Number(notification.notification_event_id)) {
                        case 1:
                            break
                        case 2:
                            break
                        case 3:
                            break
                        case 4:
                            break
                        case 5:
                        // Updated
                            if (Number(notification.work_stage_id) === 3 || Number(notification.work_stage_id) === 4) {
                            // Active or Completed or Cancelled
                                if (this.$route.path !== `/job/${notification.work_id}/overview/${notification.work_applicant_id}`) this.$router.push(`/job/${notification.work_id}/overview/${notification.work_applicant_id}`)
                            } else if (Number(notification.work_stage_id) === 2) {
                            // Pending
                                if (Number(notification.work_owner_id) === notification.sender_id) {
                                // Owner sent message
                                    if (this.$route.path !== `/job/${notification.work_id}/applicant/${notification.receiver_id}`) this.$router.push(`/job/${notification.work_id}/applicant/${notification.receiver_id}`)
                                } else {
                                // Applicant sent message
                                    if (this.$route.path !== `/job/${notification.work_id}/applicant/${notification.sender_id}`) this.$router.push(`/job/${notification.work_id}/applicant/${notification.sender_id}`)
                                }
                            }
                            break
                        case 6:
                            break
                        default:
                            break
                        }
                    } else if (Number(notification.notification_type_id) === 2) {
                    // Service
                        switch (Number(notification.notification_event_id)) {
                        case 1:
                            break
                        case 2:
                            break
                        case 3:
                            break
                        case 4:
                            break
                        case 5:
                        // Updated
                            if (this.$route.path !== `/service/${notification.service_id}/requested/${notification.work_id}`) this.$router.push(`/service/${notification.service_id}/requested/${notification.work_id}`)
                            break
                        case 6:
                            break
                        default:
                            break
                        }
                    }
                }
            }
        },

        async markNotificationsAsRead (notifications) {
            try {
                await this.generalNotificationController.markAsRead(notifications)
            } catch (error) {
                this.$store.commit('setSnackbar', {
                    text: error,
                    show: true
                })
            }
        }
    },

    render: h => h(App)
}).$mount('#app')

// ga('set', 'page', router.currentRoute.path)
// ga('send', 'pageview')

router.afterEach((to, from) => {
    // ga('set', 'page', to.path)
    // ga('send', 'pageview')
})
