/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-12-07 17:53:45
 * @ Description: A controller that handles job application detail related operations.
 */

import BaseController from '../../../../base.controller.js'

/**
* A class with helper methods to perform job application detail-related functionality.
*/
class WorkJobApplicationDetailController extends BaseController {
    /**
     * Retrieve a job's detail.
     * @param {Number} jobID The ID of the job to retrieve.
     * @param {Number} applicantID The ID of the applicant.
     */
    async retrieve (jobID, applicantID) {
        try {
            const result = await this.$http.get(`${this.apiPath}/work/job/${jobID}/application/${applicantID}/detail`)
  
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
    * Upsert a job application's detail.
    * @param {Object} detail The job application detail object to update.
    */
    async upsert (detail) {
        try {
            const result = await this.$http.put(`${this.apiPath}/work/job/application/detail`, detail)
   
            var data = result.data.resultMessage
            return data
        } catch (error) {
            if (error.response.data.errorCode === 402) {
                throw (
                    {
                        errorCode: 402,
                        invoiceData: error.response.data.resultMessage
                    }
                )
            } else throw Error(error.response.data.resultMessage)
        }
    }
}
   
export default WorkJobApplicationDetailController
   