/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-02-11 15:12:38
 * @ Description: A controller that handles job lookup related operations.
 */

import BaseController from '../../base.controller.js'

/**
  * A class with helper methods to perform job lookup-related functionality.
  */
class LookupJobController extends BaseController {
    /**
      * Retrieve all job types.
      */
    async getJobTypes () {
        try {
            const response = await this.$http.get(`${this.apiPath}/lookup/job/type`)
            const responseData = response.data.resultMessage
 
            return responseData
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
      * Retrieve all job modes.
      */
    async getJobModes () {
        try {
            const response = await this.$http.get(`${this.apiPath}/lookup/job/mode`)
            const responseData = response.data.resultMessage
 
            return responseData
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
 
export default LookupJobController
 