<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-03-01 14:39:42
 * @ Description: Component for managing document uploads and downloads.
 -->

<template>
    <!-- Desktop -->
    <v-row
        v-if="$vuetify.breakpoint.mdAndUp"
        class="pb-3"
    >
        <!-- Upload Input -->
        <v-col
            cols="9"
            class="pb-0 os-15-sb"
            v-if="canEditDocuments"
        >
            <input
                type="file"
                style="display: none"
                ref="document"
                @change="documentSelected"
            >
            <v-icon
                color="black"
            >
                {{ mdiCloudUploadOutline }}
            </v-icon>
            <span class="ml-1">Upload a Document(s)</span>
            <br />
            <v-text-field
                dense
                outlined
                color="#2E567A"
                class="pt-2 os-13-r"
                background-color="white"
                placeholder="Select a document or drag it into this window"
                readonly
                v-model="documentName"
                @click="selectDocument"
                messages="Max 5 MB file size"
                id="documentDropzone"
            />
        </v-col>
        <!-- Upload Input -->

        <!-- Add Button -->
        <v-col
            cols="3"
            class="pb-0 text-right"
            align-self="center"
            v-if="canEditDocuments"
        >
            <v-btn
                class="white--text no-uppercase rounded-lg os-13-sb"
                color="#FFA858"
                @click="createDocument"
                :disabled="documentFormData === null"
            >
                Add
                <v-icon
                    class="ml-1"
                >
                    {{ mdiPlusCircleOutline }}
                </v-icon>
            </v-btn>
        </v-col>
        <!-- Add Button -->

        <!-- No Documents -->
        <v-col
            v-if="!documents || documents.length === 0"
            class="os-12-r text-center"
            style="color: rgba(0, 0, 0, 0.38);"
        >
            No documents added
        </v-col>
        <!-- No Documents -->

        <!-- Documents -->
        <v-col
            cols="12"
            v-for="(item, index) in documents"
            :key="index"
            class="py-0 os-13-sb"
            v-else
        >
            <div
                style="background-color: white; border: 1px solid #8F9BB3; display: flex; align-items: center; max-width: 100%; width: 100%; height: 65px;"
                :style="index > 0 ? 'border-top: 0px;' : ''"
                class="pa-3"
            >
                <v-row
                    align="center"
                    style="max-width: calc(100% + 24px);"
                >
                    <v-col
                        class="flex-grow-0 flex-shrink-1 pr-1"
                        align-self="center"
                    >
                        <v-checkbox
                            v-if="documentCheckboxes"
                            v-model="item.isApplicationDoc"
                            @change="toggleSelectedDocument(item)"
                        />
                        <v-icon
                            v-else
                        >
                            {{ mdiFileOutline }}
                        </v-icon>
                    </v-col>
                    <v-col
                        class="text-truncate flex-grow-1 flex-shrink-0 px-0"
                    >
                        {{ item.filename }}
                        <br />
                        <span
                            style="color: #8F9BB3;"
                            class="os-12-r"
                        >
                            {{ item.sizeInMB }} MB
                        </span>
                    </v-col>
                    <v-col
                        align-self="center"
                        class="text-right pl-0"
                        cols="3"
                    >
                        <v-btn
                            icon
                            @click="downloadDocument(item)"
                            v-if="canDownloadDocuments"
                        >
                            <v-icon
                                color="black"
                            >
                                {{ mdiTrayArrowDown }}
                            </v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            v-if="canEditDocuments && canDeleteDocuments"
                            @click="deleteDocument(item, index)"
                        >
                            <v-icon
                                color="black"
                            >
                                {{ mdiDeleteOutline }}
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-col>
        <!-- Documents -->
    </v-row>
    <!-- Desktop -->

    <!-- Mobile -->
    <v-row
        v-else-if="$vuetify.breakpoint.smAndDown"
        class="pb-3"
    >
        <!-- Upload Input -->
        <v-col
            :cols="$store.state.general.customBreakpoints.xxs ? 12 : 8"
            class="pb-0 os-15-sb"
            v-if="canEditDocuments"
            :style="$vuetify.breakpoint.smAndDown ? 'font-size: 12px;' : 'font-size: 16px;'"
        >
            <input
                type="file"
                style="display: none"
                ref="document"
                @change="documentSelected"
            >
            <v-icon
                color="black"
            >
                {{ mdiCloudUploadOutline }}
            </v-icon>
            <span class="ml-1">Upload a Document(s)</span>
            <br />
            <v-text-field
                dense
                outlined
                color="#2E567A"
                class="pt-2 os-13-r"
                background-color="white"
                placeholder="Select document"
                readonly
                v-model="documentName"
                @click="selectDocument"
                messages="Max 5 MB file size"
            />
        </v-col>
        <!-- Upload Input -->

        <!-- Add Button -->
        <v-col
            :cols="$store.state.general.customBreakpoints.xxs ? 12 : 4"
            :class="!$store.state.general.customBreakpoints.xxs ? 'pb-0' : 'pt-0'"
            class="text-right"
            align-self="center"
            v-if="canEditDocuments"
        >
            <v-btn
                class="white--text no-uppercase rounded-lg os-13-sb"
                color="#FFA858"
                @click="createDocument"
                :disabled="documentFormData === null"
            >
                Add
                <v-icon
                    class="ml-1"
                >
                    {{ mdiPlusCircleOutline }}
                </v-icon>
            </v-btn>
        </v-col>
        <!-- Add Button -->

        <!-- No Documents -->
        <v-col
            v-if="!documents || documents.length === 0"
            class="os-12-r text-center"
            style="color: rgba(0, 0, 0, 0.38);"
        >
            No documents added
        </v-col>
        <!-- No Documents -->

        <!-- Documents -->
        <v-col
            cols="12"
            v-for="(item, index) in documents"
            :key="index"
            class="py-0 os-13-sb"
            v-else
        >
            <div
                style="background-color: white; border: 1px solid #8F9BB3; display: flex; align-items: center; max-width: 100%; width: 100%; height: 65px;"
                :style="index > 0 ? 'border-top: 0px;' : ''"
                class="pa-3"
            >
                <v-row
                    style="max-width: calc(100% + 24px);"
                    align="center"
                >
                    <v-col
                        class="flex-grow-0 flex-shrink-1 pr-1"
                        align-self="center"
                    >
                        <v-checkbox
                            v-if="documentCheckboxes"
                            v-model="item.isApplicationDoc"
                            @change="toggleSelectedDocument(item)"
                        />
                        <v-icon
                            :small="$store.state.general.customBreakpoints.xxs"
                            v-else
                        >
                            {{ mdiFileOutline }}
                        </v-icon>
                    </v-col>
                    <v-col
                        class="text-truncate flex-grow-1 flex-shrink-0 px-0"
                    >
                        {{ item.filename }}
                        <br />
                        <span
                            style="color: #8F9BB3;"
                            class="os-12-r"
                        >
                            {{ item.sizeInMB }} MB
                        </span>
                    </v-col>
                    <v-col
                        align-self="center"
                        class="text-right"
                        cols="4"
                    >
                        <v-btn
                            icon
                            :small="$store.state.general.customBreakpoints.xxs"
                            @click="downloadDocument(item)"
                            v-if="canDownloadDocuments"
                        >
                            <v-icon
                                color="black"
                                :small="$store.state.general.customBreakpoints.xxs"
                            >
                                {{ mdiTrayArrowDown }}
                            </v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            :small="$store.state.general.customBreakpoints.xxs"
                            @click="deleteDocument(item, index)"
                            v-if="canEditDocuments && canDeleteDocuments"
                        >
                            <v-icon
                                color="black"
                                :small="$store.state.general.customBreakpoints.xxs"
                            >
                                {{ mdiDeleteOutline }}
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-col>
        <!-- Documents -->
    </v-row>
    <!-- Mobile -->
</template>
<script>
    import { mdiPlusCircleOutline, mdiTrayArrowDown, mdiDeleteOutline, mdiFileOutline, mdiCloudUploadOutline } from '@mdi/js'

    export default {
        name: 'GeneralDocumentComponent',

        props: {
            documents: {
                type: Array,
                default: () => {
                    return []
                }
            },

            fileType: {
                type: String,
                default: 'other'
            },

            canEditDocuments: {
                type: Boolean,
                default: true
            },

            canDeleteDocuments: {
                type: Boolean,
                default: true
            },

            canDownloadDocuments: {
                type: Boolean,
                default: true
            },

            documentCheckboxes: {
                type: Boolean,
                default: false
            }
        },

        watch: {
            canEditDocuments: {
                handler (value) {
                    if (value) this.createDropzone()
                }
            }
        },

        data () {
            return {
                mdiPlusCircleOutline,
                mdiTrayArrowDown,
                mdiDeleteOutline,
                mdiFileOutline,
                mdiCloudUploadOutline,
                documentFormData: null,
                uploadedFile: null,
                documentName: null,
                documentSize: null
            }
        },

        mounted () {
            this.createDropzone()
        },

        methods: {
            createDropzone () {
                if (this.$vuetify.breakpoint.mdAndUp) {
                    const dropzone = document.getElementById('documentDropzone')
                    if (dropzone) {
                        ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
                            dropzone.addEventListener(eventName, preventDefaults, false)
                        })

                        function preventDefaults (e) {
                            e.preventDefault()
                            e.stopPropagation()
                        }

                        dropzone.addEventListener('drop', handleDrop, false)

                        const self = this

                        function handleDrop(e) {
                            self.documentSelected(e)
                        }
                    }
                }
            },

            selectDocument () {
                this.$refs.document.click()
            },

            documentSelected (e) {
                let files
                
                if (e.target.files) files = e.target.files
                else if (e.dataTransfer.files) files = e.dataTransfer.files

                if (files && files.length > 0) {
                    var reader = new FileReader()
                    var f = files[0]

                    var self = this

                    reader.onloadend = function () {
                        const size = parseFloat((files[0].size / 1024) / 1024).toFixed(2)

                        if (size <= 5) {
                            self.documentFormData = new FormData()
                            self.documentFormData.append(self.fileType, files[0])
                            self.uploadedFile = files[0]
                            self.documentName = files[0].name
                            self.documentSize = files[0].size
                        } else {
                            self.$store.commit('setSnackbar', {
                                text: 'Max 5 MB file size',
                                show: true
                            })
                        }
                    }

                    reader.readAsDataURL(f)
                }
            },

            deleteDocument (document, documentIndex) {
                this.$emit('deleteDocument', document, documentIndex)
            },

            downloadDocument (document) {
                this.$emit('downloadDocument', document)
            },

            createDocument () {
                this.$emit('createDocument', this.documentFormData, this.uploadedFile)
                this.documentFormData = null
                this.uploadedFile = null
                this.documentName = null
                this.documentSize = null
            },

            toggleSelectedDocument (document) {
                this.$emit('toggleSelectedDocument', document)
            }
        }
    }
</script>
<style scoped>
    >>> .v-text-field--outlined fieldset {
        border: dashed;
    }
</style>
