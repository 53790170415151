/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-02-11 15:12:38
 * @ Description: A controller that handles education lookup related operations.
 */

import BaseController from '../../base.controller.js'

/**
   * A class with helper methods to perform education lookup-related functionality.
   */
class LookupEducationController extends BaseController {
    /**
       * Retrieve all qualifications.
       */
    async getQualifications () {
        try {
            const response = await this.$http.get(`${this.apiPath}/lookup/user/education/qualification`)
            const responseData = response.data.resultMessage
  
            return responseData
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
       * Retrieve all language skills.
       */
    async getLanguageSkills () {
        try {
            const response = await this.$http.get(`${this.apiPath}/lookup/user/education/language`)
            const responseData = response.data.resultMessage
  
            return responseData
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
       * Retrieve all completion years.
       */
    async getCompletionYears () {
        try {
            const completionYears = []

            let currentYear = (new Date().getFullYear())
            const startYear = currentYear - 50

            while (currentYear > startYear) {
                completionYears.push(currentYear--)
            }

            return completionYears
        } catch (error) {
            throw Error(error)
        }
    }
}
  
export default LookupEducationController
  