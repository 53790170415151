/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-12-11 18:11:36
 * @ Description: A controller that handles general calendar related operations.
 */

import BaseController from '../../../base.controller.js'

/**
* A class with helper methods to perform general calendar-related functionality.
*/
class GeneralCalendarController extends BaseController {
    /**
    * Retrieve a user's calendar.
    * @param {String} startDate Start date of events to retrieve.
    * @param {String} endDate End date of events to retrieve.
    */
    async retrieve (startDate, endDate) {
        try {
            let queryString = 'user/calendar'

            if (startDate && endDate) queryString += `?startDate=${startDate}&endDate=${endDate}`
            else if (startDate) queryString += `startDate=${startDate}`
            else if (endDate) queryString += `endDate=${endDate}`

            const result = await this.$http.get(`${this.apiPath}/${queryString}`)
   
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
   
export default GeneralCalendarController
   