<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-10-04 15:53:04
 * @ Description: Fixer's individual profile page in the user's dashboard section of the app.
 -->

<template>
    <div>
        <v-row
            justify="center"
        >
            <v-col
                cols="12"
                style="position: relative;"
                :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 1440px;' : $vuetify.breakpoint.md ? 'max-width: 960px;' : $store.state.general.customBreakpoints.xxs ? 'max-width: 280px;' : $vuetify.breakpoint.xs ? 'max-width: 360px;' : 'max-width: 500px'"
            >
                <!-- New User Message -->
                <v-col
                    style="position: absolute; top: 112px; right: 12px; height: 185px; background-color: rgba(255, 168, 88, 0.25);"
                    class="elevation-2 os-15-r"
                    v-if="$vuetify.breakpoint.mdAndUp && $store.state.signup.newUser && this.selectedNavItem.text === 'Personal'"
                    cols="4"
                >
                    <div class="os-22-sb">Welcome!</div>
                    <br />
                    You have signed up for an <span class="os-15-sb">Individual Account.</span><br />
                    Take some time to look around. Support is just a <span class="os-15-sb" style="text-decoration: underline; cursor: pointer;">click away</span> if you have any questions.
                </v-col>
                <!-- New User Message -->

                <v-row>
                    <!-- Heading -->
                    <v-col
                        cols="12"
                        :class="$vuetify.breakpoint.smAndDown &&
                            selectedNavItem.text !== 'My Services' &&
                            selectedNavItem.text !== 'Job Titles' &&
                            selectedNavItem.text !== 'Education' &&
                            selectedNavItem.text !== 'Experience' &&
                            selectedNavItem.text !== 'Account Details' ? 'text-center' : ''"
                    >
                        <span
                            class="os-22-sb"
                        >
                            {{ selectedNavItem.heading }}
                        </span>
                    </v-col>
                    <!-- Heading -->

                    <!-- Desktop Navigation -->
                    <v-col
                        cols="12"
                        class="os-13-r"
                        v-if="$vuetify.breakpoint.mdAndUp"
                    >
                        <ul
                            style="padding-left: 0px;"
                        >
                            <li
                                v-for="(item, index) in topNavItems"
                                :key="index"
                                :style="item.selected ? '' : 'color: #8F9BB3;'"
                            >
                                <div
                                    v-if="!item.hidden"
                                >
                                    <span
                                        :style="index > 0 ? $vuetify.breakpoint.xl ? 'padding-left: 4rem;' : 'padding-left: 2.5rem;' : ''"
                                        style="cursor: pointer; position: relative;"
                                        @click="changeTopNavSelection(index, true)"
                                    >
                                        {{ item.text }}
                                    </span>
                                    <div
                                        style="background-color: #FFA858;  height: 2px; width: 35px; position: absolute;"
                                        :style="index > 0 ? $vuetify.breakpoint.xl ? 'margin-left: 4rem;' : 'margin-left: 2.5rem;' : ''"
                                        v-if="item.selected"
                                    />
                                </div>
                            </li>
                        </ul>
                    </v-col>
                    <!-- Desktop Navigation -->

                    <!-- Mobile Navigation -->
                    <v-col
                        v-else-if="$vuetify.breakpoint.smAndDown"
                        cols="12"
                        class="pa-0 os-13-r"
                    >
                        <v-sheet
                            class="mx-auto"
                            max-width="100%"
                            style="background-color: #F9FBFD;"
                        >
                            <v-slide-group
                                multiple
                                show-arrows
                            >
                                <v-slide-item
                                    v-for="(item, index) in topNavItems"
                                    :key="index"
                                    :slot-scope="index"
                                >
                                    <v-chip
                                        class="ma-2"
                                        outlined
                                        :color="item.selected ? '#FFA858' : '#8F9BB3'"
                                        :style="item.selected ? 'color: black;' : ''"
                                        style="font-size: 15px !important;"
                                        @click="changeTopNavSelection(index, true)"
                                        v-if="!item.hidden"
                                    >
                                        {{ item.text }}
                                    </v-chip>
                                </v-slide-item>
                            </v-slide-group>
                        </v-sheet>
                    </v-col>
                    <!-- Mobile Navigation -->
                    
                    <v-col
                        cols="12"
                        style="position: relative;"
                    >
                        <!-- View Type Toggle Buttons -->
                        <v-btn
                            style="position: absolute; right: 66px; top: -41px; min-width: 0px; max-width: 50px; opacity: 0.5;"
                            aria-label="list"
                            :color="selectedView === 'list' ? '#2E567A' : 'white'"
                            tile
                            class="rounded-l-lg"
                            @click="changeSelectedView('list')"
                            v-if="$vuetify.breakpoint.mdAndUp && (this.$route.path.includes('/services') || this.$route.path.includes('/job_titles') || this.$route.path.includes('/education') || this.$route.path.includes('/experience'))"
                        >
                            <v-icon
                                :color="selectedView === 'list' ? 'white' : '#C5CEE0'"
                            >
                                {{ mdiFormatListBulleted }}
                            </v-icon>
                        </v-btn>
                        <v-btn
                            style="position: absolute; right: 16px; top: -41px; in-width: 0px; max-width: 50px; opacity: 0.5;"
                            aria-label="grid"
                            :color="selectedView === 'card' ? '#2E567A' : 'white'"
                            tile
                            class="rounded-r-lg"
                            @click="changeSelectedView('card')"
                            v-if="$vuetify.breakpoint.mdAndUp && (this.$route.path.includes('/services') || this.$route.path.includes('/job_titles') || this.$route.path.includes('/education') || this.$route.path.includes('/experience'))"
                        >
                            <v-icon
                                :color="selectedView === 'card' ? 'white' : '#C5CEE0'"
                            >
                                {{ mdiViewGridOutline }}
                            </v-icon>
                        </v-btn>
                        <!-- View Type Toggle Buttons -->

                        <!-- Router -->
                        <router-view
                            :selectedView="selectedView"
                        />
                        <!-- Router -->
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import { mdiFormatListBulleted, mdiViewGridOutline } from '@mdi/js'

    export default {
        name: 'ProfileIndividualPage',

        watch: {
            '$route.path': {
                handler () {
                    this.getTopNavSelection()
                }
            }
        },

        data () {
            return {
                mdiFormatListBulleted,
                mdiViewGridOutline,
                selectedView: 'card',
                topNavItems: [
                    {
                        text: 'Personal',
                        selected: true,
                        heading: 'My Profile'
                    },
                    {
                        text: 'My Services',
                        selected: false,
                        heading: 'My Services'
                    },
                    {
                        text: 'Job Titles',
                        selected: false,
                        heading: 'Job Titles'
                    },
                    {
                        text: 'Education',
                        selected: false,
                        heading: 'Education'
                    },
                    {
                        text: 'Experience',
                        selected: false,
                        heading: 'Experience'
                    },
                    {
                        text: 'My Reviews',
                        selected: false,
                        heading: 'My Reviews'
                    },
                    {
                        text: 'Account Details',
                        selected: false,
                        heading: 'Account Details'
                    }
                ],
                selectedNavItem: {
                    text: 'Personal',
                    selected: true,
                    heading: 'My Profile'
                }
            }
        },

        beforeMount () {
            this.getTopNavSelection()

            if (!this.$route.query.view) {
                this.$router.replace({
                    name: this.$route.name,
                    query: {
                        view: this.selectedView
                    }
                })
            } else this.selectedView = this.$route.query.view
        },

        methods: {
            getTopNavSelection () {
                let index

                switch (this.$route.path) {
                case '/profile':
                    index = 0
                    break;
                case '/profile/services':
                    index = 1
                    break;
                case '/profile/job_titles':
                    index = 2
                    break;
                case '/profile/education':
                    index = 3
                    break;
                case '/profile/experience':
                    index = 4
                    break;
                case '/profile/reviews':
                    index = 5
                    break;
                case '/profile/account':
                    index = 6
                    break;
                }

                this.changeTopNavSelection(index)
            },

            changeTopNavSelection (newSelectedItemIndex, changeRoute) {
                const currentlySelectedItemIndex = this.topNavItems.findIndex(itemToFind => itemToFind.selected)
                if (currentlySelectedItemIndex > -1) this.topNavItems[currentlySelectedItemIndex].selected = false

                this.topNavItems[newSelectedItemIndex].selected = true
                this.selectedNavItem = this.topNavItems[newSelectedItemIndex]

                if (changeRoute) {
                    switch (this.selectedNavItem.text) {
                    case 'Personal':
                        if (this.$route.path !== '/profile') this.$router.push({ path: '/profile', query: { view: this.selectedView } })
                        break;
                    case 'My Services':
                        if (this.$route.path !== '/profile/services') this.$router.push({ path: '/profile/services', query: { view: this.selectedView } })
                        break;
                    case 'Job Titles':
                        if (this.$route.path !== '/profile/job_titles') this.$router.push({ path: '/profile/job_titles', query: { view: this.selectedView } })
                        break;
                    case 'Education':
                        if (this.$route.path !== '/profile/education') this.$router.push({ path: '/profile/education', query: { view: this.selectedView } })
                        break;
                    case 'Experience':
                        if (this.$route.path !== '/profile/experience') this.$router.push({ path: '/profile/experience', query: { view: this.selectedView } })
                        break;
                    case 'My Reviews':
                        if (this.$route.path !== '/profile/reviews') this.$router.push({ path: '/profile/reviews', query: { view: this.selectedView } })
                        break;
                    case 'Account Details':
                        if (this.$route.path !== '/profile/account') this.$router.push({ path: '/profile/account', query: { view: this.selectedView } })
                        break;
                    }
                }
            },

            changeSelectedView (viewType) {
                this.selectedView = viewType

                this.$router.replace({
                    name: this.$route.name,
                    query: {
                        view: this.selectedView
                    }
                })
            }
        }
    }
</script>
<style>
    @import url('../../../../assets/style/style.fonts.css');

    .no-uppercase {
        text-transform: none !important;
        letter-spacing: 0px !important;
    }
</style>
<style scoped>
    >>> .v-btn:not(.v-btn--round).v-size--default {
        min-width: 0;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }

    li {
        float: left;
    }

    li span {
        display: block;
    }
</style>
