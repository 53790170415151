/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2020-12-01 15:16:46
 * @ Description: A controller that handles general profile related operations.
 */

import BaseController from '../../../base.controller.js'

/**
 * A class with helper methods to perform general profile-related functionality.
 */
class GeneralProfileController extends BaseController {
    /**
     * Reset a profile's password.
     */
    async resetPassword () {
        try {
            const result = await this.$http.post(`${this.apiPath}/user/profile/resetpassword`)
      
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
     * Suspend an account.
     */
    async suspendAccount () {
        try {
            const result = await this.$http.put(`${this.apiPath}/user/profile/suspend`)
      
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
      
export default GeneralProfileController
      