/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-29 13:08:43
 * @ Description: A controller that handles individual profile experience related operations.
 */

import BaseController from '../../../../base.controller.js'

/**
      * A class with helper methods to perform individual profile experience-related functionality.
      */
class IndividualProfileExperienceController extends BaseController {
    /**
      * Retrieve an individual profile's experience.
      * @param {Number} userID The ID of the user to retrieve.
      */
    async retrieve (userID) {
        try {
            const result = await this.$http.get(`${this.apiPath}/individual/${userID}/profile/experience`)
     
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
    
    /**
      * Upsert an individual profile's experience.
      * @param {Object} experience The experience object to upsert.
      */
    async upsert (experience) {
        try {
            const result = await this.$http.put(`${this.apiPath}/individual/profile/experience`, experience)
     
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
  
    /**
      * Delete an individual profile's experience.
      * @param {Object} experience The experience object to delete.
      */
    async delete (experience) {
        try {
            const result = await this.$http.delete(`${this.apiPath}/individual/profile/experience`, experience)
     
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
     
export default IndividualProfileExperienceController
     