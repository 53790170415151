/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-12-08 08:53:44
 * @ Description: A controller that handles job application status related operations.
 */

import BaseController from '../../../../base.controller.js'

/**
 * A class with helper methods to perform job application status-related functionality.
 */
class WorkJobApplicationStatusController extends BaseController {
    /**
     * Toggle a job application's declined status.
     * @param {Object} application The job application object to update.
     */
    async toggleDecline (application) {
        try {
            const result = await this.$http.put(`${this.apiPath}/work/job/application/decline`, application)
      
            var data = result.data.resultMessage
            return data
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
     * Assign an applicantion to a job.
     * @param {Object} application The job application object to assign.
     */
    async assign (application) {
        try {
            const result = await this.$http.put(`${this.apiPath}/work/job/application/assign`, application)
      
            var data = result.data.resultMessage
            return data
        } catch (error) {
            if (error.response.data.errorCode === 402) {
                throw (
                    {
                        errorCode: 402,
                        invoiceData: error.response.data.resultMessage
                    }
                )
            } else throw Error(error.response.data.resultMessage)
        }
    }
}
      
export default WorkJobApplicationStatusController
      