/**
 * @ Author: Zelmi Greyling
 * @ Create Time: 2019-07-29 09:29:01
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-01-12 11:07:34
 * @ Description: A controller that handles industry lookup related operations.
 */

import BaseController from '../../base.controller.js'

/**
  * A class with helper methods to perform industry lookup-related functionality.
  */
class LookupIndustryController extends BaseController {
    /**
     * Retrive all indsutries.
     * @param {Number} pageNumber The page number to retrieve.
     * @param {Number} pageSize The page size to retrieve.
     * @param {Boolean} isProfService Retrieve industries related to professional services.
     * @param {Boolean} isProject Retrieve industries related to projects.
     * @param {Boolean} isJob Retrieve industries related to jobs.
     * @param {Boolean} isTender Retrieve industries related to tenders.
     * @param {Boolean} isProfileInd Retrieve industries related to individual profiles.
     * @param {Boolean} isProfileComp Retrieve industries related to company profiles.
     */
    async get (isProfService, isProject, isJob, isTender, isProfileInd, isProfileComp, pageNumber, pageSize) {
        try {
            let queryString = 'lookup/category/group'

            if (isProfService) queryString += '?isProfService=1'
            if (isProject) queryString += '?isProject=1'
            if (isJob) queryString += '?isJob=1'
            if (isTender) queryString += '?isTender=1'
            if (isProfileInd) queryString += '?isProfileInd=1'
            if (isProfileComp) queryString += '?isProfileComp=1'

            if (pageNumber && queryString === 'lookup/category/group') queryString += `?pageNumber=${pageNumber}`
            else if (pageNumber) queryString += `&pageNumber=${pageNumber}`
            if (pageSize) queryString += `&pageSize=${pageSize}`

            const response = await this.$http.get(`${this.apiPath}/${queryString}`)
            const responseData = response.data.resultMessage

            return responseData
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }

    /**
     * Upsert an industry.
     * @param {Object} industry The industry to upsert.
     */
    async upsert (industry) {
        try {
            const response = await this.$http.put(`${this.apiPath}/lookup/category/group`, industry)
            const responseData = response.data.resultMessage

            return responseData
        } catch (error) {
            throw Error(error.response.data.resultMessage)
        }
    }
}
 
export default LookupIndustryController
 